import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { PAGINATION_COUNT } from "../../config/config";

import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../components/common/label/pmivr-label";

import AppUtil from "../../util/app.util";

import AuditService from "../../services/audit.service";

/**
 * Lists all the AGI call logs from db
 * @returns {React.Component} Html element to render
 */
const Logs = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // call logs list and total number of calls made
    const [callLogs, setCallLogs] = useState({ callLogsList: [], totalCalls: 0 });
    // filter object for filtering the call logs
    const [filterCallLogs, setFilterCallLogs] = useState({ text: '', stepName: '', dnid: '', callerIdNum: '', sessionId: '' });
    // props for the pagination of the call logs list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of call logs
        currentPage: 0, // current page displayed in pagination of call logs
        totalResults: 0,  // total number of call logs
        count: PAGINATION_COUNT  // count for number of call logs to be displayed at a time on screen
    });

    useEffect(() => {
        // loading initial logs list
        const loadInitialCallLogs = async () => {
            await loadCallLogs();
        };
        loadInitialCallLogs();
    }, []);

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of  call logs
     * @param {Number} pageNo page number of the call logs list
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * Getting the call logs from the DB as per count, page no, etc
     * @param {Integer} pageNo page of call logs list to be displayed
     */
    const loadCallLogs = async (pageNo = 1) => {
        try {
            const filter = {
                searchText: filterCallLogs.text,
                stepName: filterCallLogs.stepName,
                dnid: filterCallLogs.dnid,
                callerIdNum: filterCallLogs.callerIdNum,
                sessionId: filterCallLogs.sessionId,
                count: pagination.count,
                pageNo
            };
            const response = await AuditService.getIvrCallLogs(filter);
            setCallLogs({ ...callLogs, callLogsList: response?.data.logs, totalCalls: response?.data.totalCalls });
            setPaginationProps(response?.dataCount, pageNo);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Resetting the list of call logs from state. 
     * If not found, then reload call logs from DB
     */
    const resetData = async () => {
        // reset filter option
        setFilterCallLogs({ text: '', stepName: '', dnid: '', callerIdNum: '', sessionId: '' });

        // Clear the cached data and make a fresh API call
        try {
            const response = await AuditService.getIvrCallLogs({
                searchText: '',
                stepName: '',
                dnid: '',
                callerIdNum: '',
                sessionId: '',
                count: pagination.count,
                pageNo: 1
            });
            setCallLogs({ ...callLogs, callLogsList: response?.data.logs, totalCalls: response?.data.totalCalls });
            setPaginationProps(response?.dataCount, 1);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Updates filter state for call logs
     * @param {string} field - field to update in filter state
     * @param {string} value - new value of the filter field
     */
    const handleInputChange = (field, value) => {
        setFilterCallLogs((prev) => {
            const newFilterCallLogs = { ...prev, [field]: value?.toLowerCase() };
            // Check if all fields are empty after input change, and reset
            const allFieldsEmpty = Object.values(newFilterCallLogs).every(val => val === '');
            if (allFieldsEmpty) {
                resetData();
            }
            return newFilterCallLogs;
        });
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom pb-3 pt-3">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / Call Logs
                            </div>
                        </div>
                    </div>
                </div>
                {/* header for search and actions */}
                <div className="row p-2 pt-3">
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Search In Call Logs" tooltip={TOOLTIP.INFO.CALL_LOGS.SEARCH_TEXT} cssClass="my-0" />
                        <input type="text" id="searchBox" className="form-control pmivr-input pe-5" value={filterCallLogs.text} placeholder="Search text"
                            onChange={(e) => handleInputChange('text', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs() : null}
                        />
                    </div>
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Step Name" tooltip={TOOLTIP.INFO.CALL_LOGS.STEP_NAME} cssClass="my-0" />
                        <input type="text" id="stepNameBox" className="form-control pmivr-input pe-5" value={filterCallLogs.stepName} placeholder="Step Name"
                            onChange={(e) => handleInputChange('stepName', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs() : null}
                        />
                    </div>
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="DNID" tooltip={TOOLTIP.INFO.CALL_LOGS.DNID} cssClass="my-0" />
                        <input type="text" id="dnidBox" className="form-control pmivr-input pe-5" value={filterCallLogs.dnid} placeholder="DNID"
                            onChange={(e) => handleInputChange('dnid', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs() : null}
                        />
                    </div>
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Caller ID Number" tooltip={TOOLTIP.INFO.CALL_LOGS.CALLER_ID_NUMBER} cssClass="my-0" />
                        <input type="text" id="callerIdNumBox" className="form-control pmivr-input pe-5" value={filterCallLogs.callerIdNum} placeholder="Caller ID Number"
                            onChange={(e) => handleInputChange('callerIdNum', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs() : null}
                        />
                    </div>
                    <div className="col-sm-2 pmivr-relative">
                        <PmivrLabel label="Session ID" tooltip={TOOLTIP.INFO.CALL_LOGS.SESSION_ID} cssClass="my-0" />
                        <input type="text" id="sessionIdBox" className="form-control pmivr-input pe-5" value={filterCallLogs.sessionId} placeholder="Session ID"
                            onChange={(e) => handleInputChange('sessionId', e.target.value)}
                            onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadCallLogs() : null}
                        />
                    </div>
                    <div className="col-sm-2 mt-3 pt-1">
                        <button title="Search call logs" type="button" onClick={() => loadCallLogs()} className="float-start pmivr-btn-secondary pmivr-reset-link me-1">
                            Search
                        </button>
                        <button title="Reset call logs" type="button" onClick={() => resetData()} className="float-start pmivr-btn-secondary pmivr-reset-link">
                            Reset
                        </button>
                    </div>
                </div>

                {/* total call logs and agi calls bar */}
                <div className="row pe-1">
                    <div className="col-lg-6 d-flex">
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total AGI Calls: {callLogs.totalCalls || 0}</div>
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total Records: {pagination.totalResults || 0}</div>
                    </div>
                    {
                        (pagination.totalResults > pagination.count) && (
                            <div className="col-lg-6">
                                {pagination.currentPage < pagination.totalPages && (
                                    <div className="float-end pt-3">
                                        <div className="px-3 pmivr-breadcrumb-list">
                                            <Link title="Next page of call logs" onClick={() => loadCallLogs(pagination.currentPage + 1)}>
                                                Next <i className="bi bi-arrow-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                )}
                                {pagination.currentPage > 1 && (
                                    <div className="float-end pt-3">
                                        <div className="px-3 pmivr-breadcrumb-list">
                                            <Link title="Previous page of call logs" onClick={() => loadCallLogs(pagination.currentPage - 1)}>
                                                <i className="bi bi-arrow-left"></i> Previous
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                </div>

                {/* list of call logs table */}
                <div className="row me-2 ms-2">
                    <table className="table table-body-block pmivr-table header-fixed border mt-2">
                        <thead>
                            <tr>
                                <th width="10%" className="text-center">Timestamp</th>
                                <th width="5%" className="text-center">DNID</th>
                                <th width="5%" className="text-center">Lext</th>
                                <th width="10%" className="text-center">Session ID</th>
                                <th width="10%" className="text-center">Caller Id Name</th>
                                <th width="10%" className="text-center">Caller Id Number</th>
                                <th width="10%" className="text-center">App Code</th>
                                <th width="10%" className="text-center">Business Code</th>
                                <th width="10%" className="text-center">Flow Name</th>
                                <th width="10%" className="text-center">Flow Type</th>
                                <th width="10%" className="text-center">Step Name</th>
                                <th width="10%" className="text-center">Session Status</th>
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            {(callLogs?.callLogsList.length) ?
                                callLogs.callLogsList.map((callLog, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="10%" title={callLog.startTime} className="text-center pt-2">{AppUtil.formatDateInLocal(callLog.startTime)}</td>
                                            <td width="5%" title={callLog.dnid} className="text-center pt-2">{callLog.dnid}</td>
                                            <td width="5%" title={callLog.lext} className="text-center pt-2">{callLog.lext || '-'}</td>
                                            <td width="10%" title={callLog.sessionId} className="text-center pt-2">{callLog.sessionId}</td>
                                            <td width="10%" title={callLog.callerIdName} className="text-center pt-2">{callLog.callerIdName}</td>
                                            <td width="10%" title={callLog.callerIdNum} className="text-center pt-2">{callLog.callerIdNum}</td>
                                            <td width="10%" title={callLog.appCode} className="text-center pt-2">{callLog.appCode}</td>
                                            <td width="10%" title={callLog.businessCode} className="text-center pt-2">{callLog.businessCode}</td>
                                            <td width="10%" title={callLog.flowName} className="text-center pt-2">{callLog.flowName}</td>
                                            <td width="10%" title={callLog.flowTypeId} className="text-center pt-2">{callLog.flowTypeId}</td>
                                            <td width="10%" title={callLog.stepName} className="text-center pt-2">{callLog.stepName || '-'}</td>
                                            <td width="10%" title={callLog.sessionStatus} className="text-center pt-2">{callLog.sessionStatus}</td>
                                        </tr>
                                    )
                                }) : <tr><td>No Call Logs</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Logs;