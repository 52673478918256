import { useState, useImperativeHandle, forwardRef } from 'react';

import PropTypes from 'prop-types';

import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog";

/**
 * Dialog box to show missing voice files
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog for missing voice files
 */
const MissingVoiceFileDialog = forwardRef((props, ref) => {
    const { closeAction, missingVoiceFileInfo, confirmDialogChanges } = props;

    /** variable to show and hide the dialog for missing voice files
     * showDialog {boolean} - whether to show or hide dialog box
     */
    const [dialogProps, setDialogProps] = useState({ showDialog: false, status: "", title: "" });

    // exposing functions to parent component
    useImperativeHandle(ref, () => ({
        open(title, status) {
            setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: true, status, title }));
        },
        close: closeDialog
    }));

    // function to close the upload file dialog box
    const closeDialog = () => {
        setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: false }));
    }

    return (
        <PmivrDialog showDialog={dialogProps.showDialog} closeDialog={closeAction}
            title={dialogProps.title}
            cssClass={`${missingVoiceFileInfo?.length ? 'diagram-confirm-modal' : ''}`}
            message={
                <>
                    {missingVoiceFileInfo?.length ?
                        <>
                            <table className="table voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
                                <thead>
                                    <tr>
                                        <th width="20%" className="text-center">
                                            Element Name
                                        </th>
                                        <th width="20%" className="text-center">
                                            Language
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="pmivr-scroll">
                                    {missingVoiceFileInfo?.map((voiceFileInfo) => {
                                        return (
                                            <tr>
                                                <td>
                                                    {voiceFileInfo?.elementName}
                                                </td>
                                                <td>
                                                    {voiceFileInfo?.language}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>
                        :
                        <div className="text-center">No Changes</div>
                    }
                </>
            }
            footer={
                <div className="d-flex justify-content-end">
                    <button className="pmivr-btn-secondary me-2 py-2" type="button" onClick={closeDialog}>
                        Cancel
                    </button>
                    <button className="pmivr-btn-app py-2" onClick={() => confirmDialogChanges(dialogProps.status)}>
                        Continue
                    </button>
                </div>
            }
        />
    )
});

// Types of props passed in component
MissingVoiceFileDialog.propTypes = {
    // function to close the dialog box
    closeAction: PropTypes.func,
    // confirming dialog box for mising voice files
    confirmDialogChanges: PropTypes.func,
    // show missing voice files
    missingVoiceFileInfo: PropTypes.array
}

export default MissingVoiceFileDialog;