import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";
import PropTypes from "prop-types";

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger.js";
import PmivrLabel from "../../common/label/pmivr-label.js";

import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { ATTRIBUTES } from "../../../constants/attributes";
import { CSS_CLASSES } from "../../../constants/css-classes.js";
import { TOOLTIP } from "../../../constants/messages";
import { envConfig } from "../../../environment/index.js";

import VoiceFile from "../../../models/voice-file";

import AudioUtil from "../../../util/audio.util";

import ElementService from "../../../services/element.service";
import AudioService from "../../../services/audio.service";

import TextToSpeechOption from "../tts-option/TextToSpeechOption";
import UploadVoiceFileOption from "../upload-voice-file-option/UploadVoiceFileOption";
import AudioPlayer from "../../common/audio-player/audio-player.js";
import VoiceFilesSelect from '../../common/voice-files-select/voice-files-select';

/**
 * Voice props view in the diagram
 */
const VoicePropsView = (props) => {
  // latest state from redux store
  const { voiceFilePrefixObj } = useSelector(state => state.voiceFile);
  // array of supported languages
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  // language selected
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // information regarding voice file info
  const [voiceFileInfo, setVoiceFileInfo] = useState({});
  // key of the currently active accordion item
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  // voice file method for multiple languages
  const [voiceFileUploadMethod, setVoiceFileUploadMethod] = useState({});
  const ttsRef = useRef();
  const uploadRef = useRef();

  const { rightPanelEventHandler, element } = props;

  useEffect(() => {
    const init = async () => {
      await resetStates(false);
    }
    init();
  }, [element]);

  // reset the state of this component and it's child components
  const resetStates = async (doResetChild = true) => {
    const voiceFileInfo = await AudioService.getVoiceFileInfo(element, ATTRIBUTES.VOICE_FILE_INFO, selectedLanguage);
    // set initial val of the state
    setSupportedLanguages(voiceFileInfo.supportedLanguages);
    setSelectedLanguage(voiceFileInfo.selectedLanguage);
    setVoiceFileInfo(voiceFileInfo.voiceFileInformation);
    // updating the voice file methods for multiple languages {"en" : "tts", "es" : "upload"}
    const voiceMethods = {};
    for (const lang of voiceFileInfo.supportedLanguages) {
      if (lang) {
        voiceMethods[lang] = voiceFileInfo.voiceFileInformation[lang]?.voiceFileType || VOICE_FILE_UPLOAD_TYPE.LIBRARY;
      }
    }
    setVoiceFileUploadMethod(voiceMethods);

    if (doResetChild) {
      ttsRef.current.resetTtsOption(); // reset the voice file info states of TTS component so that changes reflect on UI
      uploadRef.current.resetUploadOption(); // reset the voice file info states of UPLOAD component so that changes reflect on UI
    }

  }

  // update voice file sate info
  const updateVoiceFileStateInfo = async (language) => {
    const languageSelected = language ? language : "en";
    const voiceFileUploadMethod = voiceFileInfo[languageSelected]?.voiceFileType ? voiceFileInfo[languageSelected]?.voiceFileType : VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    const voiceFilePath = voiceFileInfo[languageSelected]?.filePath ? voiceFileInfo[languageSelected]?.filePath : voiceFilePrefixObj[language];
    const { voiceFileInformation } = await AudioService.getVoiceFileInfo(element, ATTRIBUTES.VOICE_FILE_INFO, languageSelected);

    setVoiceFileInfo(voiceFileInformation);
    const formatedVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(languageSelected, voiceFileInformation);
    formatedVoiceFileInfo[languageSelected].voiceFileType = voiceFileUploadMethod;
    formatedVoiceFileInfo[languageSelected].filePath = voiceFilePath;

    setSelectedLanguage(languageSelected);
    updateVoiceUploadMethod(languageSelected, voiceFileUploadMethod);
    setVoiceFileInfo(formatedVoiceFileInfo);
  }

  /**
   * Update the voice file upload method for given language in state
   * @param {String} lang language for which method is to be updated 
   * @param {String} method Method to be updated
   */
  const updateVoiceUploadMethod = (lang, method) => {
    if (lang) {
      setVoiceFileUploadMethod((prevStateValue) => {
        const newStateValue = { ...prevStateValue };
        newStateValue[lang] = method;
        return newStateValue;
      });
    }
  }

  // update the voiceFileUploadMethod state
  const handleVoiceFileUploadMethod = (val) => {
    const tempVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, voiceFileInfo);
    setVoiceFileInfo(tempVoiceFileInfo);
    switch (val) {
      case "filePath-tab":
        updateVoiceUploadMethod(selectedLanguage, VOICE_FILE_UPLOAD_TYPE.LIBRARY);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(tempVoiceFileInfo));
        return;
      case "uploadFile-tab":
        updateVoiceUploadMethod(selectedLanguage, VOICE_FILE_UPLOAD_TYPE.UPLOAD);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(tempVoiceFileInfo));
        return;
      case "tts-file-tab":
        updateVoiceUploadMethod(selectedLanguage, VOICE_FILE_UPLOAD_TYPE.TTS);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(tempVoiceFileInfo));
        return;
      default:
        tempVoiceFileInfo[selectedLanguage].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
        updateVoiceUploadMethod(selectedLanguage, VOICE_FILE_UPLOAD_TYPE.LIBRARY);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(tempVoiceFileInfo));
        return;
    }
  }

  // update the voicePath state
  const updateVoiceFilePathState = (value) => {
    const tempVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, voiceFileInfo);
    let info = new VoiceFile();
    info.filePath = value ? value : "";
    info.voiceFileType = voiceFileUploadMethod[selectedLanguage] || VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    tempVoiceFileInfo[selectedLanguage] = info;
    setVoiceFileInfo(tempVoiceFileInfo);
    updateElementAttr(ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(tempVoiceFileInfo));
  }

  // update the element's attribute
  const updateElementAttr = (key, value) => {
    ElementService.updateElement(element, key, value);
  }

  return (
    <>
      <Accordion activeKey={activeAccordionKey} className="mt-3 pmivr-accordion"
        onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
        {
          [...supportedLanguages].map((language) => {
            return (
              <div key={language}>
                <Accordion.Item eventKey={language} className="mt-3 accordion-voice-item">
                  <Accordion.Header onClick={(event) => updateVoiceFileStateInfo(language)}>
                    <span className="pmivr-accordian-tab">
                      Language : {language}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="pmivr-card card-secondary p-3 pt-0">
                    <div className="mt-3">
                      <ul className="nav nav-pills mb-3 pmivr-rounded-circle-tabs text-center" id="pills-tab" role="tablist">
                        <li className="nav-item  px-3 mt-3" role="presentation">
                          <button
                            className={voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.LIBRARY ?
                              "nav-link active d-inline" : "nav-link d-inline"}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Add file path"
                            id="filePath-tab"
                            data-bs-target="#file-path"
                            type="button"
                            role="tab"
                            aria-controls="file-path"
                            aria-selected={voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.LIBRARY}
                            onClick={(event) => { handleVoiceFileUploadMethod(event.currentTarget.id); }}
                          >
                            <i className="bi bi-file-earmark-music"></i>
                          </button>
                          <p className="pt-2">Voice File</p>
                        </li>
                        <li className="nav-item mx-2 mt-3" role="presentation">
                          <button
                            className={voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.TTS ?
                              "nav-link active d-inline" : "nav-link d-inline"}
                            id="tts-file-tab"
                            data-bs-placement="top"
                            title="Text to speech"
                            data-bs-toggle="tooltip"
                            data-bs-target="#tts-voice-file"
                            type="button"
                            role="tab"
                            aria-controls="tts-voice-file"
                            aria-selected={voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.TTS}
                            onClick={(event) => { handleVoiceFileUploadMethod(event.currentTarget.id); }}
                          >
                            <i className="bi bi-chat-square-text"></i>
                          </button>
                          <p className="pt-2">Text To Speech</p>
                        </li>
                        <li className="nav-item mx-2 mt-3" role="presentation">
                          <button
                            className={voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.UPLOAD ?
                              "nav-link active d-inline" : "nav-link d-inline"}
                            id="uploadFile-tab"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Upload voice file"
                            data-bs-target="#upload-file"
                            type="button"
                            role="tab"
                            aria-controls="upload-file"
                            aria-selected={voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.UPLOAD}
                            onClick={(event) => { handleVoiceFileUploadMethod(event.currentTarget.id); }}
                          >
                            <i className="bi bi-cloud-upload"></i>
                          </button>
                          <p className="pt-2">Upload File</p>
                        </li>
                      </ul>
                      <div className="tab-content " id="pills-tabContent">
                        <div id="file-path" role="tabpanel" aria-labelledby="filePath-tab"
                          className={(voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.LIBRARY) ?
                            "tab-pane fade show active pt-3" : "tab-pane fade"}>
                          <div className="form-group custom-input mb-3">

                            <div className="d-flex justify-content-between mt-2">
                              <div className="pmivr-title pt-3">Library Audio File</div>
                              <AudioPlayer filePath={voiceFileInfo[selectedLanguage]?.filePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
                                element={element} action={VOICE_FILE_UPLOAD_TYPE.LIBRARY}></AudioPlayer>
                            </div>

                            <div className="pmivr-label">
                              <label className="pmivr-label">Base file path</label>
                            </div>

                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                              <input disabled type="text" size="50"
                                className="form-control pmivr-input pmivr-disabled"
                                value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`} />
                            </PmivrOverlayTrigger>
                          </div>

                          <div className="form-group custom-input">
                            <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                              <VoiceFilesSelect
                                onSelect={updateVoiceFilePathState}
                                selectedItem={voiceFileInfo[language]?.filePath}
                                selectedLanguage={selectedLanguage}
                              />
                            </PmivrOverlayTrigger>
                          </div>
                        </div>
                        <div id="tts-voice-file" role="tabpanel" aria-labelledby="tts-file-tab"
                          className={(voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.TTS) ?
                            "tab-pane fade show active pt-3" : "tab-pane fade"}>
                          <TextToSpeechOption
                            ref={ttsRef}
                            element={element}
                            selectedLanguage={language}
                            voiceFileInfo={voiceFileInfo}
                            rightPanelEventHandler={rightPanelEventHandler}
                            // As we have upload button so auto save should be false
                            autoSave={false}
                            resetStates={resetStates}
                            showUploadBtn={true}
                          />
                        </div>
                        <div id="upload-file" role="tabpanel" aria-labelledby="uploadFile-tab"
                          className={(voiceFileUploadMethod[language] === VOICE_FILE_UPLOAD_TYPE.UPLOAD) ?
                            "tab-pane fade show active  pt-3" : "tab-pane fade"}>
                          <UploadVoiceFileOption
                            ref={uploadRef}
                            element={element}
                            selectedLanguage={language}
                            voiceFileInfo={voiceFileInfo}
                            rightPanelEventHandler={rightPanelEventHandler}
                            // As we have upload button so auto save should be false
                            autoSave={false}
                            resetStates={resetStates}
                            showUploadBtn={true}
                          />
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            )
          })
        }
      </Accordion>
    </>
  );
}

VoicePropsView.propTypes = {
  // right bar on diagram page panel handler
  rightPanelEventHandler: PropTypes.func,
  element: PropTypes.object,
}

export default VoicePropsView;