import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

import { ATTRIBUTES } from "../../../constants/attributes";
import { TOOLTIP } from "../../../constants/messages";

import PmivrLabel from "../../common/label/pmivr-label";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";

import ElementService from "../../../services/element.service";

/**
 * Providing options (data-types) for readback and pattern in which readback will be prompted for input data 
 * during the agi call.
 * @param {Object} props properties from parent component
 * @returns {React.Component} render readback properties for the user input
 */
const ReadbackInputData = (props) => {
    const { element, disabled } = props;
    // selected data type for readback data input
    const [selectedInputDataType, setSelectedInputDataType] = useState("");

    // selection options for input data types
    const dataTypeOptions = [
        { text: "Alpha", value: "alpha" },
        { text: "Digits", value: "digits" },
        { text: "Amount (Dollars & Cents)", value: "amount" },
        { text: "Number", value: "number" },
        { text: "Date", value: "date" }
    ];

    useEffect(() => {
        const init = () => {
            const readbackType = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE) || "alpha";
            setSelectedInputDataType(readbackType);
        }
        init();
    }, []);

    return (
        <div disabled={disabled}>
            <div className="row">
                <div className="col">
                    <div className="form-group mb-3">
                        <div className={selectedInputDataType ? "pmivr-label" : "pmivr-hide-display"}>
                            <label>Input Data Type ?</label>
                        </div>
                        <select id="input-data-type" className="pmivr-select" disabled={disabled}
                            onChange={(event) => {
                                ElementService.updateElement(element, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE, event.target.value);
                                setSelectedInputDataType(event.target.value);
                            }}
                            value={selectedInputDataType}
                        >
                            {dataTypeOptions.map((option) => {
                                return (
                                    <option key={option.value} value={option.value}>
                                        {option.text}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="form-group mb-3">
                        <PmivrLabel label={"Readback Data Pattern"} tooltip={TOOLTIP.INFO.USER_INPUT_READBACK_PATTERN} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INPUT_DATA_READBACK_PATTERN}>
                            <input id="userInputReadbackPattern" name="userInputReadbackPattern"
                                className="form-control pmivr-input" type="text"
                                value={ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_READBACK_PATTERN, "")}
                                onChange={(event) => {
                                    ElementService.updateElement(
                                        element, ATTRIBUTES.USER_INPUT_READBACK_PATTERN, event.target.value
                                    );
                                }}
                                disabled={disabled} placeholder="Enter readback pattern separated by commas"
                            />
                        </PmivrOverlayTrigger>
                    </div>
                </div>
            </div>
        </div>
    );
}

ReadbackInputData.propTypes = {
    // attributes like isSpeechInput, grammer will be added on selected element
    element: PropTypes.object,
    /**
     * flag specifying whether the user input readback is enabled or not. 
     * If enabled, the configuration options (like readback pattern, readback datatype, etc) 
     * for readback is enabled. Otherwise, disabled.
     */
    disabled: PropTypes.bool
}

export default ReadbackInputData;