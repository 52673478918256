import PropTypes from 'prop-types';

/**
 * Show dnid values with extension and variables configured on the client flows page
 * @param {Object} props props data from parent component
 */
const ShowDnidModal = ({ flowInfo }) => {

    return (
        <>
            <div className="row pb-2" id="info-header">
                <div className={"col-md-12"}>
                    <div className={`card pmivr-card p-4`}>
                        <table className="table voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
                            <thead>
                                <tr>
                                    <th width="20%" className="text-center">
                                        DNID
                                    </th>
                                    <th width="20%" className="text-center">
                                        LEXT
                                    </th>
                                    <th width="20%" className="text-center">
                                        VARIABLE
                                    </th>
                                    <th width="20%" className="text-center">
                                        VALUE
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="pmivr-scroll">
                                {flowInfo?.dnid?.length &&
                                    flowInfo.dnid.map((dnidInfo, index) => (
                                        <tr key={index}>
                                            <td>{dnidInfo?.dnid}</td>
                                            <td>{dnidInfo?.lext ? dnidInfo.lext : "-"}</td>
                                            {dnidInfo?.filledVars?.length > 0 ? (
                                                dnidInfo.filledVars.map((variableInfo, varIndex) => (
                                                    <>
                                                        <td>{variableInfo?.variable}</td>
                                                        <td>{variableInfo?.value}</td>
                                                    </>
                                                ))
                                            ) : (
                                                <>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </>
                                            )}
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
};

// Types of props passed in the component
ShowDnidModal.propTypes = {
    flowInfo: PropTypes.object
}

export default ShowDnidModal;