import PropTypes from 'prop-types';
import PmivrTooltip from "../tooltip/pmivr-tooltip";
import { TASK_ICONS } from '../../../constants/css-classes';

/**
 * Checkbox input to accept input from user on builder
 * @param {{ onChange, value, label, info }} props data from parent component
 * @returns {React.Component} Html code to render dialog
 */

const PmivrCheckbox = ({ onChange, value, label, info }) => {
    return (
        <>
            <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
                <input className="form-check-input" type="checkbox" id="end-call-if-invalid-input"
                    checked={value} onChange={(e) => onChange(e.target.checked)}
                />
                <label className="form-check-label">{label}</label>
            </div>
            {info && (
                <PmivrTooltip message={info}>
                    <i className={`${TASK_ICONS.DISPLAY_INFO} pmivr-text-primary float-end mt-2`}></i>
                </PmivrTooltip>
            )}
        </>
    )
}

PmivrCheckbox.propTypes = {
    // function to be called on Onchange
    onChange: PropTypes.func,
    // value of the checkbox attribute
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
    ]),
    // label for the checkbox
    label: PropTypes.string,
    // the info to be displayed for the current checkbox on hover of info icon
    info: PropTypes.string
}

export { PmivrCheckbox }