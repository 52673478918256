import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { ATTRIBUTES } from "../../../../constants/attributes";
import { MESSAGES, TOOLTIP } from "../../../../constants/messages";
import { VoiceContext } from "../../../../contexts/app-context";
import { SERVICE_TYPES } from "../../../../constants/task-types";

import PmivrOverlayTrigger from "../../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../../common/label/pmivr-label";

import ElementService from "../../../../services/element.service";

/**
 * Component for configuring agi method call service properties.
 * @returns {React.Component} Html element to render
 */
const MethodCallServiceView = () => {
  const { element } = useContext(VoiceContext);
  // array of agi methods configured
  const [agiMethods, setAgiMethods] = useState([]);
  // selected agi method description
  const [selectedMethodDesc, setSelectedMethodDesc] = useState("");
  const [uiState, setUiState] = useState({ agiMethod: '', responseVariable: '' });

  // getting latest value of agi service methods from redux
  const { agiServiceMethods } = useSelector(state => state.config);

  useEffect(() => {
    // loads the agi methods configured
    const init = async () => {
      const firstElement = { "name": MESSAGES.SELECT_STRING, "description": "", "_id": "0" };
      const methodList = [firstElement, ...agiServiceMethods];
      setAgiMethods(methodList);
      // populate data only when method call is selected
      if (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.METHOD_CALL) === SERVICE_TYPES.METHOD_CALL) {
        setUiState({
          ...uiState,
          agiMethod: getAgiMethodName(),
          responseVariable: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, "")
        });
      }
    };
    init();
    // element in dependency array updates the state of method call service
  }, [element]);

  // when agiMethods get loaded then set description 
  useEffect(() => {
    // on load of dropdown of methods set the selected method's description
    const methodName = getAgiMethodName();
    setMethodDescription(methodName);
  }, [agiMethods]);

  /**
   * Update a Method Call attribute in the element.
   * @param {string} attributeName - The name of the attribute to update.
   * @param {string} value - The new value to set for the attribute.
   */
  const updateAttribute = (attributeName, value) => {
    ElementService.updateElement(element, attributeName, value);
  };

  /**
   * Prefix the service method name with required enviornment string and updates the attribute
   * @param {string} attributeName attribute name whose value is to be updated
   * @param {string} value the method name value to be updated
   */
  const setAgiMethodAttribute = (attributeName, value) => {
    // as standard service element needs implementation method to be called like this
    const attrValue = "${environment.services." + value + "}";
    updateAttribute(attributeName, attrValue);
    setUiState({ ...uiState, agiMethod: value });
    // set the method description
    setMethodDescription(value);
  };

  /**
   * Sets the description to show on hover for selected method
   * @param {string} methodName 
   */
  const setMethodDescription = (methodName) => {
    const agiMethod = agiMethods.find((method) => method.name === methodName);
    setSelectedMethodDesc(agiMethod?.description);
  }

  /**
   * Get the method name only from prefixed string as did in setAgiMethodAttribute
   * eg from this "${environment.services.populateDataFromLiability}"; get populateDataFromLiability
   */
  const getAgiMethodName = () => {
    const attributeValue = ElementService.getAttribute(element, ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD, "")
    let methodName = "";
    if (attributeValue) {
      const segments = attributeValue.split('.');
      const lastSegment = segments[segments.length - 1];
      // from last segment trim last char }
      methodName = lastSegment.split("}")[0];
    }
    return methodName;
  };

  /**
   * Handles attributes and variables update for response variable
   * @param {Object} event object for the input change for response variable
   */
  const handleResponseVariableChange = (event) => {
    setUiState({ ...uiState, responseVariable: event.target.value });
    updateAttribute(ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, event.target.value);
    // setting the service type
    updateAttribute(ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.METHOD_CALL);
  }

  return (
    <div className=" m-2 mt-3">
      {/* agi service method */}
      <div className="form-group mb-3">
        <PmivrLabel label="Select AGI Method Name" tooltip={TOOLTIP.INFO.SERVICE_TASK_METHOD_NAME} />
        <PmivrOverlayTrigger tooltip={selectedMethodDesc}>
          <select id="implementation" name="implementation" className="form-control pmivr-select"
            value={uiState.agiMethod || MESSAGES.SELECT_STRING}
            onChange={(event) => {
              setAgiMethodAttribute(ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD, event.target.value);
              // setting the service type
              updateAttribute(ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.METHOD_CALL);
            }}
          >
            {agiMethods.map((agiMethod, index) => (
              <option key={index} value={agiMethod.name} disabled={agiMethod.name === MESSAGES.SELECT_STRING}>
                {agiMethod.name}
              </option>
            ))}
          </select>
        </PmivrOverlayTrigger>
      </div>

      {/* response variable */}
      <div className="form-group mb-3">
        <PmivrLabel label="Enter Response Variable" tooltip={TOOLTIP.INFO.SERVICE_IMPL_METHOD_RESPONSE_VARIABLE} />
        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.SERVICE_IMPL_RESPONSE_VARIABLE}>
          <input id="responseVariable" name="responseVariable" className="form-control pmivr-input"
            value={uiState.responseVariable}
            onChange={handleResponseVariableChange}
          />
        </PmivrOverlayTrigger>
      </div>
    </div>
  );
};

export default MethodCallServiceView;