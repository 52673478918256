import { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Accordion from "react-bootstrap/Accordion";

import { useSelector, useDispatch } from "react-redux";

import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from '../../../constants/messages';
import { VOICE_FILE_DEFAULT_LANGUAGE } from '../../../constants/voice-file';

import { updateSystemVoiceFiles } from '../../../redux/actions/client.action';

import PmivrSnackBar from '../../../components/common/dialog/pmivr-snackbar';
import { PmivrDialog } from '../../../components/common/dialog/pmivr-dialog';
import EditSystemVoiceFiles from './edit-system-voice-files';
import SystemVoiceFileContent from './system-voice-file-content';

import AudioService from '../../../services/audio.service';

/**
 * The component for listing system voice files being configured and used internally in AGI call
 * @returns {React.Component} Listing of system voice files to render on UI
 */
const SystemVoiceFilesComponent = ({ langConfigured }) => {
  // to dispatch the data in redux
  const dispatch = useDispatch();
  // snackbar to display messages
  const snackbarRef = useRef();
  const { systemVoiceFiles } = useSelector(state => state.client);
  // boolean value to show voice file settings menu
  const [uiState, setUiState] = useState({ showVoiceFileSettingMenu: false, systemVoiceFiles: [] });
  // language selected in voice file info and update if change in the modal
  const [selectedLanguage, setSelectedLanguage] = useState('');
  // voice file path for the select menu
  const [voiceFilePath, setVoiceFilePath] = useState('');
  // information about the system voice file (file path,language)
  const [voiceFileInfo, setVoiceFileInfo] = useState({});
  // voice file object to temporarily save user changes
  const [tempSystemVoiceFiles, setTempSystemVoiceFiles] = useState([]);
  const [filter, setFilter] = useState({
    searchText: '',// serach for voice file in system voice files
  });

  useEffect(() => {
    const init = async () => {
      const voiceFiles = await AudioService.getSystemVoiceFiles();
      setUiState({ ...uiState, systemVoiceFiles: voiceFiles?.data });
      setTempSystemVoiceFiles(voiceFiles?.data);
      setSelectedLanguage(VOICE_FILE_DEFAULT_LANGUAGE);
      dispatch(updateSystemVoiceFiles({ systemVoiceFiles: voiceFiles?.data }));
    };
    init();
  }, []);

  /**
   * revert the changes of the voice file setting menu
   * @param {Object} event 
   */
  const handleCloseVoiceFileSettingMenu = async (event) => {
    setTempSystemVoiceFiles(uiState?.systemVoiceFiles);
    setUiState({ ...uiState, showVoiceFileSettingMenu: false });
  };

  /**
   * update the voice file state information when language tab is changed
   * @param {string} language selected language
   * @param {{fileName,language}} voiceFileInfo selected voice file information object
   */
  const updateVoiceFileStateInfo = async (language, voiceFileInfo) => {
    const voiceFilePath = voiceFileInfo?.language[language]?.filePath || null;
    setVoiceFilePath(voiceFilePath);
    setSelectedLanguage(language);
  };

  /**
   * save the changes of the voice file setting menu and update the system voice files
   * @param {Object} event 
   */
  const handleSaveVoiceFileSettingMenu = async (event) => {
    // updates the system voice files
    const updatedVoiceFile = await AudioService.updateSystemVoiceFiles(tempSystemVoiceFiles);
    setTempSystemVoiceFiles(updatedVoiceFile?.data);
    setUiState({ ...uiState, showVoiceFileSettingMenu: false, systemVoiceFiles: updatedVoiceFile?.data });
    // save it in redux as well to avoid any loss of data
    dispatch(updateSystemVoiceFiles({ systemVoiceFiles: updatedVoiceFile?.data }));
    setFilter({ ...filter, searchText: "" });
    // show the success message on saving the system voice file
    snackbarRef.current.open(MESSAGES.SYSTEM_VOICE_FILE_UPDATED);
  };

  /**
   * update voice file path state when edit option modal is activated
   * @param {Object} event 
   * @param {string} fileName key value identifier to fetch voice files
   */
  const handleVoiceFileSettingMenu = async (event, fileName) => {
    // to stop opening of accordian when action button is clicked
    event.stopPropagation();
    const voiceFileInfo = _getVoiceFileInfo(fileName, selectedLanguage);
    const voiceFilePath = voiceFileInfo?.language[selectedLanguage]?.filePath || null;
    setVoiceFilePath(voiceFilePath);
    setVoiceFileInfo(voiceFileInfo);
    setUiState({ ...uiState, showVoiceFileSettingMenu: true });
  };

  /**
   * update the voicePath state for select menu
   * @param {string} value selected file path
   * @param {{fileName,language}} voiceFileInfo selected voice file information object
   */
  const updateVoiceFilePathState = (value, voiceFileInfo) => {
    const updateVoiceFiles = _updateFilePath(value, voiceFileInfo?.fileName);
    setTempSystemVoiceFiles(updateVoiceFiles);
    setVoiceFilePath(value);
  };

  /**
   * fetches the updated voice files object
   * @param {string} value selected file path
   * @param {string} fileName key value identifier to fetch voice files
   * @returns {Array<{fileName,language}>} updated system voice files array
   */
  const _updateFilePath = (value, fileName) => {
    return tempSystemVoiceFiles.map((item) => {
      if (item.fileName === fileName) {
        return {
          ...item,
          language: {
            ...item.language,
            [selectedLanguage]: {
              ...item.language[selectedLanguage],
              filePath: value,
            },
          },
        };
      }
      return item;
    });
  };

  /**
   * extract the voice file information from the system voice files array
   * @param {string} fileName key value identifier to fetch voice files
   * @returns {{fileName,language}} selected voice file info object
   */
  const _getVoiceFileInfo = (fileName) => {
    const file = uiState?.systemVoiceFiles.find((file) => file.fileName === fileName);
    return file ? file : null;
  };

  // Wrap tooltip
  const tooltip = (props, info) => <Tooltip {...props}>{info}</Tooltip>;

  /**
   * Saving the search input in state
   * @param {string} textTyped
   */
  const searchInput = (textTyped) => {
    // updating the state object
    setFilter({ ...filter, searchText: textTyped });
  };

  /**
   * Filter the searched text in the voice file list.
   */
  const filterVoiceFiles = () => {
    if (filter.searchText) {
      const filteredVoicefile = systemVoiceFiles.filter(file =>
        file.fileName.toLowerCase().includes(filter.searchText.trim().toLowerCase())
      );
      setUiState({ ...uiState, systemVoiceFiles: filteredVoicefile });
    }
  };

  /**
   * Clear all the search and filters (reset the content)
   */
  const clearAll = () => {
    // clearing the cache (reseting the state object)
    setFilter({ ...filter, searchText: "" });
    // updating cache with original list
    setUiState({ ...uiState, systemVoiceFiles: systemVoiceFiles });
  };

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="pmivr-container">
        <div className="wrapper pmivr-voice-file-list p-3">
          <div className="row">
            {/* Search */}
            <div className="col-md-3">
              <div className="search-voice-file mb-2">
                <div className="pmivr-label">
                  <label>Search File Name: </label>
                </div>
                <input type="text" value={filter.searchText} className="form-control pmivr-input" placeholder="Search Voice File Text" onChange={(e) => searchInput(e.target.value)} />
                <span className="btn-search">
                  <button className="pmivr-btn-transparent" onClick={filterVoiceFiles}>
                    <i className="bi bi-search"></i>
                  </button>
                </span>
              </div>
            </div>
            {/* Filter */}
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-1 mt-3 pt-1">
                  <button type="button" onClick={clearAll} className="pmivr-btn-app pmivr-reset-link">Reset</button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <table className="table voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
              <thead>
                <tr>
                  <th width="20%" className="text-center">
                    Voice File Name
                  </th>
                  <th width="20%" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="pmivr-scroll">
                <tr>
                  <td>
                    <Accordion defaultActiveKey="0" flush>
                      {
                        uiState?.systemVoiceFiles.map((value, index) =>
                          <tr id="main-tr">
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header className="main-table-content">
                                {/* File Name */}
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, value.fileName)}>
                                  <td width="50%" className="text-center pt-2 ms-4"> {value.fileName}</td>
                                </OverlayTrigger>

                                {/* Action */}
                                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, TOOLTIP.INFO.CONFIGURE_VOICE_FILE)}>
                                  <td width="50%" className="text-center ms-5">
                                    <Button className="pmivr-btn-rounded-circle pmivr-btn-app rounded-circle"
                                      variant="primary" onClick={(event) => { handleVoiceFileSettingMenu(event, value.fileName); }}
                                      data-bs-toggle="tooltip" data-bs-placement="right" >
                                      <i className="bi bi-pencil"></i>
                                    </Button>
                                  </td>
                                </OverlayTrigger>

                              </Accordion.Header>
                              <Accordion.Body>
                                <tr id={value.fileName} className="inner-tr-class">
                                  <table className="pmivr-table">
                                    <tbody>
                                      <SystemVoiceFileContent languageInfo={value?.language} />
                                    </tbody>
                                  </table>
                                </tr>
                              </Accordion.Body>
                            </Accordion.Item>
                          </tr>
                        )
                      }
                    </Accordion>
                  </td>
                </tr>
              </tbody>
            </table>
            <PmivrDialog showDialog={uiState.showVoiceFileSettingMenu} closeDialog={handleCloseVoiceFileSettingMenu}
              title={`System Voice File`} message={<EditSystemVoiceFiles supportedLanguages={langConfigured}
                updateVoiceFileStateInfo={updateVoiceFileStateInfo} selectedLanguage={selectedLanguage}
                voiceFilePath={voiceFilePath} voiceFileInfo={voiceFileInfo} updateVoiceFilePathState={updateVoiceFilePathState} />}
              saveDialogChanges={handleSaveVoiceFileSettingMenu} />
          </div>
        </div>
      </div>
    </>
  );
};

SystemVoiceFilesComponent.propTypes = {
  // array of supported languages
  langConfigured: PropTypes.array
};
export default SystemVoiceFilesComponent;
