import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import PropTypes from 'prop-types';

// Placement positions for tooltip
const TOOLTIP_POSITIONS = {
    BOTTOM: "bottom",
    TOP: "top",
    LEFT: "left",
    RIGHT: "right",
    AUTO: "auto"
};

const PmivrTooltip = ({ children, message, position=TOOLTIP_POSITIONS.AUTO }) => {
    const wrapToolTip = (info) => <Tooltip className="pmivr-tooltip">{info}</Tooltip>;

    return (
        <OverlayTrigger placement={position} delay={{ show: 250, hide: 400 }} overlay={wrapToolTip(message)}>
            {children}
        </OverlayTrigger>
    );
};

PmivrTooltip.propTypes = {
    // message for the tooltip
    message: PropTypes.string,
    // children passed like button
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.element)
    ]),
    // Position of the tooltip (top, bottom etc.)
    position: PropTypes.string
}

export { TOOLTIP_POSITIONS };
export default PmivrTooltip;