import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { VARIABLE_TYPES } from "../../constants/flow.js";
import { MESSAGES, TOOLTIP } from "../../constants/messages.js";
import { APP_PAGES } from "../../constants/app-pages.js";

import AppUtil from "../../util/app.util.js";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import PmivrTooltip from "../../components/common/tooltip/pmivr-tooltip.js";

import ClientService from "../../services/client.service";
import UserService from "../../services/user.service.js";
import FlowService from "../../services/flow.service.js";

/**
 * Displays all the variables required by the flow
 */
const Variables = () => {
    // navigating to pages
    const navigate = useNavigate();
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // latst state from redux store
    let { businessCode, variables, selectedFlowTypeInfo } = useSelector(state => state.client);
    // count for various types of variables
    const [variableCount, setVariableCount] = useState({ flowVariableCount: 0, systemVariableCount: 0 });
    // cache for the flow information (businessCode, doc versionId, etc)
    const [flowInfo, setFlowInfo] = useState([]);
    // info related to different types of variables (flow variables, system variables)
    const [allVariables, setAllVariables] = useState([]);
    // variable types to be displayed (system variables, flow variables)
    const [varType, setVarType] = useState();
    // object containing all kinds filtering properties
    const [filterObj, setFilterObj] = useState({ filterText: '' });  // text to be filtered
    // title hints displayed for different types of variables
    const TITLE = {
        SYSTEM_VARIABLE: "VARIABLES COMMON FOR ALL CLIENTS",
        FLOW_VARIABLE: "VARIABLES FOR GIVEN DOC VERSION"
    };

    useEffect(() => {
        // check if token exists if not then redirect to login page.
        const currentUser = UserService.getCurrentUser();
        if (!currentUser?.token) {
            navigate(APP_PAGES.LOGIN);
        }
        variableInit();
    }, []);

    /**
     * Initializing the variables at page load
     */
    const variableInit = async () => {
        try {
            if (!businessCode) {
                const basicFlowInfo = FlowService.getBasicFlowInfo();
                businessCode = basicFlowInfo.businessCode;
            }
            // populating state object flowInfo from cache
            const flow = await ClientService.getFlowInfo(businessCode);
            setFlowInfo(flow);
            // setting all variables in local state
            setAllVariables(variables);
            setVariableCount({
                ...variableCount,
                flowVariableCount: variables.filter(variable => variable.varType === VARIABLE_TYPES.FLOW_VARIABLES).length,
                systemVariableCount: variables.filter(variable => variable.varType === VARIABLE_TYPES.SYSTEM_VARIABLES).length
            });
            // at first time, setting: display all var types
            setVarType(VARIABLE_TYPES.ALL_TYPES_VARIABLES);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.UNABLE_TO_LOAD_VARIABLES);
            }
        }
    };

    /**
    * Filter Records as per input
    * Populating values (filter text) on changing the value of text field
    * @param {Object} event
    */
    const setFilterText = (text) => setFilterObj({ filterText: text });

    /**
     * Filter Records as per input (filter key and filter text)
     */
    const getSearchedVariables = async () => {
        // On Filtering based on filterKey and VARIABLE_TYPES
        const searchedVariables = [];

        if (filterObj.filterText) {
            // filter flowVariables
            if (varType === VARIABLE_TYPES.ALL_TYPES_VARIABLES || varType === VARIABLE_TYPES.FLOW_VARIABLES) {
                const flowVariables = variables?.filter(variable => variable.varType === VARIABLE_TYPES.FLOW_VARIABLES) || [];
                searchedVariables.push(...filterVariables(flowVariables));
            }
            // filter systemVariables
            if (varType === VARIABLE_TYPES.ALL_TYPES_VARIABLES || varType === VARIABLE_TYPES.SYSTEM_VARIABLES) {
                const systemVariables = variables?.filter(variable => variable.varType === VARIABLE_TYPES.SYSTEM_VARIABLES) || [];
                searchedVariables.push(...filterVariables(systemVariables));
            }
            // set variables
            setAllVariables(searchedVariables);
        }
    };

    /**
     * Filter variables by field and text and return the list of filtered variables
     * @param {List} variables 
     * @returns {Array} Returns list of filtered variables
     */
    const filterVariables = (variables) => {
        const query = filterObj.filterText?.trim().toUpperCase();
        const filteredVariables = variables.filter((variable) => {
            return variable["name"]?.toUpperCase().includes(query);
        });
        return filteredVariables;
    };

    /**
    * Clear all the search and filters (reset the content)
    */
    const clearAll = async () => {
        // reset filter option
        setFilterObj({ filterText: "" });
        // get all variables and reset state
        variableInit();
    };

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <div className="pmivr-variable">
                {/* Navigation Bar */}
                <div className="row  border-bottom  px-3 pb-3 pt-3">
                    <div className="col-lg-6">
                        <div className="px-1  pmivr-breadcrumb-list">
                            {selectedFlowTypeInfo?.flowName} : <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.HOME}>
                                <Link to="/home">Home</Link></PmivrTooltip>/
                            <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.FLOWS}>
                                <Link to={`${APP_PAGES.CLIENT_FLOWS}/${flowInfo.businessCode}`}>{flowInfo.businessCode}</Link>
                            </PmivrTooltip>/
                            <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.DIAGRAM}>
                                <Link to={`${APP_PAGES.DIAGRAM}/${flowInfo.businessCode}/${flowInfo.status}/${flowInfo.docVersionId}`}>
                                    {flowInfo.status}
                                </Link>
                            </PmivrTooltip>/variables
                        </div>
                    </div>
                </div>
                <div className="row mx-3">
                    <div className="col-lg-4">
                        <div className="row">
                            <div className={"col-md-8 mt-3"}>
                                <div className="filter-variables">
                                    <input
                                        type="text"
                                        className="form-control pmivr-input"
                                        value={filterObj.filterText}
                                        placeholder="Search Variable"
                                        onChange={(e) => setFilterText(e.target.value)}
                                        onKeyDown={(e) => AppUtil.isEnterKey(e) ? getSearchedVariables() : null}
                                    />
                                    <span className="btn-search">
                                        <button className="pmivr-btn-transparent" onClick={getSearchedVariables}>
                                            <i className="bi bi-search"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-4 mt-3 pt-1">
                                <button type="button" onClick={clearAll} className="pmivr-btn-app pmivr-reset-link">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-8 text-end"}>
                        <div className="mt-4">
                            <div className="form-check pmivr-check-radio form-custom-inline">
                                <input
                                    className="form-check-input"
                                    id={VARIABLE_TYPES.ALL_TYPES_VARIABLES}
                                    type="radio"
                                    value={VARIABLE_TYPES.ALL_TYPES_VARIABLES}
                                    name={VARIABLE_TYPES.ALL_TYPES_VARIABLES}
                                    checked={varType === VARIABLE_TYPES.ALL_TYPES_VARIABLES}
                                    onChange={(e) => { setVarType(e.target.value); }}
                                />
                                <label className="form-check-label" htmlFor="allVar">
                                    All Variables
                                </label>
                            </div>

                            <div className="form-check pmivr-check-radio form-custom-inline">
                                <input
                                    className="form-check-input"
                                    id={VARIABLE_TYPES.SYSTEM_VARIABLES}
                                    type="radio"
                                    value={VARIABLE_TYPES.SYSTEM_VARIABLES}
                                    name={VARIABLE_TYPES.ALL_TYPES_VARIABLES}
                                    title={TITLE.SYSTEM_VARIABLE}
                                    onChange={(e) => { setVarType(e.target.value); }}
                                />
                                <label className="form-check-label" htmlFor="systemVar">
                                    System Variables
                                </label>
                            </div>

                            <div className="form-check pmivr-check-radio form-custom-inline">
                                <input
                                    className="form-check-input"
                                    id={VARIABLE_TYPES.FLOW_VARIABLES}
                                    type="radio"
                                    value={VARIABLE_TYPES.FLOW_VARIABLES}
                                    name={VARIABLE_TYPES.ALL_TYPES_VARIABLES}
                                    title={TITLE.FLOW_VARIABLE}
                                    onChange={(e) => { setVarType(e.target.value); }}
                                />
                                <label className="form-check-label" htmlFor="flowVar">
                                    Flow Variables
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-content p-3">
                    <table className="table variables-table pmivr-table header-fixed border">
                        <thead >
                            <tr>
                                <th width="25%" className="text-start">Name</th>
                                <th width="8%" className="text-center" title={TITLE.FLOW_VARIABLE}>Flow Variables</th>
                                <th width="8%" className="text-center" title={TITLE.SYSTEM_VARIABLE}>System Variables</th>
                                <th width="25%" className="text-center">Element Name</th>
                            </tr>
                        </thead>

                        <tbody className="pmivr-scroll">
                            {/* displaying the variables list */}
                            {allVariables?.length
                                ? allVariables.map((variable, index) =>
                                    (varType === VARIABLE_TYPES.ALL_TYPES_VARIABLES) || (varType === variable.varType) ?
                                        <tr key={index}>
                                            <td width="25%" className="text-start">{variable.name}</td>
                                            <td width="8%">
                                                <div className="form-check pmivr-check-radio text-center  ">
                                                    <input className="form-check-input center  mt-2" type="checkbox" disabled="disabled" checked={variable.isFlowVar} />
                                                </div>
                                            </td>
                                            <td width="8%">
                                                <div className="form-check pmivr-check-radio text-center  ">
                                                    <input className="form-check-input center  mt-2" type="checkbox" disabled="disabled" checked={variable.isSysVar} />
                                                </div>
                                            </td>
                                            <td width="25%" className="text-center">{variable.stepName}</td>
                                        </tr>
                                        : <></>
                                ) : <p className="text-center pt-3">{MESSAGES.ERR.VARIABLES_NOT_AVAILABLE}</p>
                            }
                            {/* if variables are there, but not of particular type when selected  */}
                            {(allVariables?.length && ((varType === VARIABLE_TYPES.SYSTEM_VARIABLES && !variableCount.systemVariableCount)
                                || (varType === VARIABLE_TYPES.FLOW_VARIABLES && !variableCount.flowVariableCount))) ?
                                <p className="text-center pt-3">{MESSAGES.ERR.VARIABLES_NOT_AVAILABLE}</p> : <></>}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Variables;