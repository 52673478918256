class DateUtil {
    /**
     * Convert date string to readable string format (like data string of mongo db)
     * @param {string} date date string 
     * @returns {string}
     */
    static toDateString(date) {
        const dateObj = new Date(date);
        return dateObj.toDateString();
    };
}

export default DateUtil;