import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PropTypes from 'prop-types';

import { APP_PAGES } from "../../../../constants/app-pages";
import { CSS_CLASSES } from "../../../../constants/css-classes";
import { MESSAGES, TOOLTIP } from "../../../../constants/messages";
import { APP_CONFIG } from "../../../../config/config";
import { FLOW_STATUS, TEMPLATE_VERSIONS_ACTIONS } from "../../../../constants/flow";

import { updateVersion, updateSelectedFlowType } from "../../../../redux/actions/client.action";

import AppUtil from "../../../../util/app.util";

import DocVersionListItem from "./doc-version-list-item";
import PmivrSnackBar from "../../../../components/common/dialog/pmivr-snackbar";
import { MessageBox } from "../../../../components/common/message-box/message-box";
import PmivrTooltip from "../../../../components/common/tooltip/pmivr-tooltip";
import { PmivrDialog } from "../../../../components/common/dialog/pmivr-dialog";
import UploadXmlFile from "../upload-xml-file/upload-xml-file";

import FlowService from "../../../../services/flow.service";
import ClientService from "../../../../services/client.service";
import UserService from "../../../../services/user.service";

/**
 * List of different published and draft versions of the flows
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render list
 */
const DocVersionList = (props) => {
    const { versionStatusInfo, flowInfo, showHistoryDialogBox, onToggleRepublishDialog, onFlagStatusChange,
        filterFlowFlag, onFlowFilterChange, onScroll } = props;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const snackbarRef = useRef();

    // latest state from redux store
    let { businessCode, published } = useSelector(state => state.client);
    businessCode = businessCode ? businessCode : location.pathname.split("/").at(-1);

    // get the latest published from published flows array
    const latestPublished = (published?.length) ? published[0] : null;

    // ui alert state values
    const [messageBox, setMessageBox] = useState({
        showMessage: false, heading: `${flowInfo.flowName}: ${MESSAGES.UPDATE_TO_LATEST_FLOW_HEADING}`
    });

    // show and hide the dialogs like base flow , alreadyMigratedFlow dialog
    // latestTemplateInfo store the latest template info 
    const [uiState, setUiState] = useState({
        showDialog: false, showBaseFlowDialog: false, latestTemplateInfo: {},
        showAlreadyMigratedFlowDialog: false
    });

    // filter options for number status
    const FLAG_STATUS = [
        { text: "ALL", value: "" },
        { text: "Working", value: "working" },
    ];

    /**
     * fetching info from dnidConfig and template flow info, and checking whether to show message to update flow and add 
     * the latest base flow in draft
     */
    useEffect(() => {
        const init = async () => {
            let showMessage = false;
            /**
             * fetching the flow template details to check when was the template was updated and which billers have
             * added the latest flow onto the draft list
             */
            const flowTemplateDetails = await FlowService.getTemplatesInfo(flowInfo.flowTypeId);
            /**
             * fetching the dnid config details to check the when the biller was onboarded.
             * Whether biller is onboarded before / after the flow was updated.
             */
            const res = await ClientService.getDnidConfig({ flowName: flowInfo.flowName, businessCode });
            const dnidConfig = res.length ? res[0] : {};
            // date when biller is onboarded
            const billerOnboardedDate = dnidConfig.createdOn;
            if (flowTemplateDetails?.length) {
                // fetching latest template details from the list
                const latestTemplateDetail = flowTemplateDetails[0];
                setUiState({ ...uiState, latestTemplateInfo: flowTemplateDetails[0] });
                const billersUpdated = latestTemplateDetail.billersUpdated;
                const templateUpdateDate = latestTemplateDetail.updatedOn;
                // checking whether the biller has not updated it and biller is onboarded before the template is updated, then show alert
                if (templateUpdateDate > billerOnboardedDate && !billersUpdated.includes(businessCode)) {
                    showMessage = true;
                }

            }
            // base flow has not been updated
            setMessageBox({
                ...messageBox, showMessage,
                heading: `${flowInfo.flowName}: ${MESSAGES.UPDATE_TO_LATEST_FLOW_HEADING}`
            });
        }
        init();
    }, [flowInfo.flowTypeId]);

    /**
     * function is used to display the different versions for the docs. 
     * @param {Array} documents 
     * @param {string} flowType 
     * @param {boolean} isLatest 
     * @returns {string} jsx element for displaying doc
     */
    const displayFlow = (documents, flowType, isLatest) => {
        if (!documents || !documents.length) {
            return <li><p>Not Available</p></li>
        } else {
            if (isLatest) {
                return <DocVersionListItem
                    navigateToFlow={navigateToFlow}
                    showHistoryDialogBox={showHistoryDialogBox} onDownloadFlowXml={onDownloadFlowXml}
                    doc={documents[0]} flowType={flowType} isLatest={true}
                />
            } else {
                return documents.slice(1).map((doc) => {
                    return <span className="old-version-list"><DocVersionListItem
                        navigateToFlow={navigateToFlow}
                        showHistoryDialogBox={showHistoryDialogBox} onDownloadFlowXml={onDownloadFlowXml}
                        doc={doc} flowType={flowType} onToggleRepublishDialog={onToggleRepublishDialog} onFlagStatusChange={onFlagStatusChange}
                    /></span>
                });
            }
        }
    }

    /**
     * Navigate to the flow diagram on selection of flow type and version id
     * @param {string} flowType Flow type selected (draft / published)
     * @param {string} versionId Doc version Id selected
     * @param {boolean} isMigratedFlow flag to check if latest draft is opened from message box button
     */
    const navigateToFlow = async (flowType, versionId, isMigratedFlow = false) => {
        // save in the local storage
        const basicFlowInfo = FlowService.getBasicFlowInfo();
        basicFlowInfo.docVersionId = versionId;
        basicFlowInfo.flowType = flowType;
        basicFlowInfo.businessCode = businessCode;
        basicFlowInfo.flowName = flowInfo.flowName;
        basicFlowInfo.flowTypeId = flowInfo.flowTypeId;
        basicFlowInfo.isMigratedFlow = isMigratedFlow;
        FlowService.setBasicFlowInfo(basicFlowInfo);

        // updating the redux state
        dispatch(updateVersion({ versionId }));
        dispatch(updateSelectedFlowType({ flowType }));
        navigate(`${APP_PAGES.DIAGRAM}/${businessCode}/${flowType}/${versionId}`);
    }

    /**
     * handle action of rebasing the biller created flow
     */
    const createDraftFromLatestBaseFlow = async () => {
        try {
            const flowInformation = {
                businessCode: businessCode, flowName: flowInfo.flowName, flowTypeId: flowInfo.flowTypeId,
                dnid: flowInfo.dnid
            }
            await ClientService.createDraftFromLatestBaseFlow(flowInformation);
            snackbarRef.current.open(MESSAGES.REBASED_SUCCESSFULLY);
            setUiState({ ...uiState, showBaseFlowDialog: false });
            // added timeout, so that snackbar message is displayed for some time
            setTimeout(() => { navigate(0); }, APP_CONFIG.MESSAGE_TIMEOUT);
        } catch (err) {
            snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
        }
    }

    /**
     * Uploads the XML content if valid.
     * @param {{file, name, comments}} values - Values from formik
     */
    const uploadFlowXml = async (values) => {
        const selectedFile = values.file;
        if (selectedFile) {
            // Check if the selected file is an XML file
            if (selectedFile.type === "text/xml") {
                // Read the content of the file
                const reader = new FileReader();
                reader.onload = async () => {
                    const xml = reader.result;
                    // validate the xml
                    const isValidXml = AppUtil.isXmlValid(xml);
                    if (isValidXml) {
                        const flowData = {
                            flowName: flowInfo.flowName, flowTypeId: flowInfo.flowTypeId,
                            status: FLOW_STATUS.DRAFT, businessCode, xml: xml, metaInfo: { voiceFiles: [] },
                            comment: { name: values.name, description: values.comments },
                            isDeleted: false, dnid: flowInfo.dnid
                        }
                        // upload the flow and generate a new draft version for the uploaded xml
                        const billersUpdated = uiState?.latestTemplateInfo?.billersUpdated;
                        if (values.isBaseFlow && !billersUpdated?.includes(businessCode)) {
                            const templateInfo = {
                                versionId: uiState.latestTemplateInfo?.versionId, businessCode,
                                action: TEMPLATE_VERSIONS_ACTIONS.ADD_BILLER
                            };
                            await FlowService.updateTemplateInfo(templateInfo);
                        }
                        const uploadedFlow = await FlowService.uploadFlow(flowData);
                        navigateToFlow(FLOW_STATUS.DRAFT, uploadedFlow?.data?.versionId);
                    } else {
                        snackbarRef.current.open(MESSAGES.ERR.INVALID_XML_FILE);
                    }
                };
                reader.readAsText(selectedFile);
            } else {
                // Display an error message when a non-XML file is selected
                snackbarRef.current.open(MESSAGES.ERR.UPLOAD_XML_FILE);
            }
        }
    }

    /**
     * Downloads the XML data of a flow document.
     * @param {{flowName, flowTypeId, businessCode, status, versionId}} doc - The document containing information about the flow.
     */
    const onDownloadFlowXml = async (doc) => {
        try {
            const flowInfo = {
                flowName: doc?.flowName, flowTypeId: doc?.flowTypeId, businessCode: doc?.businessCode,
                status: doc?.status, versionId: doc?.versionId
            };
            // download the xml for flow document from server
            const response = await FlowService.downloadFlowXml(flowInfo);
            const flowXml = response?.data;
            // generate URL for anchor tag to download
            const url = AppUtil.generateXmlDownloadUrl(flowXml);
            // Create a temporary link element
            const link = document.createElement('a');
            link.href = url;
            // name of the xml file downloaded
            link.download = `flow_${doc?.businessCode}_${doc?.flowName}_${doc?.status}_${doc?.versionId}.xml`;
            document.body.appendChild(link);
            // automatically click the link created
            link.click();
            // show downloaded successfully message
            snackbarRef.current.open(MESSAGES.DOWNLOADED_SUCCESSFULLY);

            // Clean up by removing the link and revoking the URL
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Set base flow dialog state, opens the dialog for Update flow migration
     */
    const setUpdateBaseFlowDialogState = () => {
        setUiState({ ...uiState, showBaseFlowDialog: true });
    }

    /**
     * Handle already migrated flow
     * Once user clicks on already migrated flow then user can avoid seeing the message alert for flow update
     */
    const handleAlreadyMigratedFlow = async () => {
        try {
            // mark that biller has migrated the flow and , now message not to be shown
            const templateInfo = {
                versionId: uiState.latestTemplateInfo?.versionId,
                businessCode, action: TEMPLATE_VERSIONS_ACTIONS.ADD_BILLER
            };
            await FlowService.updateTemplateInfo(templateInfo);
            // close the dialog box
            setUiState({ ...uiState, showAlreadyMigratedFlowDialog: false });
            setMessageBox({ ...messageBox, showMessage: false });
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Set already migrated dialog and ask user to continue if he has done migration
     * Telling him that he has migrated and continue
     */
    const setAlreadyMigratedDialogState = () => {
        setUiState({ ...uiState, showAlreadyMigratedFlowDialog: true });
    }

    /**
     * Navigating to latest draft on manually migrating button click and user can publish it as latest flow
     */
    const navigateToLatestDraft = () => {
        navigateToFlow(versionStatusInfo.latestDraft?.status, versionStatusInfo.latestDraft?.versionId, true);
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            {(messageBox?.showMessage) &&
                <MessageBox heading={messageBox.heading} message={
                    ` New version is available and you are on an older version.
                      Update is available that will create a new Draft version.<br>
                      <strong>Note</strong> - This will not migrate your custom changes made on the flow.`
                } dismissible={false}
                    buttons={[{
                        label: "Already Migrated", handleAction: setAlreadyMigratedDialogState,
                        tooltipMsg: TOOLTIP.INFO.ALREADY_MIGRATED_FLOW, cssClass: "pmivr-btn-cancel"
                    },
                    {
                        label: "Migrate Manually", handleAction: navigateToLatestDraft,
                        tooltipMsg: TOOLTIP.INFO.MIGRATE_MANUALLY, cssClass: "pmivr-btn-app"
                    },
                    {
                        label: "Update Flow", handleAction: setUpdateBaseFlowDialogState,
                        tooltipMsg: TOOLTIP.INFO.UPDATE_FLOW, cssClass: "pmivr-btn-app"
                    }
                    ]} />
            }

            {/* Dialog box for upload base flow from message box*/}
            <PmivrDialog showDialog={uiState.showBaseFlowDialog}
                closeDialog={() => setUiState({ ...uiState, showBaseFlowDialog: false })}
                title={`Are you sure to migrate to latest base flow`}
                message={<>{"Once you update the flow. It will create a new draft version."}</>}
                footer={<>
                    <button className="pmivr-btn-cancel" onClick={() => setUiState({ ...uiState, showBaseFlowDialog: false })}>
                        Cancel
                    </button>
                    <button className="pmivr-btn-app" onClick={() => createDraftFromLatestBaseFlow()}>
                        Update Flow
                    </button>
                </>} />

            {/* Dialog box to upload the xml file and enter name and comments */}
            <PmivrDialog showDialog={uiState.showDialog} closeDialog={() => setUiState({ ...uiState, showDialog: false })}
                title={`Upload Flow XML for: ${flowInfo?.flowName}`}
                message={<UploadXmlFile flowInfo={flowInfo} uploadFlowXml={uploadFlowXml}
                    closeAction={() => setUiState({ ...uiState, showDialog: false })} />}
                footer={<></>} />

            {/* Dialog box for already migrated flow just mark it migrated*/}
            <PmivrDialog showDialog={uiState.showAlreadyMigratedFlowDialog}
                closeDialog={() => setUiState({ ...uiState, showAlreadyMigratedFlowDialog: false })}
                title={`Already migrated the flow`}
                message={<>{"Since you already migrated the flow. Are you sure to continue."}</>}
                footer={<>
                    <button className="pmivr-btn-cancel" onClick={() => setUiState({ ...uiState, showAlreadyMigratedFlowDialog: false })}>
                        Cancel
                    </button>
                    <button className="pmivr-btn-app" onClick={() => handleAlreadyMigratedFlow()}>
                        Already Migrated
                    </button>
                </>} />
            <div className="row  pt-3" id="client-flow-existing">
                <div className="col-md-6 mt-2" >
                    {versionStatusInfo.latestDraft ?
                        <div className="card p-3">
                            {versionStatusInfo.latestDraft ?
                                <div className="form-group row mb-2">
                                    <div className="col-sm-6 pt-2">
                                        <label className="pb-3 pmivr-title">Latest Draft</label>
                                    </div>
                                    {/* allow only admin to upload flow xml */}
                                    {UserService.hasPermission() &&
                                        <div className="col-sm-6 text-end mt-1">
                                            <PmivrTooltip message={MESSAGES.UPLOAD_XML_FILE}>
                                                <button className="pmivr-btn-secondary" onClick={() => setUiState({ ...uiState, showDialog: true })}>
                                                    Upload
                                                </button>
                                            </PmivrTooltip>
                                        </div>
                                    }
                                    <div className="col-md-12 mt-1">
                                        {displayFlow(versionStatusInfo.draft, 'draft', true)}
                                    </div>
                                </div>
                                : <></>
                            }
                            <div className={versionStatusInfo.showDraftVersions ?
                                `version-list ${CSS_CLASSES.BLOCK_DISPLAY}` :
                                `version-list ${CSS_CLASSES.HIDE_DISPLAY}`}>
                                <div className="pmivr-sub-title small  pt-3 pb-2">
                                    Draft Versions
                                </div>
                                <div className={`list-group ${messageBox?.showMessage ? 'message-box-scroll' : 'pmivr-scroll'}`} onScroll={(e) => onScroll(e, FLOW_STATUS.DRAFT)}>
                                    {displayFlow(versionStatusInfo.draft, 'draft', false)}
                                </div>
                            </div>
                        </div> :
                        <div className="card p-3 no-flow-card">
                            <h6>No flow drafted yet</h6>
                        </div>
                    }
                </div>

                <div className="col-md-6 mt-2">
                    {latestPublished ?
                        <div className="card p-3">
                            {latestPublished ?
                                <div className="form-group row mb-2">
                                    <div className="col-sm-6 pt-2">
                                        <label className="pb-3 pmivr-title">Latest Published </label>
                                    </div>
                                    <div className="col-sm-6 text-end mb-3">
                                        <PmivrTooltip message={MESSAGES.FILTER_FLOW_VERSIONS}>
                                            <select className="pmivr-select select-sm" value={filterFlowFlag?.flag} onChange={(e) => onFlowFilterChange(e.target.value)}>
                                                {
                                                    FLAG_STATUS.map((filter) => {
                                                        return (
                                                            <option key={filter.value} value={filter.value}>
                                                                {filter.text}
                                                            </option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </PmivrTooltip>
                                    </div>
                                    <div className="col-md-12">
                                        {displayFlow(published, 'published', true)}
                                    </div>
                                </div>
                                : <></>
                            }
                            <div className={versionStatusInfo.showPublishedVersions ?
                                `version-list ${CSS_CLASSES.BLOCK_DISPLAY}` :
                                `version-list ${CSS_CLASSES.HIDE_DISPLAY}`}>
                                <div className="pmivr-sub-title small pt-3 pb-2">
                                    Published Versions
                                </div>
                                <div className={`list-group ${messageBox?.showMessage ? 'message-box-scroll' : 'pmivr-scroll'}`} onScroll={(e) => onScroll(e, FLOW_STATUS.PUBLISHED)}>
                                    {displayFlow(published, 'published', false)}
                                </div>
                            </div>
                        </div> :
                        <div className="card p-3 no-flow-card">
                            <PmivrTooltip message={MESSAGES.FILTER_FLOW_VERSIONS}>
                                <select className="pmivr-select select-sm w-50 ms-auto" value={filterFlowFlag?.flag} onChange={(e) => onFlowFilterChange(e.target.value)}>
                                    {
                                        FLAG_STATUS.map((filter) => {
                                            return (
                                                <option key={filter.value} value={filter.value}>
                                                    {filter.text}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </PmivrTooltip>
                            <h6>No flow published yet</h6>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

// Types of props passed in the component
DocVersionList.propTypes = {
    // {draft, published, latestDraft, latestPublished, showDraftVersions, showPublishedVersions}
    versionStatusInfo: PropTypes.object,
    // {selected flows dnid,flow name, flow type id }
    flowInfo: PropTypes.object,
    // Function that triggers the history dialog box
    showHistoryDialogBox: PropTypes.func,
    // Function to handle scroll and load more flow versions
    onScroll: PropTypes.func,
    //  Function that toggles republish dialog
    onToggleRepublishDialog: PropTypes.func,
    // Function that handles the flag update of flow versions
    onFlagStatusChange: PropTypes.func,
    // {flag} to filter selected flags
    filterFlowFlag: PropTypes.object,
    // Function that handles the flag filter of flow versions
    onFlowFilterChange: PropTypes.func
}

export default DocVersionList;