import { ENVIRONMENT } from '../constants/environment';

/**
 * Environment
 */
import development from './environment.dev';
import production from './environment.prod';
import uat from './environment.uat';
import sit from './environment.sit';

// determines the environment configuration based on various sources
export const envConfig =
  // sit environment in configured from CLI
  process.env.REACT_APP_ENV === ENVIRONMENT.SIT
    ? sit
    : // dev environment is configured from the browser's window configuration
    window.config.REACT_APP_ENV === ENVIRONMENT.DEVELOPMENT
    ? development
    : // production environment is configured from the browser's window configuration
    window.config.REACT_APP_ENV === ENVIRONMENT.PRODUCTION
    ? production
    //  default environment to uat 
    : uat;
