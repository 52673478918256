import { useContext } from 'react';

import { VoiceContext } from '../../../contexts/app-context';

import PmivrLoader from '../../common/loader/pmivr-loader.js';

import VoicePromptSelection from '../../common/voice-prompt-selection/VoicePromptSelection.js';

/**
 * Render the multi voice file dialog for option selection 
 * @returns {React.Component} React dialog to render for multi voice file
 */
const MultiVoiceFileOptionModal = () => {
  const { selectedLanguage, voiceFileDetails, selectedOption, updateSelectedOption, getAvailableOptions, showLoader,
    updatePromptsState, promptsList, element } = useContext(VoiceContext);

  return (
    <>
      <PmivrLoader showLoader={showLoader} />
      <div className="p-0">
        <div className="form-group px-3">
          <div className=" props-custom-input">
            <select
              required
              id={`file_option_${voiceFileDetails[selectedLanguage][selectedOption.index] && voiceFileDetails[selectedLanguage][selectedOption.index].option}`}
              className="pmivr-select"
              aria-label="Default select example"
              onChange={(event) => { updateSelectedOption(event.target.value); }}
            >
              <option selected>
                {voiceFileDetails[selectedLanguage][selectedOption.index] && voiceFileDetails[selectedLanguage][selectedOption.index].option}
              </option>
              {getAvailableOptions().map((value, index) => {
                return <option value={value}>{value}</option>;
              })}
            </select>
            <label>Select Option</label>
          </div>
        </div>
        {/* Multi voice file prompts with variables */}
        <VoicePromptSelection element={element} promptsList={promptsList} onChange={updatePromptsState}
          selectedLanguage={selectedLanguage} />
      </div>
    </>
  );
};

export default MultiVoiceFileOptionModal;