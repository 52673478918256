import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import PropTypes from 'prop-types';

import { envConfig } from '../../../environment';

import { VOICE_FILE_LANGUAGE_NAME } from '../../../constants/voice-file';

/**
 * Displaying content of system voice files
 * @returns {React.Component} Displayed content of system voice files
 */
const SystemVoiceFileContent = (props) => {
    const { languageInfo } = props;

    // Wrap tooltip
    const tooltip = (props, info) => <Tooltip {...props}>{info}</Tooltip>;

    return (
        <>
            <thead>
                <th width="50%" className="text-center">
                    Language
                </th>
                <th width="50%" className="text-center">
                    File Path
                </th>
            </thead>
            {
                Object.keys(languageInfo).map((lang) => {
                    return (<tr>
                        {/* Language */}
                        <td width="50%">{VOICE_FILE_LANGUAGE_NAME[lang?.toUpperCase()]}</td>

                        {/* File Path */}
                        <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }}
                            overlay={tooltip({}, `${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}` + languageInfo[lang]?.filePath)}>
                            <td width="50%" className="text-center">
                                {`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}` + languageInfo[lang]?.filePath}
                            </td>
                        </OverlayTrigger>
                    </tr>)
                })
            }
        </>
    );
};

SystemVoiceFileContent.propTypes = {
    // info of language which contains file path info
    languageInfo: PropTypes.shape({
        language: PropTypes.object
    })
};

export default SystemVoiceFileContent;
