import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";

import { MESSAGES } from "../../constants/messages.js";
import { APP_PAGES } from "../../constants/app-pages.js";
import { envConfig } from "../../environment/index.js";

import PmivrSnackBar from "../../components/common/dialog/pmivr-snackbar";

import StorageUtil from "../../util/storage.util.js";

import AuthService from "../../services/auth.service.js";
import UserService from "../../services/user.service.js";

/**
 * Allowing login to the user to access the app
 * @returns {React.Component} Renders login page and after login render to the home page
 */
const Login = () => {
  const navigate = useNavigate();
  // using the open method from the snackbar component
  const snackbarRef = useRef();
  // checkbox for remember me.
  const [rememberMe, setRememberMe] = useState(false);
  // uiState errorMessage and formDisabled
  const [uiState, setUiState] = useState({ errorMessage: "", formDisabled: false });

  // formik values for login form
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      login(values);
    },
  });

  useEffect(() => {
    const init = async () => {
      StorageUtil.clearStorage();
      // set lastUrl as null because if user is on logout then
      // lastUrl is removed as after login it should go to home page.
      const lastUrl = UserService.getLastVisitedUrl();;
      if (lastUrl) {
        StorageUtil.removeStorageValues([StorageUtil.STORAGE.LAST_URL]);
      }
      // value from localstorage is string
      const rememberMeInfo = UserService.getRememberMeInfo();
      if (rememberMeInfo?.rememberMe) {
        // set the email to be auto fill if remember me is selected.
        setRememberMe(rememberMeInfo?.rememberMe);
        formik.setFieldValue("email", rememberMeInfo?.email);
      }
    }
    init();
  }, []);

  /**
   * Saving remember me
   */
  const rememberMeHandler = () => {
    setRememberMe(!rememberMe);
  };

  /**
   * Checking the credentials and doing login for the client. If not authorized, display the error message
   * @param {Object} creds login data
   */
  const login = async (creds) => {
    const email = creds.email?.toLowerCase();
    const password = creds.password;

    // set remember me boolean value in local storage
    const rememberMeInfo = { rememberMe, email };
    UserService.setRememberMeInfo(rememberMeInfo);
    // disabling login button to avoid multiple clicks
    setUiState({ ...uiState, formDisabled: true });

    if (email && password) {
      try {
        // validating the emailId and password
        await AuthService.login(email, password);
        navigate(APP_PAGES.HOME);
      } catch (error) {
        if (error?.response?.status === 401) {
          setUiState({ ...uiState, errorMessage: MESSAGES.INVALID_CREDENTIALS, formDisabled: false });
        } else {
          setUiState({ ...uiState, errorMessage: MESSAGES.SOMETHING_WENT_WRONG, formDisabled: false });
        }
      }
    } else {
      setUiState({ ...uiState, errorMessage: MESSAGES.FILL_CREDENTIALS_PROPERLY, formDisabled: false });
    }
  };

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="full-screen-bg">
        <div className="pmivr-login">
          <div className="row border">
            <div className="col-md-6 login-left-pannel p-4">
              <div>
                <h2 className="mt-4 mb-3">Sign to Paymentus Smart Call </h2>
                <span className="mt-4">
                  IVR Flow Setups made <br /> super easy and fast.
                </span>
              </div>
              <div className="login-image">
                <img
                  src={envConfig.PUBLIC_URL + "/images/login-img.png"}
                  alt="login"
                />
              </div>
            </div>
            <div className="col-md-6 login-right-panel p-5">
              <div className="form-error">{uiState.errorMessage}</div>
              <form
                onSubmit={formik.handleSubmit}
                className={`${uiState.formDisabled ? "disabled" : ""}`}
              >
                <div className="form-group mb-4 mt-3">
                  <div className="pmivr-label">
                    <label className="pmivr-label">EMAIL ID </label>
                  </div>
                  <i className="bi bi-person-fill px-1"></i>
                  <input
                    type="email"
                    className="form-control pmivr-input login-input mb-3"
                    name="email"
                    placeholder="Enter your email id *"
                    autoFocus
                    required
                    //pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  ></input>
                </div>

                <div className="form-group mb-4">
                  <div className="pmivr-label">
                    <label className="pmivr-label">PASSWORD </label>
                  </div>
                  <i className="bi bi-lock-fill px-1"></i>
                  <input
                    type="password"
                    className="form-control pmivr-input login-input"
                    name="password"
                    placeholder="Enter your password *"
                    autoComplete="off"
                    required
                    minLength={4}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                  ></input>
                </div>

                <div className="form-check pmivr-check-radio mb-5">
                  <input
                    className="form-check-input mt-1 checkbox"
                    type="checkbox"
                    name="inlineRadioOptions"
                    id="checkbox"
                    value={rememberMe}
                    checked={rememberMe}
                    onChange={rememberMeHandler}
                  />
                  <p className="rememberme-check px-2">Remember Me</p>
                </div>

                <div className="form-group mb-3 mt-3">
                  <button className={`${uiState.formDisabled ? "disabled" : ""} pmivr-btn-app w-100 p-3`} type="submit">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;