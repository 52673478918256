import store from "../redux/store/store";

import { DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY } from "../config/config";

import { FLOW_STATUS } from "../constants/flow";
import { envConfig } from "../environment";

import FlowService from "./flow.service";

import appAxios from "../util/app.axios";

/**
 * Service class for client related operations
 */
class ClientService {

    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;

    /**
      * Configure the new client
      * @param {Object} flowInfo 
      * @param {{dnid: Array, questionsValues: Array}} billerInfo
      * @returns {Promise<any>} Response from configure client api
      */
    static async configureClient(flowInfo, billerInfo) {
        // save businessCode information in S3 then this will return versionId in response 
        const url = `${this.BASE_URL}/biller/configureBiller`;
        return appAxios.post(url, { flowInfo, billerInfo });
    }

    /**
     * Get the list of available dnid numbers info
     * @param {string} status dnid number status
     * @returns {Promise<Array>} dnid number's info
     */
    static async getDnidNumberPool(status) {
        const url = `${this.BASE_URL}/biller/dnidNumberPool?status=${status}`;
        return appAxios.get(url);
    }

    /**
     * Get the config from extNumber and dnid
     * @param {{dnid,lext,flowName,businessCode}} dnidConfigInfo
     * @returns {Promise<{dnid,businessCode,flowName,flowTypeId,updatedOn}>} dnid config with provided extNumber  
     */
    static async getDnidConfig(dnidConfigInfo) {
        const { dnid = "", lext = "", flowName = "", businessCode = "" } = dnidConfigInfo;
        const url = `${this.BASE_URL}/biller/dnidConfig?lext=${lext}&dnid=${dnid}&flowName=${flowName}&businessCode=${businessCode}`;
        return appAxios.get(url);
    }

    /**
     * Get all the dnid numbers from the DB as per filter object.
     *  Filter Object : {
     *  {Integer}  count Count of the dnid numbers to be fetched at a time, 
     *  {Integer} pageNo Page to be displayed, 
     *  {string} searchText text to be searched,
     *  {string} status Available / Occupied
     * }
     * @param {{ count, pageNo, searchText, status }} filter  Filter 
     * @returns {Promise<{data : Array<Numbers}>} response from the /numbers get api
     */
    static async getDnidNumbers(filter) {
        const { searchText = "", count = 10, pageNo = 1, status = "" } = filter;
        const url = `${this.BASE_URL}/biller/numbers?count=${count}&pageNo=${pageNo}&searchText=${searchText}&status=${status}`;
        return appAxios.get(url);
    }

    /**
     * Saves the new dnid for the application
     * @param {Object} dnid {phoneNumber, status, rangeStart, rangeEnd}
     * @returns {Promise<{msg: string}>} response from the /client/numbers post api
     */
    static saveDnidNumber(dnid) {
        const url = `${this.BASE_URL}/biller/numbers`;
        return appAxios.post(url, dnid);
    }

    /**
      * Get the user activity info for the given businessCode from DB
      * @param {Object} flowInfo {businessCode, flowName, flowTypeId, chatFlowId}  
      * @param {number} pageNo current page number
      * @param {number} count number of flow versions to display
      * @returns {Promise<Object>} version info for the given businessCode
      */
    static async getVersionInfo(flowInfo, pageNo, count) {
        const filters = { flowInfo, pageNo, count, status: null };

        // query to find both published and draft versions
        const versionInfo$ = [
            FlowService.getFlowInfo({ ...filters, status: FLOW_STATUS.DRAFT }),
            FlowService.getFlowInfo({ ...filters, status: FLOW_STATUS.PUBLISHED })
        ];

        const responseList = await Promise.all(versionInfo$);
        const [draft, published] = responseList;

        return { draft, published };
    }

    /**
    * Getting the recent searched businessCode 
    * @param {string} user Email of the user
    * @return {Promise<Array>} List of recently searched businessCode
    */
    static async getRecentSearches(user) {
        const url = `${this.BASE_URL}/biller/recentSearch?user=${user}`;
        return appAxios.get(url);
    }

    /**
     * Getting the businessCode info
     * @param {{businessCode,flowName}} dnidInfo 
     * @return {Promise<{flowName,flowTypeId,businessCode,dnid}>} Returns businessCode info object
     */
    static async getBusinessCodeInfo(dnidInfo) {
        const { businessCode, flowName = "", flowTypeId = "" } = dnidInfo;
        const url = `${this.BASE_URL}/biller/businessCodeInfo?businessCode=${businessCode}&flowName=${flowName}&flowTypeId=${flowTypeId}`;
        return appAxios.get(url);
    }

    /**
     * Getting the client verification
     * @param {string} businessCode businessCode to check if to get onBoarded or not
     * @return {Promise<{isVerifiedClient}>} Returns isverified client
     */
    static async getClientVerification(businessCode) {
        const url = `${this.BASE_URL}/biller/verifyClient?businessCode=${businessCode}`;
        return appAxios.get(url);
    }

    /**
    * Update the wizard config
    * @param {{businessCode,flowTypeId,flowName,questionValues}} flowInfo info of the flow to update the wizard config
    * @param {{filledVars, dnid , lext}} dnidInfo
    * @returns {Promise<Array>}
    */
    static async updateWizardConfig(flowInfo, dnidInfo) {
        const url = `${this.BASE_URL}/biller/wizardConfig`;
        return appAxios.put(url, { flowInfo, dnidInfo });
    }

    /**
     * Getting the updated draft and published versions from the database
     * @param {string} businessCode businessCode name
     * @param {{flowName, flowTypeId, chatFlowId, pageNo, count}} filters
     * @returns {Promise<Object>} FlowInfo 
     */
    static async getFlowInfo(businessCode, filters) {
        // getting the version info from DB, as per businessCode and limit number
        const flowInfo = { businessCode, flowName: filters?.flowName, flowTypeId: filters?.flowTypeId, chatFlowId: filters?.chatFlowId };
        const versionInfo = await ClientService.getVersionInfo(flowInfo, filters?.pageNo, filters?.count ? filters?.count : DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY);
        const basicFlowInfo = FlowService.getBasicFlowInfo();

        return {
            businessCode: businessCode, draft: versionInfo.draft, published: versionInfo.published,
            status: basicFlowInfo.flowType,
            docVersionId: basicFlowInfo.docVersionId
        };
    }

    /**
     * Get the setting of client set for wizard 
     * @param {{businessCode,flowName}} wizardData
     */
    static async getWizardConfig(wizardData) {
        const { businessCode, flowName } = wizardData;
        const url = `${this.BASE_URL}/biller/wizardConfig?businessCode=${businessCode}&flowName=${flowName}`;
        return appAxios.get(url);
    }

    /**
     * Save the updated wizardConfig
     * @param {string} businessCode Name of businessCode
     * @param {Array} wizardConfig List of wizard questions and it's seleted values
     */
    static async saveWizardConfig(businessCode, wizardConfig) {
        const url = `${this.BASE_URL}/biller/wizardConfig`;
        const wizardInfo = { businessCode, wizardConfig };
        return appAxios.post(url, wizardInfo);
    }

    /**
     * Get the list of all the flow types created for client
     * @param {string} businessCode Name of businessCode
     * @returns {Promise<Array>}
     */
    static async getClientCreatedFlowTypes(businessCode) {
        const url = `${this.BASE_URL}/biller/getBillerCreatedFlowTypes?businessCode=${businessCode}`;
        return appAxios.get(url);
    }

    /**
     * After updating the common base flow on S3 server, can create draft from the latest base flow.
     * If needed, then add custom changes to it.
     * @param {{businessCode, flowName, flowTypeId, dnid}} flowInfo info about the flow
     *  @returns {Promise<{data: {versionId}}>} response from api - creating draft flow for client from latest base flow
     */
    static async createDraftFromLatestBaseFlow(flowInfo) {
        const url = `${this.BASE_URL}/biller/baseFlow`;
        return appAxios.put(url, { flowInfo });
    }

    /**
     * Getting the appCode from redux.
     * @returns {{string}} App Code
     */
    static getAppCode() {
        return store.getState().client.appCode;
    }

    /**
     * Update the recent searched business code at backend
     * @param {string} businessCode 
     * @returns {Promise<Array>}
     */
    static updateRecentSearch(businessCode) {
        const url = `${this.BASE_URL}/biller/recentSearch`;
        return appAxios.put(url, { businessCode });
    }

    /**
     * Get all the clients from the DB as per filter object.
     *  Filter Object : {
     *  {Integer}  count Count of the clients to be fetched at a time, 
     *  {Integer} pageNo Page to be displayed, 
     *  {string} searchText text to be searched
     * }
     * @param {{ count, pageNo, searchText }} filter  Filter 
     * @returns {Promise<{data : Array<clients>}>} 
     */
    static async getClients(filter) {
        const { searchText = "", count = 10, pageNo = 1 } = filter;
        const url = `${this.BASE_URL}/biller/billers?count=${count}&pageNo=${pageNo}&searchText=${searchText}`;
        return appAxios.get(url);
    }

    /**
     * Get all the draft options from the DB as per filter object.
     *  Filter Object : {
     *  {Integer}  count Count of the clients to be fetched at a time, 
     *  {Integer} pageNo Page to be displayed, 
     *  {string} searchText text to be searched
     * }
     * @param {{ count, pageNo, searchText }} filter  Filter 
     * @returns {Promise<{data : Array<draftOption>}>} 
     */
    static async getDraftOptions(filter = {}) {
        const { searchText = "", count = 10, pageNo = 1 } = filter;
        const url = `${this.BASE_URL}/biller/draftOptions?count=${count}&pageNo=${pageNo}&searchText=${searchText}`;
        return appAxios.get(url);
    }

    /**
     * Saves the new draft option in system settings
     * @param {{label: string, accessToken: string, optionId: string, url: string}} draftOption 
     * @returns {Promise<{data : {draftOption}, message: string}>} 
     */
    static async saveDraftOption(draftOption) {
        const url = `${this.BASE_URL}/biller/draftOptions`;
        return appAxios.post(url, draftOption);
    }

    /**
     * Updates the draft option in Database
     * @param {{label: string, accessToken: string, optionId: string, url: string}} draftOption 
     * @returns {Promise<{message: string, data: Object}>} 
     */
    static async updateDraftOption(draftOption) {
        const url = `${this.BASE_URL}/biller/draftOptions`;
        return appAxios.put(url, { draftOption });
    }

    /**
     * Deletes draft option from database 
     * @param {{label: string, accessToken: string, optionId: string, url: string}} draftOption 
     * @returns {Promise<{message: string}>} 
     */
    static async deleteDraftOption(draftOption) {
        const url = `${this.BASE_URL}/biller/draftOptions`;
        return appAxios.delete(url, { data: { draftOption } });
    }

    /**
     * Drafts to another environment, if flow is present and the client is on-boarded.
     * @param {draftOption: {label: string, optionId: string, url: string}, flowInfo: {flowName, flowTypeId etc.}} draftOption 
     * @returns {Promise<{message: string, data: Object}>} 
     */
    static async draftToAnotherEnvBuilder(draftOption, flowInfo) {
        const url = `${this.BASE_URL}/flow/draft/env`;
        return appAxios.post(url, { draftOption, status: flowInfo.status, versionId: flowInfo.versionId });
    }
}

export default ClientService;