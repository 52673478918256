import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";

import UserService from "../../services/user.service";

import SETTING_OPTIONS from "./setting-options";
import PmivrLoader from "../../components/common/loader/pmivr-loader";
import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";

/**
 * Displays the different settings options
 * @returns {React.Component} Html element to render
 */
const Settings = () => {

    const navigate = useNavigate();
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // current user logged in 
    const [currentUser, setCurrentUser] = useState();
    // props that show hide loader from UI
    const [uiState, setUiState] = useState({ loader: false });

    useEffect(() => {
        const init = () => {
            try {
                setUiState({ ...uiState, loader: true });
                setCurrentUser(UserService.getCurrentUser());
                setUiState({ ...uiState, loader: false });
            } catch (err) {
                setUiState({ ...uiState, loader: false });
                // opening the snackbar
                if (snackbarRef?.current) {
                    snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
                }
            }
        }
        init();
    }, []);

    return (
        <>
            <PmivrLoader showLoader={uiState.loader} />
            <PmivrSnackbar ref={snackbarRef} />
            {/* Navigation Bar */}
            <div className="row pt-1">
                <div className="row border-bottom  pb-3 pt-3 ">
                    <div className="col-lg-6">
                        <div className="px-3 pmivr-breadcrumb-list">
                            <Link to={APP_PAGES.HOME}>Home</Link> / Settings
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3 pmivr-settings">
                {/* Header specifying the current user role */}
                <h4 className="pmivr-sub-title large mt-5">{currentUser?.roleId}</h4>
                <div className="row setting-tabs scroll">
                    {
                        [...SETTING_OPTIONS].map((item, index) => {
                            return (
                                <>
                                    {
                                        UserService.hasPermission() && (
                                            <div className="col-md-3" key={index} title={item?.description} onClick={() => navigate(item?.linkTo)}>
                                                <div className="pmivr-card card card-effect text-center pmivr-text-link mb-3 p-3 mt-2 fs-1" >
                                                    {/* Icon Heading */}
                                                    <i className={item?.iconClass}></i>
                                                    {/* Option Heading */}
                                                    <span><h3 className="pmivr-title large pmivr-ellipsis pt-3 pb-2">{item?.text}</h3></span>
                                                    {/* Option Description */}
                                                    <span><h6 className="pmivr-sub-title pmivr-ellipsis">{item?.description}</h6></span>

                                                </div>
                                            </div>
                                        )
                                    }
                                </>
                            )
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default Settings;