import PropTypes from 'prop-types';

import { TOOLTIP } from '../../../constants/messages';
import { envConfig } from '../../../environment';

import PmivrLabel from '../../../components/common/label/pmivr-label';
import PmivrOverlayTrigger from '../../../components/common/overlay-trigger/pmivr-overlay-trigger';
import VoiceFilesSelect from '../../../components/common/voice-files-select/voice-files-select';

/**
 * The component for editing system voice files being configured and used internally in AGI call
 * @returns {React.Component} Editing of system voice files to render on UI
 */
const EditSystemVoiceFiles = (props) => {
  const { supportedLanguages, selectedLanguage, updateVoiceFileStateInfo, updateVoiceFilePathState, voiceFilePath, voiceFileInfo } = props;
  return (
    <>
      <div className="pmivr-card card-secondary" style={{ height: '258px' }}>
        <div>
          <ul className="nav nav-tabs text-center" role="tablist">
            {supportedLanguages.map((language) => {
              return (
                <li className="nav-item  px-2" role="presentation">
                  <button className={selectedLanguage === language ? 'nav-link active ' : 'nav-link'}
                    data-bs-target="#language-options" type="button" role="tab" title={language}
                    aria-controls="language-options" onClick={(event) => {
                      updateVoiceFileStateInfo(language, voiceFileInfo);
                    }} >
                    {language}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="mt-3" id="language-options">
          <div className="tab-content " id="pills-tabContent">
            <div className={'tab-pane fade show active pt-3'} id="file-path" role="tabpanel" aria-labelledby="ttsFile-tab">
              <div className="form-group custom-input mb-3">
                <label className="pmivr-label">Base file path</label>
                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                  <input disabled type="text" className="form-control pmivr-input pmivr-disabled"
                    size="50" value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`} />
                </PmivrOverlayTrigger>
              </div>
              <div className="form-group custom-input">
                <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} />
                <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                  <VoiceFilesSelect onSelect={(value) => updateVoiceFilePathState(value, voiceFileInfo)}
                    selectedItem={voiceFilePath} selectedLanguage={selectedLanguage} />
                </PmivrOverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EditSystemVoiceFiles.propTypes = {
  // array of supported languages
  supportedLanguages: PropTypes.array,
  // selected language
  selectedLanguage: PropTypes.string,
  // updates the voice file path when language tabs is changed
  updateVoiceFileStateInfo: PropTypes.func,
  // updates the voice file path for select menu
  updateVoiceFilePathState: PropTypes.func,
  // current selected voice file path
  voiceFilePath: PropTypes.string,
  // current selected voice files object
  voiceFileInfo: PropTypes.shape({
    fileName: PropTypes.string,
    language: PropTypes.object
  })
};

export default EditSystemVoiceFiles;
