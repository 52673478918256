import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import PropTypes from "prop-types";

import { ATTRIBUTES } from "../../../constants/attributes";
import { EVENT_TYPE } from "../../../constants/events";
import { TASK_TYPE } from "../../../constants/task-types.js";
import { VOICE_FILE_TYPE, VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { CSS_CLASSES } from "../../../constants/css-classes.js";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";

import PmivrSnackbar from "../../common/dialog/pmivr-snackbar";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import AudioPlayer from "../../common/audio-player/audio-player.js";

import AudioUtil from "../../../util/audio.util";

import VoiceFile from "../../../models/voice-file";

import ElementService from "../../../services/element.service";
import FlowService from "../../../services/flow.service.js";
import DiagramEventHandlerService from "../../../services/diagram-event-handler.service.js";

import { decode as decodeWav } from 'wav-decoder';
import AudioService from "../../../services/audio.service.js";

/**
 * The upload voice file option view in the diagram
 */
const UploadVoiceFileOption = forwardRef((props, ref) => {

  // file should be MONO
  const CHANNELS = { MONO: 1 };
  // using the open method from the snackbar component
  const snackbarRef = useRef();
  // set the file uploade
  const [file, setFile] = useState(null);
  // set the file path
  const [filePath, setFilePath] = useState();
  // set file size and display
  const [fileSize, setFileSize] = useState();
  const [isUploadedOnGit, setIsUploadedOnGit] = useState(false);
  const [isPromptUserInput, setIsPromptUserInput] = useState(false);
  // info message displayed if file succesfully uploaded
  const [infoMessage, setInfoMessage] = useState("");
  // error message in case of any faliure
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  // check if file updated or not
  const [isFileUpdated, setIsFileUpdated] = useState(false);
  const [uiState, setUiState] = useState({ uploadBtnDisable: true });

  const { rightPanelEventHandler, isMultiplePrompts, voiceFileInfo, element,
    selectedLanguage, autoSave, invaildVoiceFile, resetStates, showUploadBtn, onChange } = props;

  useEffect(() => {
    const init = () => {
      setInfoMessage("");
      setIsErrorMessage(false);
      setIsPromptUserInput(ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) === TASK_TYPE.promptUserInput);
      const voiceFileDetails = voiceFileInfo[selectedLanguage];
      setFileSize(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails.fileSize : "" : "");
      setFilePath(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails.filePath : "" : "");
      setIsUploadedOnGit(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails?.isUploadedOnGit : false : false);
    }
    init();
  }, [voiceFileInfo]);

  useEffect(() => {
    const doUpdate = () => {
      if (isFileUpdated) {
        if (autoSave) {
          saveAudio();
        }
      }
      // clearing the info message and error message from state after a pause of 5 seconds
      setTimeout(() => {
        setInfoMessage('');
        setIsErrorMessage(false);
      }, 5000);
    }
    doUpdate();
  }, [isFileUpdated])

  useImperativeHandle(ref, () => ({
    resetUploadOption() {
      resetUploadOption();
    },
    saveAudio() {
      saveAudio();
    }
  }));

  // reset voice file related states 
  const resetUploadOption = () => {
    setFile(file);
    setIsPromptUserInput(ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) === TASK_TYPE.promptUserInput);
    const voiceFileDetails = voiceFileInfo[selectedLanguage];
    setFileSize(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails.fileSize : "" : "");
    setFilePath(voiceFileDetails ? voiceFileDetails.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? voiceFileDetails.filePath : "" : "");
    snackbarRef.current.close();
    setInfoMessage("");
    setIsErrorMessage(false);
  }

  /**
   * Update the uplod voice file changes
   * @param {Object} event event of type object
   */
  const voiceFileUploadChangeHandler = async (event) => {
    try {
      // Check if the file extension is .wav
      if (event?.target?.files?.length && event?.target?.files[0].name.endsWith(".wav")) {
        const file = event.target.files[0];
        // read the file that is uploaded
        const reader = new FileReader();
        // runs when file reading is complete
        reader.onload = async (event) => {
          // get the buffer from file
          const arrayBuffer = event.target.result;
          // Decode the WAV audio data from the buffer
          const audioData = await decodeWav(arrayBuffer);

          // Check if wav file is valid or not
          if (!isWavFileValid(audioData)) {
            setInfoMessage(`Unsupported File: ${file.name}`);
            setIsErrorMessage(true);
            setFile(null);
            setIsFileUpdated(false);
          } else {
            setFile(file);
            setFilePath(file.name);
            setInfoMessage(`File Added Successfully`);
            setFileSize(Math.floor((file.size) / 1000));
            setIsErrorMessage(false);
            setIsFileUpdated(true);
          }
        };
        // Read the file as an array buffer
        reader.readAsArrayBuffer(file);
      } else {
        snackbarRef.current.open(MESSAGES.VOICE_FILE_UPLOAD_CORRECT_FORMAT);
      }
    } catch (err) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.VOICE_FILE_UPLOAD);
      }
    }
  };

  /**
   * Check if the wav file is valid or not
   * @param {Object} audioData data of the audio file
   * @returns {boolean} valid file or not
   */
  const isWavFileValid = (audioData) => {
    if (audioData.sampleRate !== 8000 || audioData.numberOfChannels !== CHANNELS.MONO) {
      return false;
    }
    return true;
  }

  //saved the voice file onto the server and the flow
  const saveAudio = () => {
    let eventInfoData;
    if (isFileUpdated) {
      // update voice file infomation
      let info = new VoiceFile();
      if (!isMultiplePrompts) {
        const temVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, voiceFileInfo);
        info = { ...info, voiceFileType: VOICE_FILE_UPLOAD_TYPE.UPLOAD, filePath: filePath, fileSize: fileSize };
        temVoiceFileInfo[selectedLanguage] = info;
        if (isPromptUserInput && invaildVoiceFile) {
          ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(temVoiceFileInfo));
        } else {
          ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(temVoiceFileInfo));
        }
      }

      const eventInfo = {
        element: element,
        data: {
          file: null,
          language: selectedLanguage,
          fileType: VOICE_FILE_TYPE.VALID,
          eventType: EVENT_TYPE.VOICE_FILE_UPLOAD
        }
      };
      // if tts
      if (file) {
        eventInfo.data.file = file;
        eventInfo.element.file = file;
        // add fileType if task is PromptUserInput
        if (isPromptUserInput && invaildVoiceFile) {
          eventInfo.data.fileType = VOICE_FILE_TYPE.INVALID;
        }
      }

      if ((ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) !== TASK_TYPE.keyValueUserOption)
        && !isMultiplePrompts) {
        eventInfoData = rightPanelEventHandler(eventInfo);
      }
      // call the resetStates function of parent call to reset the states of other component
      if (resetStates) {
        resetStates();
      }
      return eventInfoData;
    }
  }

  /**
   * Uploads the audio file
   */
  const uploadFile = async () => {
    try {
      if (!isMultiplePrompts) {
        let metaInfo;
        setUiState({ ...uiState, uploadBtnDisable: true });
        const eventInfoData = saveAudio();
        const basicFlowInfo = FlowService.getBasicFlowInfo();
        const voiceTaskType = ElementService.getAttribute(eventInfoData?.element, ATTRIBUTES.VOICE_FILE_TASK_TYPE);

        if ([TASK_TYPE.playVoiceFile, TASK_TYPE.promptUserInput, TASK_TYPE.promptUserOption].includes(voiceTaskType)) {
          metaInfo = await DiagramEventHandlerService.executeRightPanelEvents(eventInfoData, basicFlowInfo?.flowName);
        }
        // get the attribute to update or modify
        const attribute = invaildVoiceFile ? ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE : ATTRIBUTES.VOICE_FILE_INFO;
        // get the info of the voice file attribute
        let voiceFileInfo = ElementService.getAttribute(eventInfoData?.element, attribute);
        voiceFileInfo = JSON.parse(voiceFileInfo);
        //update the necessary fields or add them
        voiceFileInfo[selectedLanguage]["filePath"] = metaInfo?.location;
        voiceFileInfo[selectedLanguage]["isUploadedOnGit"] = metaInfo?.isUploadedOnGit;
        //update the element
        ElementService.updateElementAttr(eventInfoData?.element, attribute, JSON.stringify(voiceFileInfo));
        snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
        setUiState({ ...uiState, uploadBtnDisable: true });
        // to update the latest state of filePath at component level
        if (resetStates) {
          resetStates();
        }
      } else {
        // basic flow for for uploading file , like busniessCode
        const basicFlowInfo = FlowService.getBasicFlowInfo();
        // basic flowInfo for tts
        const flowInfo = {
          businessCode: basicFlowInfo?.businessCode, elementId: element.id,
          language: selectedLanguage, flowName: basicFlowInfo?.flowName
        };
        // necessary fields in case of upload
        flowInfo.file = file;
        const response = await AudioService.uploadVoiceFile(flowInfo);
        if (response) {
          onChange({
            filePath: response?.data?.metaInfo?.location, isUploadedOnGit: response?.data?.metaInfo?.isUploadedOnGit,
            fileSize
          });
          snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
          setUiState({ ...uiState, uploadBtnDisable: true });
        }
      }
    } catch (err) {
      setUiState({ ...uiState, uploadBtnDisable: false });
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
      }
    }
  }

  return (
    <>
      <PmivrSnackbar ref={snackbarRef} />
      <div className="d-flex justify-content-between">
        <div className="pmivr-title pt-3">Upload Audio File</div>
        <AudioPlayer filePath={filePath}
          cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE} element={element}
          action={VOICE_FILE_UPLOAD_TYPE.UPLOAD} selectedLanguage={selectedLanguage} isMultiplePrompts={isMultiplePrompts}
          isUploadedOnGit={isMultiplePrompts ? isUploadedOnGit : false}></AudioPlayer>
      </div>
      <div className="pmivr-drop-audio-file">
        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.UPLOAD_VOICE_FILE}>
          <div className="choose-file">
            Drop audio file here or{" "}
            <a href="/#">Browse <i className="bi bi-link-45deg"></i></a>
            <input
              id="voiceFileUpload"
              className="form-control form-control-lg"
              accept=".wav"
              onChange={(e) => { voiceFileUploadChangeHandler(e); setUiState({ ...uiState, uploadBtnDisable: false }); }}
              placeholder="Drop audio file here or Browse"
              type="file"
            />
          </div>
        </PmivrOverlayTrigger>
      </div>
      <div className="pmivr-upload-file">
        <div className={isErrorMessage ? "failed-upload" : "success-upload"}>{infoMessage}</div>
        <div className="file-name">File Path: {filePath}</div>
        <div className="file-size">File Size: {fileSize} {fileSize !== "" ? "KB" : ""}</div>
      </div>
      {showUploadBtn &&
        <button
          style={{ width: "100%" }}
          className="pmivr-btn-app mt-3"
          disabled={uiState.uploadBtnDisable || !file}
          onClick={() => uploadFile()}>Upload File</button>
      }
    </>
  );
});

UploadVoiceFileOption.propTypes = {
  // right bar on diagram page panel handler
  rightPanelEventHandler: PropTypes.func,
  isMultiplePrompts: PropTypes.bool,
  // voice file info object
  voiceFileInfo: PropTypes.object,
  element: PropTypes.object,
  // nam eof the selected language
  selectedLanguage: PropTypes.string,
  // boolean value to check for auto save
  autoSave: PropTypes.bool,
  // voice file invalid or not
  invaildVoiceFile: PropTypes.bool,
  resetStates: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  // onChange function to update tts values in prompts list
  onChange: PropTypes.func
}

export default UploadVoiceFileOption;