import PropTypes from "prop-types";

import { TOOLTIP } from "../../../constants/messages";
import { ATTRIBUTES } from "../../../constants/attributes";
import { CSS_CLASSES } from "../../../constants/css-classes";
import { useEffect, useState } from "react";

import PmivrLabel from "../../common/label/pmivr-label";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import VoiceFilesSelect from "../../common/voice-files-select/voice-files-select";

import ElementService from "../../../services/element.service";

/**
 * Option and Voice File Mapping View in case of "List Element As User Option"
 * MAPPING_KEY: mapping through index of list or mapping through some field of element of list
 * MappingField: name of field through which voice file is mapped to the user option
 *  
 * @param {Object} props properties from parent component
 * @returns {React.Component} Html element to render 
 */
const DynamicOptionVoiceFileMapping = (props) => {

    const {
        element, optionAndVoiceFile, keyVoiceFileOptionMapping, handleInputChange,
        optionIdInputRefs, handleFocus, supportedLanguages, handleDelete, handleAdd
    } = props;

    // mapping keys, for mapping voice files with options
    const MAPPING_KEY = {
        FIELD: "field",
        INDEX: "index"
    };

    /**
     * in customize voice files, user will map the voice file to user option using this 
     * mapping key (by index, by mapping field)
     */
    const [mappingBy, setMappingBy] = useState(MAPPING_KEY.INDEX);

    useEffect(() => {
        let mappedBy = ElementService.getAttribute(element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_BY);
        if (!mappedBy) {
            // if mapping_by is not set, then update as "index" for the first time
            ElementService.updateElementAttr(element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_BY, MAPPING_KEY.INDEX);
            mappedBy = MAPPING_KEY.INDEX;
        }
        // updating the mappingBy in state
        setMappingBy(mappedBy);
    }, [])

    /**
     * Handling the event of selecting the mapping key type radio button.
     * Updating the state and the element
     * @param {string} value value on selecting the type of mapping key 
     */
    const handleMappingBySelection = (value) => {
        setMappingBy(value);
        ElementService.updateElementAttr(
            element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_BY, value
        );
    }

    return (
        <>
            <div className="form-group custom-input">
                <div className="row mt-2 mb-3">
                    <div className="col-md-5 m-3 mt-2 mb-3 form-check pmivr-check-radio">
                        <input className="form-check-input" id="mappingUsingIndex"
                            type="radio" value="mappingUsingIndex" name="mappingUsingIndex"
                            checked={mappingBy === MAPPING_KEY.INDEX}
                            onChange={() => handleMappingBySelection(MAPPING_KEY.INDEX)} />
                        <label className="form-check-label" htmlFor="mappingUsingIndex">
                            Mapping by index
                        </label>
                    </div>
                    <div className="col-md-5 m-2 mt-2 mb-3 form-check pmivr-check-radio">
                        <input className="form-check-input" id="mappingUsingField"
                            type="radio" value="mappingUsingField" name="mappingUsingField"
                            checked={mappingBy === MAPPING_KEY.FIELD}
                            onChange={() => handleMappingBySelection(MAPPING_KEY.FIELD)} />
                        <label className="form-check-label" htmlFor="mappingUsingField">
                            Mapping by field
                        </label>
                    </div>
                </div>
                <div className={
                    (mappingBy === MAPPING_KEY.INDEX)
                        ? CSS_CLASSES.HIDE_DISPLAY
                        : `${CSS_CLASSES.BLOCK_DISPLAY} mt-2 mb-1`
                }>
                    <PmivrLabel label="Mapping Field" tooltip={TOOLTIP.INFO.DYNAMIC_OPTION_MAPPING_FIELD} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.DYNAMIC_OPTION_MAPPING_FIELD}>
                        <input id="mappingField" name="mappingField" className="form-control pmivr-input"
                            value={
                                ElementService.getAttribute(
                                    element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_FIELD, ""
                                )
                            }
                            onChange={(e) => {
                                ElementService.updateElementAttr(
                                    element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_FIELD, e.target.value
                                );
                            }} />
                    </PmivrOverlayTrigger>
                </div>
                {(optionAndVoiceFile[keyVoiceFileOptionMapping] || [])?.map((item, index) => {
                    return (
                        <div className="row mb-2" key={index}>
                            <div className="col-sm-3 mt-3">
                                <div className="props-custom-input">
                                    <input className="form-control pmivr-input" id={`file_value_${index}`} value={item.mappingFieldValue}
                                        type="text" onChange={(e) => handleInputChange(index, 'mappingFieldValue', e.target.value)}
                                        ref={(el) => (optionIdInputRefs.current[index] = { ...optionIdInputRefs.current[index], name: el })}
                                        onFocus={() => handleFocus(index)} />
                                    <label>{mappingBy === MAPPING_KEY.INDEX ? 'Index Value' : 'Mapped Value'}</label>
                                </div>
                            </div>
                            {
                                [...supportedLanguages].map((language) => {
                                    return (
                                        <>
                                            <div className="col-sm-4 mt-1">
                                                <div className="form-group">
                                                    <PmivrLabel label={`Voice File (${language})`} />
                                                    <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                                                        <VoiceFilesSelect onSelect={(selectedPath) => handleInputChange(index, `voiceFile_${language}`, selectedPath)}
                                                            selectedLanguage={language} selectedItem={item[`voiceFile_${language}`] || ''} />
                                                    </PmivrOverlayTrigger>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                            }

                            <div className="col-sm-1 text-end mt-3">
                                <i className="bi bi-x-lg pmivr-btn-remove" id={`file_option_remove_${index}`}
                                    onClick={() => handleDelete(index)}></i>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="text-center mt-3">
                <button className="pmivr-add-option" onClick={handleAdd}>
                    Add Voice File Mapping<i className="ms-1">+</i>
                </button>
            </div>
        </>
    );
}

DynamicOptionVoiceFileMapping.propTypes = {
    keyVoiceFileOptionMapping: PropTypes.string,
    optionIdInputRefs: PropTypes.array,
    supportedLanguages: PropTypes.array,
    element: PropTypes.object,
    optionAndVoiceFile: PropTypes.object,
    handleDelete: PropTypes.func,
    handleAdd: PropTypes.func,
    handleInputChange: PropTypes.func,
    handleFocus: PropTypes.func,
};

export default DynamicOptionVoiceFileMapping;