import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { PAGINATION_COUNT, REGEX } from "../../config/config";
import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";
import { STATUS_API_KEY } from "../../constants/api-config";

import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import ApiKeyDisplayModal from "./components/api-modal";
import RegenerateKey from "./components/regenerate-key";
import ApiKeysConfirmDialog from "./components/api-keys-confirm-dialog";

import AppUtil from "../../util/app.util";

import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";

/**
* List of API keys
* Also provides option to regenerate keys and delete them
* @returns {React.Component} Html element to render.
*/
const ApiKeys = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();

    // list of api keys
    const [apiKeys, setApiKeys] = useState([]);
    // cache api keys list info
    const [apiKeyInfo, setApiKeyInfo] = useState({ keys: [], dataCount: 0, pageNo: 0 });
    // Regenerate key info for the modal 
    const [regenerateKeyInfo, setRegenerateKeyInfo] = useState({ email: "", appCode: "", apiKey: "" });
    // for closing and opening modals
    const [modalState, setModalState] = useState({
        showRegenerateModal: false, // Whether to show or not the regenerate modal
        showDialog: false, // show the option menu modal based on boolean value
    });
    // For showing confirm dialog box
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    // parameters for passing to the modals
    const [modalParams, setModalParams] = useState({ title: '', appCode: '', email: "", action: '' });
    // filter object for filtering the keys
    const [filterKeys, setFilterKeys] = useState({ text: '', status: '' });
    // props for the pagination of the keys list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of api keys
        currentPage: 0, // current page displayed in pagination of api keys
        totalResults: 0,  // total number of api keys
        count: PAGINATION_COUNT  // count for number of api keys to be displayed at a time
    });
    // filter options for api keys status
    const KEY_STATUS = [
        { text: "ALL", value: "" },
        { text: STATUS_API_KEY.ACTIVE, value: STATUS_API_KEY.ACTIVE },
        { text: STATUS_API_KEY.EXPIRED, value: STATUS_API_KEY.EXPIRED },
    ];
    // title for modal
    const TITLE = {
        ADD_KEY: "Create",
        REGENERATE_KEY: "New Key Regenerated",
        CONFIRM_DELETE: "Confirm Delete",
        CONFIRM_REGENERATE: "Confirm Regenerate"
    };
    // Actions for the dialog box when confirm is clicked 
    const ACTION = {
        REGENERATE_KEY: 'regenerate',
        DELETE_KEY: 'delete'
    }

    useEffect(() => {
        onInit();
    }, [filterKeys.status]);

    const onInit = async () => {
        await loadApiKeys();
    }

    /**
     * Getting the api keys from db as per searchText, count, pageNo
     * @param {Number} pageNo
     */
    const loadApiKeys = async (pageNo = 1) => {
        try {
            const filter = { searchText: filterKeys.text, status: filterKeys.status, count: pagination.count, pageNo };
            const response = await AuthService.getApiKeys(filter);
            setApiKeys(response?.data);
            // updating the cache when search request is not there (keeping the original list)
            if (!filter.searchText && !filter.status) {
                setApiKeyInfo({ keys: response?.data, dataCount: response?.dataCount, pageNo });
            }
            setPaginationProps(response?.dataCount, pageNo);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of api keys
     * @param {Number} pageNo page number of the api keys list
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * show modal to add new api key
     */
    const showApiKeyModal = () => {
        setModalParams(prevParams => ({
            ...prevParams,
            title: TITLE.ADD_KEY,
        }));
        setModalState({ ...modalState, showDialog: true });
    }

    /**
     * Resetting the list of api keys from cache.
     * If not in state, the reload from DB
     */
    const resetData = async () => {
        // reset filter option
        setFilterKeys({ text: "", status: "" });
        if (apiKeyInfo.keys?.length) {
            // reading from state
            setApiKeys(apiKeyInfo.keys);
            setPaginationProps(apiKeyInfo.dataCount, apiKeyInfo.pageNo);
        } else {
            // reading from DB
            await loadApiKeys();
        }
    }

    /**
     * Populating values on changing the value of text field
     * @param {string} value - The value of the filter text
     * @param {string} status - The api-key status
     */
    const setFilterText = (value = "", status = "") => {
        setFilterKeys({ text: value?.toLowerCase(), status: status })
    }

    /**
     * close the modal by setting flag to false
     * @param {string} apiKey 
     */
    const handleCloseAction = async (apiKey) => {
        updateKeysState(apiKey);
        setModalState({ ...modalState, showDialog: false });
        loadApiKeys()
    }

    /**
     * Closing the regenerate api key modal 
     */
    const closeRegenerateModal = async () => {
        setModalState({ ...modalState, showRegenerateModal: false })
    }

    /**
     * Closing the confirm dialog box 
     */
    const closeConfirmDialog = async () => {
        setShowConfirmDialog(false);
    }

    /**
     * Updating the keys state with added appCode
     * @param {Object} apiKey apiKey added
     */
    const updateKeysState = async (apiKey) => {
        if (apiKey) {
            // getting the keys cached in state
            const cachedKeys = apiKeyInfo.keys;
            // adding the new key to the top of the list
            const updatedKeys = [apiKey, ...cachedKeys];
            if (apiKeyInfo.dataCount >= PAGINATION_COUNT) {
                // removing the last item from the list, as new item is being added above
                updatedKeys.pop();
            }
            // updating the keys state
            setApiKeys(updatedKeys);
            setApiKeyInfo({ keys: updatedKeys, dataCount: (apiKeyInfo.dataCount + 1), pageNo: apiKeyInfo.pageNo });
            setPaginationProps((apiKeyInfo.dataCount + 1), apiKeyInfo.pageNo);
        }
    }

    /**
     * Deletes the API key from database
     * @param {String} email
     */
    const deleteApiKey = async (email, appCode) => {
        try {
            const response = await AuthService.deleteApiKey(email, appCode);
            if (response) {
                const updatedApiKeys = apiKeys.filter(apiKey => apiKey.email !== email);
                setApiKeys(updatedApiKeys);
                // opening the snackbar
                snackbarRef.current.open(MESSAGES.API_KEY_DELETED_SUCCESS);
                setPagination(prevPaginationObj => ({
                    ...prevPaginationObj,
                    totalResults: pagination.totalResults - 1
                }));
            }
        } catch (error) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERR.UNABLE_TO_DELETE_API_KEY);
            }
        } finally {
            setTimeout(() => {
                snackbarRef.current.close();
            }, 1000)
        }
    }

    /**
     * Opens the confirm action dialog box
     * @param {String} email
     * @param {String} action
     */
    const openConfirmationDialog = async (email, appCode, action) => {
        setModalParams(prevParams => ({
            ...prevParams,
            action: action, email: email, appCode: appCode
        }));
        switch (action) {
            case ACTION.REGENERATE_KEY:
                setModalParams(prevParams => ({
                    ...prevParams,
                    title: TITLE.CONFIRM_REGENERATE
                }));
                break;
            case ACTION.DELETE_KEY:
                setModalParams(prevParams => ({
                    ...prevParams,
                    title: TITLE.CONFIRM_DELETE
                }));
                break;
            default:
                setModalParams(prevParams => ({
                    ...prevParams,
                    title: ""
                }));
                break;
        }
        setShowConfirmDialog(true);
    }

    /**
     * Regenerates api key and sends request at backend for update
     * @param {string} email
     * @param {string} appCode
     * @param {number} apiKeyValidityDays
     */
    const regenerateKey = async (email, appCode, apiKeyValidityDays) => {
        try {
            // api key information object
            const apiKeyConfigs = { email, appCode, apiKeyValidityDays };
            // Updating at backend
            const response = await AuthService.updateApiKey(apiKeyConfigs);
            if (response) {
                // api key generated at backend
                const apiKey = response?.data.apiKey;
                setRegenerateKeyInfo({ email, apiKey, appCode });
                setModalParams(prevParams => ({
                    ...prevParams,
                    title: TITLE.REGENERATE_KEY,
                }));
                setModalState({ ...modalState, showRegenerateModal: true });
            }
        } catch (err) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERR.UNABLE_TO_REGENERATE_API_KEY);
            }
        }
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <PmivrDialog showDialog={modalState.showDialog} closeDialog={handleCloseAction}
                title={modalParams.title}
                message={<ApiKeyDisplayModal closeAction={handleCloseAction} />}
                footer={<></>} />
            <PmivrDialog showDialog={modalState.showRegenerateModal} closeDialog={closeRegenerateModal}
                title={modalParams.title}
                message={<RegenerateKey closeAction={closeRegenerateModal} apiKeyInfo={regenerateKeyInfo} />}
                footer={<></>} />
            <PmivrDialog showDialog={showConfirmDialog} closeDialog={closeConfirmDialog}
                title={modalParams.title}
                message={<ApiKeysConfirmDialog closeAction={closeConfirmDialog} email={modalParams.email} appCode={modalParams.appCode}
                    regenerateKey={regenerateKey} deleteApiKey={deleteApiKey} action={modalParams.action} />}
                footer={<></>} />
            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom  pb-3 pt-3 ">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / <Link to={APP_PAGES.SETTINGS}>Settings</Link> / Api Keys
                            </div>
                        </div>
                    </div>
                </div>
                {/* header for search and actions */}
                <div className="row p-2 pt-3">
                    {/* left side actions */}
                    <div className="col-lg-8">
                        <div className="row ps-1 pmivr-number-input">
                            <div className="col-sm-6 pmivr-relative remove-arrows">
                                <input type="text" className="form-control pmivr-input pe-5"
                                    value={filterKeys.text} placeholder="Search email, appCode, apiKeyName"
                                    onChange={(e) => {
                                        const searchValue = e.target.value;
                                        if (searchValue && AppUtil.checkRegex(REGEX.API_KEY_SEARCH, searchValue)) {
                                            setFilterText(searchValue, filterKeys.status);
                                        } else if (!searchValue) {
                                            resetData();
                                        }
                                    }
                                    }
                                    // on pressing enter search the key
                                    onKeyDown={(e) => AppUtil.isEnterKey(e) ? loadApiKeys() : null}
                                />
                                <span className="btn-search-key">
                                    <button title="Click to search the key" disabled={!(filterKeys.text)}
                                        className="pmivr-btn-transparent" onClick={() => loadApiKeys()}>
                                        <i className="bi bi-search"></i>
                                    </button>
                                </span>
                            </div>
                            <div className="col-sm-3">
                                <select className="pmivr-select" value={filterKeys?.status}
                                    onChange={(e) => setFilterText(filterKeys.text, e.target.value)}>
                                    {
                                        KEY_STATUS.map((filter) => {
                                            return (
                                                <option key={filter.value} value={filter.value}>
                                                    {filter.text}
                                                </option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                            <div className="col-sm-2">
                                <button className="float-start pmivr-btn-secondary pmivr-reset-link"
                                    onClick={() => resetData()}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* right side action: option for adding the key */}
                    <div className="col-lg-4">
                        {UserService.hasPermission() && (
                            <div className="pmivr-relative float-end pe-1">
                                <button title="Click to add the new number" onClick={() => showApiKeyModal()}
                                    className="pmivr-btn-app pmivr-reset-link">
                                    <i className="bi bi-plus-lg"></i> Add Key
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                {/* total records bar */}
                <div className="row pe-1">
                    <div className="col-lg-6">
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total Records: {pagination.totalResults || 0}</div>
                    </div>
                    {
                        (pagination.totalResults > pagination.count) ?
                            <div className="col-lg-6">
                                {
                                    (pagination.currentPage < pagination.totalPages) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Next page of keys list"
                                                    onClick={() => loadApiKeys(pagination.currentPage + 1)}>
                                                    Next <i className="bi bi-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div> : <></>
                                }
                                {
                                    (pagination.currentPage > 1) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Previous page of keys list"
                                                    onClick={() => loadApiKeys(pagination.currentPage - 1)}>
                                                    <i className="bi bi-arrow-left"></i> Previous
                                                </Link>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                            : <></>
                    }
                </div>
                {/* list of api keys table */}
                <div className="row me-2 ms-2">
                    <table className="table pmivr-table header-fixed table-body-block border mt-2">
                        <thead>
                            <tr>
                                <th width="10%" className="text-center">Email</th>
                                <th width="10%" className="text-center">App Code</th>
                                <th width="10%" className="text-center">API Key Name</th>
                                <th width="10%" className="text-center">API Key</th>
                                <th width="10%" className="text-center">Updated On</th>
                                <th width="10%" className="text-center">Updated By</th>
                                <th width="10%" className="text-center">Status</th>
                                <th width="10%" className="text-center">Expires On</th>
                                <th width="20%" className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            {
                                (apiKeys?.length) ?
                                    [...apiKeys.values()].map((keyData, index) => {
                                        return (
                                            <tr key={index}>
                                                <td width="10%" title={keyData.email} className="text-center pt-2">{keyData.email}</td>
                                                <td width="10%" title={keyData.appCode} className="text-center pt-2">{keyData.appCode}</td>
                                                <td width="10%" title={keyData.apiKeyName} className="text-center pt-2">{keyData.apiKeyName}</td>
                                                <td width="10%" title={keyData.apiKey} className="text-center pt-2">****************</td>
                                                <td width="10%" title={keyData.updatedOn} className="text-center pt-2">
                                                    {AppUtil.formatDateInLocal(keyData.updatedOn)}
                                                </td>
                                                <td width="10%" title={keyData.updatedBy} className="text-center pt-2">{keyData.updatedBy}</td>
                                                <td width="10%" title={keyData.status} className="text-center pt-2">{keyData.status}</td>
                                                <td width="10%" title={AppUtil.formatDateInLocal(keyData.expiresOn)} className="text-center pt-2">
                                                    {AppUtil.formatDateInLocal(keyData.expiresOn)}
                                                </td>
                                                <td width="20%" title={keyData.status} className="text-center pt-2">
                                                    <button className="pmivr-btn-secondary p-2 py-0 mx-2" onClick={() => openConfirmationDialog(keyData.email, keyData.appCode, ACTION.REGENERATE_KEY)}>
                                                        Regenerate
                                                    </button>
                                                    <button className="pmivr-btn-secondary p-2 py-0" onClick={() => openConfirmationDialog(keyData.email, keyData.appCode, ACTION.DELETE_KEY)}>
                                                        Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr><td>No Key Found</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}
export default ApiKeys;