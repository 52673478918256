import { ATTRIBUTES, ATTRIBUTE_VALUE } from "../constants/attributes";
import { DEFAULT_EL_NAME_PREFIX, EL_TYPE } from "../constants/element";
import { MODELER_EVENTS } from "../constants/events";
import { VOICE_FILE_TYPE, VOICE_FILE_UPLOAD_TYPE } from "../constants/voice-file";
import { TASK_TYPE, TASK_PREFIX } from "../constants/task-types";

import AudioUtil from "../util/audio.util";
import StringUtil from "./string.util";
import AppUtil from "./app.util";

import { CHANGE_ATTRS_TO_AVOID, SHAPE_HIGHLIGHT_COLOR } from "../config/config";

import ChangeHistoryService from "../services/change-history.service";
import ElementService from "../services/element.service";
import AudioService from "../services/audio.service";
import FlowService from "../services/flow.service";
import DiagramService from "../services/diagram.service";

/**
 * For the processing of listen events on the modeler on diagra.js file
 */
class DiagramUtil {
  /**
   *Processing the changed property event in the diagram
   * Updates the changing in right panel in diagram page 
   * And updates the change history to maintain the record of changes. 
   * @param {Object} event
   * @param {Array} changeHistory Array containing history of changes client has done. 
   * @returns {Object} Object containing changeHistory and isUpdated values.
   */
  static processUpdatePropertyEvent(event, changeHistory) {
    let isUpdated = false;
    const element = event.context.element;
    const property = Object.keys(event.context.properties)[0];
    // some properties/attributes are set implicitly for the flow and not counted as user changes
    // dont make change history for that
    const avoidToTrack = CHANGE_ATTRS_TO_AVOID.includes(property);
    if (!avoidToTrack) {
      const oldPropertyValue = event.context.oldProperties[property];
      const propertyValue = event.context.properties[property];
      // tracking the changes
      const isAttributeChanged = ChangeHistoryService.isAttributeChanged(property, oldPropertyValue, propertyValue);
      // add in change history only if there is any change in control tasks
      if (isAttributeChanged) {
        // get the element if exist with same changes
        const changedElement = changeHistory.find(
          (info) =>
            info.id === element.id &&
            info.eventType === MODELER_EVENTS.UPDATE_PROPERTY &&
            info?.change === property
        );
        // if not chanegs were made to this element add entry
        const elName = element.businessObject.get("name") || '';
        if (!changedElement) {
          const taskType = element.businessObject.get("taskType");
          const history = {
            id: element.id,
            elName,
            elType: element.type,
            taskType,
            eventType: MODELER_EVENTS.UPDATE_PROPERTY,
            change: property,
            changeValue: propertyValue,
          };
          changeHistory.push(history);
        } else {
          // update properties
          const updatedElement = { ...changedElement, changeValue: propertyValue };
          const index = changeHistory.findIndex(info => info.id === element.id && element?.change === property);
          // update the changeHistory with the object of updated name
          if (index !== -1) {
            changeHistory.splice(index, 1, updatedElement);
          }
        }
        isUpdated = true;
      }
    }
    return { isUpdated, changeHistory };
  };

  /**
   * Processing the moved element event in the diagram
   * @param {Object} event 
   * @param {Array} changeHistory Array containing history of changes client has done. 
   */
  static processElementMovedEvent(event, changeHistory) {
    const element = event.context.shapes[0];
    if (element) {
      const elementId = element.id;
      const updatedElement = changeHistory.find(
        (history) => history.id === elementId
      );
      if (updatedElement) {
        // giving timeout to render the elements properly and then highlighting the changed element
        setTimeout(() => {
          DiagramUtil.highlightElement(element.id);
        }, 200);
      }
    }
    return changeHistory;
  };

  /**
   * Processing the deleted element to update the condition if condition is linked with deleted node
   * On deleting the element it will update the condition string if deleted element is connected with the condition
   * @param {Object} event 
   */
  static updateConditionElement(event) {
    // event.context contains list of elements , its an array which contains deleted element inside it
    let deletedElement = event.context.elements;
    deletedElement = deletedElement[0];
    // not the sequence flow since it will not have its incoming and outgoing 
    if (deletedElement.type !== EL_TYPE.SEQUENCE_FLOW) {
      // Find all incoming connections to the deleted task
      const incomingConnections = deletedElement.incoming;

      incomingConnections.forEach(connection => {
        // Check if the connection source is an exclusive gateway
        if (connection.source.type === EL_TYPE.GATEWAY) {
          const exclusiveGateway = connection.source.businessObject;

          // Parse the conditions array if it is stringified
          const strConditions = JSON.parse(exclusiveGateway.condition);
          if (strConditions) {
            const conditions = strConditions.filter((condition) => condition.connectionId !== connection.id);

            // update the attribute
            const gatewayConditionsStr = JSON.stringify(conditions);
            ElementService.updateElementAttr(connection.source, ATTRIBUTES.GATEWAY_CONDITION, gatewayConditionsStr);
          }
        }
      });
    }
  };

  /**
   * Process the update element name event
   * @param {Object} event 
   * @param {Array} changeHistory Array containing history of changes client has done. 
   * @returns {Object} object containing changeHistory and isUpdated values.
   */
  static processUpdateElementNameEvent(event, changeHistory) {
    const element = event.context.element;
    const newName = event.context.newLabel;
    const oldName = event.context.oldLabel;
    let isUpdated = false;

    // if user changed nothing then return
    if (newName === oldName) {
      return { isUpdated, changeHistory };
    }

    let updatedHistory;
    // update the eventType for the history with matching conditions
    changeHistory.forEach((history, index) => {
      // If new shape has been created already and its name is being changed, don't create a new entry, instead just change the event type
      if (history.id === element.id &&
        [MODELER_EVENTS.CREATE_SHAPE,
        MODELER_EVENTS.CUSTOM.CREATE_SHAPE_AND_RENAME].includes(history.eventType)) {
        isUpdated = true;
        // Update the eventType if conditions are met
        updatedHistory = { ...history, eventType: MODELER_EVENTS.CUSTOM.CREATE_SHAPE_AND_RENAME, changeValue: newName };
        changeHistory[index] = updatedHistory;
      }
    });

    if (isUpdated) {
      // if any history related to property update exists in the change history, then update the name with new name
      changeHistory.forEach((history, index) => {
        if (history.id === element.id && history.eventType === MODELER_EVENTS.UPDATE_PROPERTY) {
          // Update the element name if conditions are met
          updatedHistory = { ...history, elName: newName };
          changeHistory[index] = updatedHistory;
        }
      });
      return { isUpdated, changeHistory };
    }

    isUpdated = true;
    // get the element if exist with same changes
    const changedElement = changeHistory.find(
      (info) => info.id === element.id
    );

    const elName = element.businessObject.get("name");
    const taskType = element.businessObject.get("taskType");
    // if not chanegs were made to this element add entry
    if (!changedElement) {
      changeHistory.push({
        id: element.id,
        elName,
        elType: element.type,
        taskType,
        eventType: MODELER_EVENTS.UPDATE_NAME,
        change: null,
        changeValue: newName,
        oldValue: oldName,
      });
    } else {
      // boolean to check if the name of element is already changed
      let elementNameAlreadyChanged = false;
      // updating the element name for all same history items 
      changeHistory = changeHistory.map((item) => {
        // change the elName property of the matched item 
        if (item.id === element.id && item.eventType !== MODELER_EVENTS.UPDATE_NAME) {
          return { ...item, elName };
        }
        // if the element name is already changed, no need to push new change 
        if (item.id === element.id && item.eventType === MODELER_EVENTS.UPDATE_NAME) {
          elementNameAlreadyChanged = true;
          return { ...item, elName, changeValue: newName, oldValue: oldName };
        }
        // returning the item because map method needs a return statement
        return item;
      });

      // push new change to change history if the element name is changed and it is not present in history.
      if (!elementNameAlreadyChanged) {
        changeHistory.push({
          id: element.id,
          elName,
          elType: element.type,
          taskType,
          eventType: MODELER_EVENTS.UPDATE_NAME,
          changeValue: newName,
          oldValue: oldName,
        });
      }
    }
    return { isUpdated, changeHistory };
  };

  /**
   * Process the create element event
   * @param {Object} event 
   * @param {Array} changeHistory Array containing history of changes client has done. 
   */
  static processCreatedElementEvent(event, changeHistory) {
    const element = event.context.shape;
    const elName = element.businessObject.get("name");
    let isUpdated = false;

    // get the element if exist with same changes
    const changedElement = changeHistory.find((info) =>
      info.id === element.id && info.eventType === MODELER_EVENTS.CREATE_SHAPE
    );

    // if not chanegs were made to this element add entry
    if (!changedElement) {
      changeHistory.push({
        id: element.id, elName: "<no-name>", elType: element.type, taskType: null,
        eventType: MODELER_EVENTS.CREATE_SHAPE, change: null, changeValue: elName
      });
      isUpdated = true;
    }
    return { isUpdated, changeHistory };
  }

  /**
   * Add default name to the given event
   * @param {Object} event triggered create shape event
   */
  static addDefaultNameToEvent(event) {
    const businessObject = event.context.shape.businessObject || {};
    switch (businessObject?.$type) {
      case EL_TYPE.START_EVENT:
        businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.START);
        break;
      case EL_TYPE.GATEWAY:
        businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.GATEWAY);
        break;
      case EL_TYPE.SERVICE_TASK:
        businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.SERVICE);
        break;
      case EL_TYPE.INTERMEDIATE_THROW_EVENT_TASK:
        businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.INTERMEDIATE);
        break;
      case EL_TYPE.USER_TASK:
        if (businessObject.taskType === TASK_TYPE.promptUserOption) {
          businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.OPTION_INPUT);
        } else if (businessObject.taskType === TASK_TYPE.promptUserInput) {
          businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.USER_INPUT);
        } else if (businessObject.taskType === TASK_TYPE.recordVoice) {
          businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.VOICE_RECORD);
        }
        break;
      case EL_TYPE.TASK:
        if (businessObject.isSayData) {
          businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.PROMPT);
        } else if (businessObject.taskName === ATTRIBUTE_VALUE.VOICE_TASK_NAME_HANGUP) {
          businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.HANGUP);
        } else if (businessObject.isTransferTask) {
          businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.TRANSFER);
        } else if (businessObject.taskType === TASK_TYPE.playVoiceFile) {
          businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.VOICE);
        }
        break;
      case EL_TYPE.END_EVENT:
        businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.END);
        break;
      case EL_TYPE.SUB_PROCESS:
        businessObject.name = AppUtil.getTaskName(DEFAULT_EL_NAME_PREFIX.SUB_PROCESS);
        break;
      default:
        businessObject.name = '';
    }
  }

  /**
  * Process the connection create
  * @param {Object} event
  * @param {Array} changeHistory Array containing history of changes client has done. 
  */
  static processConnectionCreate(event, changeHistory) {
    const connection = event.context.connection;
    const sourceNode = event.context.source.businessObject.name;
    const targetNode = event.context.target.businessObject.name;
    let isUpdated = false;
    // get the element if exist with same changes
    const changedElement = changeHistory.find((info) =>
      info.id === connection.id && info.eventType === MODELER_EVENTS.CONNECTION_CREATED
    );
    if (!changedElement) {
      changeHistory.push({
        id: connection.id, elName: "<no-name>", elType: connection.type, taskType: null,
        eventType: MODELER_EVENTS.CONNECTION_CREATED, change: event.command,
        changeValue: { 'sourceNode': sourceNode || '<no-name>', 'targetNode': targetNode || '<no-name>' }
      });
      isUpdated = true;
    }

    return { isUpdated, changeHistory };
  }

  /**
   * Process the connection delete
   * @param {Object} e 
   * @param {Array} changeHistory Array containing history of changes client has done. 
   */
  static processConnectionDelete(e, changeHistory) {
    const connection = e.context.elements[0];
    let isUpdated = false;
    // now remove the condition from gateway as well
    const sourceGateway = connection.source;
    // event was getting multiple times and first event will delte the connection
    if (sourceGateway) {
      const strConditions = ElementService.getAttribute(sourceGateway, ATTRIBUTES.GATEWAY_CONDITION);
      if (strConditions) {
        let conditions = JSON.parse(strConditions);
        conditions = conditions.filter((condition) => condition.connectionId !== connection.id);

        // update the attribute
        const gatewayConditionsStr = JSON.stringify(conditions);
        ElementService.updateElementAttr(sourceGateway, ATTRIBUTES.GATEWAY_CONDITION, gatewayConditionsStr);
      }
    }
    // get the element if exist with same changes
    const changedElement = changeHistory.find((info) =>
      info.id === connection.id && info.eventType === MODELER_EVENTS.CONNECTION_DELETE
    );

    if (!changedElement) {
      // in case of deleting the connection between elements
      changeHistory.push({
        id: connection.id, elName: "<no-name>", elType: connection.type, taskType: null,
        eventType: MODELER_EVENTS.CONNECTION_DELETE, change: e.command,
        changeValue: { 'sourceNode': connection.source.businessObject.name || '<no-name>', 'targetNode': connection.target.businessObject.name || '<no-name>' }
      });
      isUpdated = true;
    }

    return { isUpdated, changeHistory };
  }

  /**
   * Highlight the element only if element exists in change history
   * @param {string} elementId Id of element 
   */
  static highlightChangedEl(elementId) {
    const elInfo = ChangeHistoryService._changeHistory.find((history) => history.id === elementId);
    if (elInfo) {
      DiagramUtil.highlightElement(elementId);
    }
  }

  /**
   * Highlighing the element by changing the color of border
   * @param {string} elementId Element id 
   */
  static highlightElement(elementId) {
    // highlight the element
    DiagramUtil._highlightBorder(elementId);
    // highlight the parent element if child element (get bpmn element info)
    const elementInfo = ElementService.getAllElements().find((el) => el.id === elementId);
    if (elementInfo) {
      const parent = elementInfo.parent;
      if (parent && parent.type === EL_TYPE.SUB_PROCESS) {
        DiagramUtil._highlightBorder(parent.id);
      }
    }
  }

  /**
   * Get the element and highlight it's border
   * @param {string} elementId 
   */
  static _highlightBorder(elementId) {
    // document cannot be removed as div is generated dynamically for the BPMN element
    const domElement = document.querySelector(`[data-element-id="${elementId}"]`);
    // in case of some elements like condition box it can be empty
    if (domElement) {
      const isGatway = elementId?.startsWith("Gateway");
      // start event, end event, intermediary event
      const isEvent = elementId?.startsWith("Event");
      // flow arrow
      const isConnection = elementId?.startsWith("Flow");
      // element to hightlight
      let highlightEl;
      if (isGatway) {
        highlightEl = domElement.querySelector("polygon");
      } else if (isEvent) {
        highlightEl = domElement.querySelector("circle");
      } else if (isConnection) {
        highlightEl = domElement.querySelector("path");
      } else {
        highlightEl = domElement.querySelector("line.el-border-top");
      }

      if (highlightEl) {
        highlightEl.style.stroke = SHAPE_HIGHLIGHT_COLOR;
      }
    }
  }

  /**
   * Update voicefile information base on voice file upload type
   * Sets the required attributes on bpmn element
   * @param {{element, fileType, data }} event contains element , event type and voice file info
   */
  static updateVoiceFileAttribute(event) {
    let invalidVoiceFileInfo = {};
    let voiceFileInfo = {};
    if (event.fileType === VOICE_FILE_TYPE.INVALID) {
      // incase of invaild voice file we get invalid voice file information from 
      // ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE attribute
      const invalidOptionAttrValue = ElementService.getAttribute(event.element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE) || "{}";
      invalidVoiceFileInfo = JSON.parse(invalidOptionAttrValue);
    } else if (event.fileType === VOICE_FILE_TYPE.VALID) {
      // incase of vaild voice file we get invalid voice file information from ATTRIBUTES.VOICE_FILE_INFO attribute
      voiceFileInfo = ElementService.getAttribute(event.element, ATTRIBUTES.VOICE_FILE_INFO, "{}");
      voiceFileInfo = JSON.parse(voiceFileInfo);
    }

    // format voice file information for each language and file type
    for (let i in event.data) {
      if (event.fileType === VOICE_FILE_TYPE.INVALID) { // incase of invalid file
        const info = AudioUtil.getFormattedVoiceFileInfo(event.data[i].language, invalidVoiceFileInfo);
        info[i].ttsText = event.data[i].text || "";
        info[i].voiceFileType = event.data[i].voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? VOICE_FILE_UPLOAD_TYPE.UPLOAD : VOICE_FILE_UPLOAD_TYPE.TTS;
        invalidVoiceFileInfo = info;
      } else if (event.fileType === VOICE_FILE_TYPE.VALID) { // incase of valid file
        const info = AudioUtil.getFormattedVoiceFileInfo(event.data[i].language, voiceFileInfo);
        info[i].ttsText = event.data[i].text || "";
        info[i].voiceFileType = event.data[i].voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? VOICE_FILE_UPLOAD_TYPE.UPLOAD : VOICE_FILE_UPLOAD_TYPE.TTS;
        info[i].filePath = event.data[i].filePath
        voiceFileInfo = info;
      }
    }
    // Get the new element's object 
    // Note when not get the new element object from elementRegistry then it will only update the attributes of the last element or elements of the last subprocess in the list will get updated, everything else will be skipped(not change)
    event.element = ElementService.getElementById(event.element.id);

    if (event.fileType === VOICE_FILE_TYPE.INVALID) {  // update invaild voice file information
      ElementService.updateElementAttr(event.element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(invalidVoiceFileInfo));
    }
    if (event.fileType === VOICE_FILE_TYPE.VALID) {  // update vaild voice file information
      ElementService.updateElementAttr(event.element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(voiceFileInfo));
    }
  }

  /**
   * Uploads the voice file to s3 
   * example for optionId 1 for english 2 for spanish these will have their own ids that are called optionIds 
   * @param {Object} element 
   * @param {string} file file to save
   * @param {string} language language selected by user
   * @param {string} fileType 
   * @param {string} optionId  id of option from keyValueMap taskType
   * @param {string} flowName based on flow name the dir is created where voice file is stored
   * @returns {Promise<{voiceFileType, filePath, elementId, language, fileType, optionId, metaInfo}>} Info of uploaded file saved
   */
  static async uploadVoiceFile(element, file, language, flowName, fileType = "", optionId = "") {
    try {
      const basicFlowInfo = FlowService.getBasicFlowInfo();
      const businessCode = basicFlowInfo.businessCode;
      const flowInfo = { businessCode, file, elementId: element.id, language, flowName, optionId };
      const response = await AudioService.uploadVoiceFile(flowInfo);
      return {
        voiceFileType: VOICE_FILE_UPLOAD_TYPE.UPLOAD,
        filePath: response.data.filePath,
        elementId: element.id,
        metaInfo: response.data.metaInfo,
        language, fileType, optionId
      };
    } catch (e) {
      throw new Error({ code: 403, message: "Error while uploading file" });
    }
  }

  /**
   * Changes the heading and highlight color for element
   * @param {string} elementId - unique id of the flow element
   * @param {string} headingColor - color of the element heading (label) in hexadecimal
   * @param {string} highlightColor - color of the line at the top of element box in hexadecimal
   */
  static changeElementHighlightColor(elementId, headingColor, highlightColor) {
    // Get the parent tag for the component
    const domElement = document.querySelector(`[data-element-id="${elementId}"]`);
    if (domElement) {
      const highlightEl = domElement.querySelector("line.el-border-top");
      const elementLabel = domElement.querySelectorAll("text.djs-label");
      // Changing the upper line color
      highlightEl.style.stroke = highlightColor;
      // Changing the element label color for each label of component (voice file path, heading etc.)
      elementLabel.forEach((label) => label.style.fill = headingColor);
    }
  }

  /**
   * Generates disabled attribute for all task types
   * isDisabled attribute is dynamic and is different for different task types 
   * because same attribute can not be added in two schemas due to conflict
   * Eg: for sayData task- sayData:isDisabledSayData
   * @param {string} taskType - type of the task: voice/sayData etc. 
   * @returns {string} attribute name generated
   */
  static generateDisabledAttribute = (taskType) => {
    const taskPrefix = TASK_PREFIX[taskType] || 'element';
    // capitalizes first letter of the task type for adding it at end of isDisabled
    const capitalizedTaskPrefix = taskPrefix.charAt(0).toUpperCase() + taskPrefix.slice(1);
    const attributeName = taskPrefix + ':isDisabled' + capitalizedTaskPrefix;
    // task:isDisabledTask
    return attributeName;
  }

  /**
   * Checks if the element is disabled
   * @param {Object} element 
   * @returns {Boolean} whether the element is disabled or not
   */
  static checkElementDisabled = (element) => {
    let isElementDisabled;
    // If the element is sub-process then simply check disabled attribute
    if (element.type === EL_TYPE.SUB_PROCESS) {
      isElementDisabled = StringUtil.toBoolean(element.businessObject.$attrs?.isDisabledSubprocess);
    } else {
      const isDisabledAttribute = DiagramUtil.generateDisabledAttribute(element.businessObject.taskType);
      // Since attribute returned will be in the form of element:isDisabledElement
      const attribute = isDisabledAttribute.split(':')[1];
      // Get the value of attribute from business object
      isElementDisabled = StringUtil.toBoolean(element.businessObject[attribute]);
    }

    return isElementDisabled;
  }
  /**
   * Hides the incoming flow lines for any process/subprocess.
   * @param {Object} element - bpmn element
   */
  static hideIncomingFlowLines(element) {
    // get the id for incoming arrows towards the element
    const incomingConnectionIds = element.incoming.map(flow => {
      return flow.id;
    });
    // Disable each arrow with the connection id's in incoming attribute
    incomingConnectionIds.forEach((id) => {
      const djsElement = document.querySelector(`[data-element-id="${id}"]`);
      // add the class to override the display property of incoming connections for subprocess
      if (djsElement) {
        djsElement.classList.add('incoming-flow-line');
      }
    });
  }

  /**
   * Set the businessObject of the element
   * Sets the information like taskType, optionRetryCount etc 
   * @param {Object} businessObject
   * @param {{taskType,stepName}} attributes attributes of the elements
   */
  static setBusinessObject = (businessObject, attributes) => {
    Object.keys(attributes).forEach((key) => {
      businessObject[key] = attributes[key];
    });
  }

  /**
   * Get position of child elements inside sub process 
   * Calculation of posiiton x-axis and y-axis for element to be placed is done
   * @param {Object} modeler
   * @returns {{posX,posY}} positons x and y of sub process
   */
  static getNewElementPosition = (modeler) => {
    // Determine the position dynamically based on existing elements
    let posX = 0;
    let posY = 0;
    // get the canvas so that we can calculate position of x and y co ordinates
    const elementRegistry = modeler.get('elementRegistry');
    const canvas = modeler.get('canvas');
    const canvasWidth = canvas.viewbox().inner.width;
    const canvasHeight = canvas.viewbox().inner.height;
    const existingElements = elementRegistry.filter(element => element.type !== EL_TYPE.SEQUENCE_FLOW);
    const elementSpacing = { x: -100, y: 200 };

    if (existingElements.length > 1) {
      const lastElement = existingElements[1];
      posX = lastElement.x + elementSpacing.x;
      posY = lastElement.y + elementSpacing.y;
    } else {
      // If no elements are present, place the subprocess at the center of the canvas
      posX = canvasWidth / 2 - 100;
      posY = canvasHeight / 2 - 100;
    }
    return { posX, posY };
  }

  /**
   * Process delete event of any element or connection
   * @param {Object} e - bpmn event triggered
   * @param {Array} changeHistory - array of objects where each object is a change history
   * @returns {{isUpdated: boolean, changeHistory: Object}}
   */
  static processElementDelete(e, changeHistory) {
    let deletedElement = e.context.elements;
    const element = deletedElement[0];
    // in case the element to delete is arrow, process the connection delete
    if (element.type === EL_TYPE.SEQUENCE_FLOW) {
      const response = this.processConnectionDelete(e, changeHistory);
      return response;
    }
    // getting name of the element
    const elName = element.businessObject.get("name");
    let isUpdated = false;
    // get the element if exist with same changes
    const changedElement = changeHistory.find((info) =>
      info.id === element.id && info.eventType === MODELER_EVENTS.ELEMENT_DELETE
    );

    // if no changes were made to this element add entry
    if (!changedElement) {
      changeHistory.push({
        id: element.id, elName, elType: element.type, taskType: element.type,
        eventType: MODELER_EVENTS.ELEMENT_DELETE, change: e.command, changeValue: elName
      });
      isUpdated = true;
    }

    return { isUpdated, changeHistory };
  }

  /**
   * Undo the change in change history, if user does undo of connection delete
   * @param {Object} e - bpmn event triggered
   * @param {Array} changeHistory - array of objects where each object is a change history
   * @returns {{isUpdated: boolean, changeHistory: Object}}
   */
  static processUndoConnectionDelete(e, changeHistory) {
    const connection = e.context.connection;
    let isUpdated = false;
    // now remove the condition from gateway as well
    const sourceGateway = e.context.source;
    if (sourceGateway) {
      // from the change history remove the entry with connection delete message for this connection
      changeHistory.forEach((history, index) => {
        if (history.id === connection.id) {
          changeHistory.splice(index, 1);
        }
      });
      isUpdated = true;
    }

    return { isUpdated, changeHistory };
  }

  /**
   * Undo the change in change history, if user does undo of connection delete
   * @param {Object} e - bpmn event triggered
   * @param {Array} changeHistory - array of objects where each object is a change history
   * @returns {{isUpdated: boolean, changeHistory: Object}}
   */
  static processUndoElementDelete(e, changeHistory) {
    let isUpdated = false;
    const element = e.context.shape;
    // get the element if exist with same changes
    const changedElement = changeHistory.find((info) =>
      info.id === element.id && info.eventType === MODELER_EVENTS.UNDO_ELEMENT_DELETE
    );

    if (!changedElement) {
      // from the change history remove the entry with element delete message for this connection
      changeHistory.forEach((history, index) => {
        if (history.id === element.id && history.eventType === MODELER_EVENTS.ELEMENT_DELETE) {
          changeHistory.splice(index, 1);
        }
      });
      isUpdated = true;
    }

    return { isUpdated, changeHistory };
  }
}

export default DiagramUtil;
