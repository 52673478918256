import React, { useState, useContext, useEffect } from "react";
import { VoiceContext } from "./../../../contexts/app-context";
import { SERVICE_TYPES } from "../../../constants/task-types";
import { ATTRIBUTES } from "./../../../constants/attributes";
import ApiCallServiceView from "./components/ApiCallServiceView";
import CustomExpressionServiceView from "./components/CustomExpressionServiceView";
import MethodCallServiceView from "./components/MethodCallServiceView";
import ElementService from "./../../../services/element.service";

/**
 * Component for configuring service properties including Method Call and API Call options.
 * Displays different input fields based on the selected service type.
 */
const ServicePropertiesView = () => {
  const { element } = useContext(VoiceContext);

  const [serviceType, setServiceType] = useState(SERVICE_TYPES.METHOD_CALL);

  useEffect(() => {
    // default to show method call
    const serviceType = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.METHOD_CALL);
    // Update serviceType based on the element's type attribute
    setServiceType(serviceType);
  }, [element]);

  return (
    <div className=" m-2 mt-3">
        <form>
          {/* Service Type Selection */}
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="serviceType" id="methodCall"
              value={SERVICE_TYPES.METHOD_CALL} checked={serviceType === SERVICE_TYPES.METHOD_CALL}
              onChange={() => setServiceType(SERVICE_TYPES.METHOD_CALL)} />
            <label className="form-check-label" htmlFor="methodCall">
              Method Call
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="serviceType" id="apiCall"
              value={SERVICE_TYPES.API_CALL} checked={serviceType === SERVICE_TYPES.API_CALL}
              onChange={() => setServiceType(SERVICE_TYPES.API_CALL)} />
            <label className="form-check-label" htmlFor="apiCall">
              API Call
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="serviceType" id="expression"
              value={SERVICE_TYPES.EXPRESSION} checked={serviceType === SERVICE_TYPES.EXPRESSION}
              onChange={() => setServiceType(SERVICE_TYPES.EXPRESSION)} />
            <label className="form-check-label" htmlFor="expression">
              Expression
            </label>
          </div>

          {/* Method Call Inputs */}
          {serviceType === SERVICE_TYPES.METHOD_CALL && (
            <MethodCallServiceView />
          )}

          {/* API Call Inputs */}
          {serviceType === SERVICE_TYPES.API_CALL && (
            <ApiCallServiceView />
          )}
          {/* Custom Expression Inputs */}
          {serviceType === SERVICE_TYPES.EXPRESSION && (
            <CustomExpressionServiceView />
          )}
        </form>
    </div>
  );
};

export default ServicePropertiesView;