import AppUtil from "./app.util";

/**
 * utility for the single voice file option
 */
class VoiceFileOptionUtil {

  /**
   * As per the option and value, it returns the options list.
   * Like, their is yes and no options, the result returned will be 12
   * As there two options - 1-yes , 2-No
   * @param {Object} optionAndVal 
   * @param {boolean} isOptionAndValStartFromZero 
   * @returns Returns a string value in the forat of 1 and 2 based on options yes and no.
   */
  static getUpdatedOptions(optionAndVal, isOptionAndValStartFromZero) {
    let optionsListTemp = "";
    for (let i = 0; i < Object.keys(optionAndVal).length; i++) {
      if (AppUtil.isValueValid(optionAndVal[i][0]) && AppUtil.isValueValid(optionAndVal[i][1])) {
        // some optionAndVal start from 0, but we need to start optionListTemp value from 1, rather than zero (addded check isOptionAndValStartFromZero)
        optionsListTemp = optionsListTemp + ((isOptionAndValStartFromZero) ? (Number(optionAndVal[i][0]) + 1) : optionAndVal[i][0]);
      }
    }
    return optionsListTemp;
  }

  /**
   * Return the list of all the avaiable options
   * @param {Array} langInfo Info of languages
   * @returns {Array} List of all avalaible options
   */
  static getAvailableOptions(langInfo) {
    const options = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "#"];
    const availableOptions = [];
    const selectedOptions = [];
    langInfo?.forEach((info) => {
      if (info.option) {
        selectedOptions.push(info.option);
      }
    });
    options.forEach((opt) => {
      if (!selectedOptions.includes(opt)) {
        availableOptions.push(opt);
      }
    });
    return availableOptions;
  }

  /**
   * Sort the voice file info by option value so that the option render and play in sequence
   * @param {Array} fileInfo 
   * @returns {Array} Array of sorted voice file based on option value
   */
  static sortVoiceFileLanguageInfoByOption(fileInfo) {
    fileInfo.sort(function (a, b) {
      return a.option - b.option;
    });
    return fileInfo;
  }
}

export default VoiceFileOptionUtil;