import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { ATTRIBUTES } from "../../../constants/attributes";
import { VOICE_FILE_DEFAULT_LANGUAGE, VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { CSS_CLASSES } from "../../../constants/css-classes.js";
import { TOOLTIP } from "../../../constants/messages";
import { envConfig } from "../../../environment/index.js";

import { SingleVoiceContext, VoiceContext } from "../../../contexts/app-context";
import { PmivrDialog } from "../../common/dialog/pmivr-dialog";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip.js";
import PmivrLabel from "../../common/label/pmivr-label.js";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import OptionValueMapping from "../../common/option-value-mapping/option-value-mapping.js";
import VoiceFileSettingModal from "./VoiceFileSettingModal";
import AudioPlayer from "../../common/audio-player/audio-player.js";

import AudioUtil from "../../../util/audio.util";
import AppUtil from "../../../util/app.util.js";
import VoiceFileOptionUtil from "../../../util/voice-file-option.util";

import ElementService from "../../../services/element.service";
import AudioService from "../../../services/audio.service";
import VoiceFileOptionService from "../../../services/voice-file-option.service";

/**
 * Single voice file component for option user
 */
const SingleVoiceFileOption = () => {
  const [optionList, setOptionList] = useState("");
  const [optionAndVal, setOptionAndVal] = useState({});
  const [isOptionAndValStartFromZero, setIsOptionAndValStartFromZero] = useState(false);
  // option menu to render
  const [showOptionMenu, setShowOptionMenu] = useState(false);
  // boolean value to show voice file settings menu
  const [showVoiceFileSettingMenu, setShowVoiceFileSettingMenu] = useState(false);
  // array of languages supported
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  // language selected in voice file info and update if change in the modal
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // inforation about voice file
  const [voiceFileInfo, setVoiceFileInfo] = useState({});
  // path stored for voice file
  const [voiceFilePath, setVoiceFilePath] = useState("");
  // type of upload method for the voice file 
  const [voiceFileUploadMethod, setVoiceFileUploadMethod] = useState("");
  const [tmpVoiceFileInfo, setTmpVoiceFileInfo] = useState({});
  const [tmpVoiceFileType, setTmpVoiceFileType] = useState({});

  const ttsRef = useRef();
  const uploadRef = useRef();

  const { element, rightPanelEventHandler } = useContext(VoiceContext);

  // latest state from redux store
  const { voiceFilePrefixObj } = useSelector(state => state.voiceFile);

  useEffect(() => {
    const init = async () => {
      setOptionList(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS) ? element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS).split("") : "");
      setVoiceFileUploadMethod(VOICE_FILE_UPLOAD_TYPE.LIBRARY);
      const tempOptionAndVal = VoiceFileOptionService.getFormattedOptionAndVal(element); // get formatted option val
      const voiceFileInfo = await AudioService.getVoiceFileInfo(element, ATTRIBUTES.VOICE_FILE_INFO, selectedLanguage);
      // all languages voice file info template
      const voiceFileInfoAll = AudioUtil.getFormattedVoiceFileInfo(voiceFileInfo.selectedLanguage, voiceFileInfo.voiceFileInformation);
      setOptionList(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS) ? element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS).split("") : "");
      setOptionAndVal(tempOptionAndVal);
      setIsOptionAndValStartFromZero((tempOptionAndVal && tempOptionAndVal[0] && tempOptionAndVal[0][0]) ? (tempOptionAndVal[0][0].toString() === '0') : false);
      setShowOptionMenu(false);
      // flag to show voice file setting menu
      setShowVoiceFileSettingMenu(false);
      //set the supported languages
      setSupportedLanguages(voiceFileInfo.supportedLanguages || []);
      // flag to set the selected language
      setSelectedLanguage(voiceFileInfo.selectedLanguage);
      // set the voice file info
      setVoiceFileInfo(voiceFileInfoAll);
      setVoiceFilePath(voiceFileInfoAll[voiceFileInfo.selectedLanguage]?.filePath || voiceFilePrefixObj[voiceFileInfo.selectedLanguage]);
      setVoiceFileUploadMethod(voiceFileInfoAll[voiceFileInfo.selectedLanguage].voiceFileType);
      const tempVoiceInfo = {};
      const tempVoiceFileType = {};
      // initially set the temp file info for the respective languages and set temp voice file info
      voiceFileInfo?.supportedLanguages.forEach((lang) => {
        const tmpVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(lang, null);
        tempVoiceInfo[lang] = tmpVoiceFileInfo;
        tempVoiceFileType[lang] = { "fileType": VOICE_FILE_UPLOAD_TYPE.LIBRARY };
      });
      setTmpVoiceFileInfo(tempVoiceInfo);
      setTmpVoiceFileType(tempVoiceFileType);
    }
    init();
  }, [element]);

  useEffect(() => {
    // update the voice file method selected to know while saving which method was selected
    // to get saved in voiceFileInfo 
    if (selectedLanguage) {
      if (Object.keys(tmpVoiceFileType).length) {
        const selectedVoiceFileType = tmpVoiceFileType[selectedLanguage]
        if (AppUtil.isValueValid(selectedVoiceFileType)) {
          tmpVoiceFileType[selectedLanguage].fileType = voiceFileUploadMethod;
          setTmpVoiceFileType(tmpVoiceFileType);
        }
      }
    }
  }, [voiceFileUploadMethod]);

  // reset voice file pop menu , it will clear the other voice file option detials by reset the states of this and it's child component
  const resetStates = () => {
    if (voiceFileUploadMethod !== VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
      setVoiceFilePath(voiceFilePrefixObj[selectedLanguage]);
    }
    ttsRef.current.resetTtsOption(); // reset the voice file info states of TTS component so that changes reflect on UI
    uploadRef.current.resetUploadOption(); // reset the voice file info states of UPLOAD component so that changes reflect on UI
  }

  // revert the changes when closing option manue
  const handleCloseOptionMenu = (event) => {
    const tempOptionAndVal = VoiceFileOptionService.getFormattedOptionAndVal(element);
    setShowOptionMenu(false);
    setOptionList(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS) ? element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS).split("") : "");
    setOptionAndVal(tempOptionAndVal);
  }

  // save the changes of the option menu
  const handleSaveOptionMenu = () => {
    let keyAndVal = {};
    let optionAndValJson = AppUtil.deepClone(optionAndVal);
    for (let i of Object.keys(optionAndValJson)) {
      if (optionAndValJson[i][0] !== null && optionAndValJson[i][0] !== "" && optionAndValJson[i][1] !== null && optionAndValJson[i][1] !== "") {
        keyAndVal[optionAndValJson[i][0]] = optionAndValJson[i][1];
      }
    }
    ElementService.updateElement(element, ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, JSON.stringify(keyAndVal));
    ElementService.updateElement(element, ATTRIBUTES.PROMT_USER_OPTION_OPTIONS, optionList);
    setShowOptionMenu(false);
  }

  // revert the changes of the voice file setting menu
  const handleCloseVoiceFileSettingMenu = async (event) => {
    const voiceFileInfo = await AudioService.getVoiceFileInfo(element, ATTRIBUTES.VOICE_FILE_INFO, selectedLanguage);
    setShowVoiceFileSettingMenu(false);
    setSupportedLanguages(voiceFileInfo.supportedLanguages || []);
    setSelectedLanguage(voiceFileInfo.selectedLanguage);
    setVoiceFileInfo(voiceFileInfo.voiceFileInformation);
    setVoiceFilePath(voiceFileInfo.filePath || voiceFilePrefixObj[voiceFileInfo.selectedLanguage]);
    setVoiceFileUploadMethod(voiceFileInfo.voiceFileUploadMethod);
  }

  // update the voicePath state
  const updateVoiceFilePathState = (value) => {
    setVoiceFilePath(value);
    resetStates();
    tmpVoiceFileInfo[selectedLanguage].filePath = value;
    setTmpVoiceFileInfo(tmpVoiceFileInfo);
  }

  // save changes of voice fie setting
  const handleSaveVoiceFileSettingMenu = () => {
    if (voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
      handleSaveLibraryFile();
    } else {
      // if not library method then get the info
      let voiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO);
      voiceFileInfo = JSON.parse(voiceFileInfo);

      delete tmpVoiceFileType[selectedLanguage];
      const remainingLangFileTypes = Object.keys(tmpVoiceFileType);
      //get the info of all languages except the current selected language
      remainingLangFileTypes?.length && remainingLangFileTypes.forEach((lang) => {
        const voiceFileInfoTemplate = {};
        let obj = AudioUtil.getFormattedVoiceFileInfo(lang, null);
        voiceFileInfoTemplate[lang] = obj;
        // set the fields for all the languages for voice file info
        if (tmpVoiceFileType[lang]?.fileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
          voiceFileInfoTemplate[lang]["filePath"] = tmpVoiceFileInfo[lang]?.filePath || voiceFileInfo[lang]?.filePath;
          voiceFileInfoTemplate[lang]["voiceFileType"] = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
          voiceFileInfoTemplate[lang]["ttsText"] = "";
          voiceFileInfo[lang] = voiceFileInfoTemplate[lang];
        }
      });
      //update the attribute
      ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(voiceFileInfo));
    }
    setShowVoiceFileSettingMenu(false);
  }

  /**
   * Saves the voice file info if voice file method is libraray
   */
  const handleSaveLibraryFile = () => {
    // set isOptionInput value to true is case of filePath and set event type to Voice path
    ElementService.updateElement(element, ATTRIBUTES.PROMT_USER_OPTION_IS_OPTION, true);
    // get the voice file info attribute from element
    let voiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO);
    if (voiceFileInfo) {
      voiceFileInfo = JSON.parse(voiceFileInfo);
      // get the temporary data for the attribute
      let voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, voiceFileInfo);
      // set the fileds necessary for the attribute
      voiceFileInfoTemplate[selectedLanguage].filePath = voiceFilePath;
      voiceFileInfoTemplate[selectedLanguage].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
      voiceFileInfoTemplate[selectedLanguage].ttsText = "";

      delete tmpVoiceFileType[selectedLanguage];
      const remainingLangFileTypes = Object.keys(tmpVoiceFileType);
      // get the remaining languages info and check for library file and set its path and then save the attribute 
      remainingLangFileTypes?.length && remainingLangFileTypes.forEach((lang) => {
        if (tmpVoiceFileType[lang].fileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
          voiceFileInfoTemplate[lang].filePath = tmpVoiceFileInfo[lang]?.filePath || voiceFileInfo[lang]?.filePath;
          voiceFileInfoTemplate[lang].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
          voiceFileInfoTemplate[lang].ttsText = "";
        }
      });
      // updation of attribute on save
      ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(voiceFileInfoTemplate));
    } else {
      // if not voiceFileInfo then save the tempVoiceFileInfo in the element it means it is new element
      ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(tmpVoiceFileInfo));
    }
  }

  //   update option and val of the voice file
  const updateOptionAndValues = (event, val) => {
    const response = VoiceFileOptionService.updateOptionAndValues(event, val, optionAndVal, isOptionAndValStartFromZero)
    //  set new state value and update the model properties
    setOptionAndVal(response.optionAndVal);
    setOptionList(response.optionsListTemp);
  }

  //   add option of the voice file
  const addOption = (event) => {
    let options = AppUtil.deepClone(optionAndVal);
    options[Object.keys(options).length] = ["", ""];
    setOptionAndVal(options);
  }

  //  remove option of the voice file
  const removeOption = (event) => {
    const indx = Number(event.target.id.split("file_option_remove_")[1]);
    let options = AppUtil.deepClone(optionAndVal);
    let updatedOptionsAndVal = [];
    let counter = 0;
    let arrayCounter = optionAndVal[0][0]; // initializing the actual array counter

    // re-indexing the array when the element is deleted from in between
    Object.keys(options).map((key, index) => {
      if (index !== indx) {
        options[index][0] = arrayCounter.toString();
        updatedOptionsAndVal[counter++] = options[index];
        arrayCounter++;
      }
      // doing this because map method needs a return statement
      return options[index];
    });

    let optionsListTemp = VoiceFileOptionUtil.getUpdatedOptions(updatedOptionsAndVal, isOptionAndValStartFromZero);
    setOptionAndVal(updatedOptionsAndVal);
    setOptionList(optionsListTemp);
  }

  // update the voice file state information
  const updateVoiceFileStateInfo = async (language) => {
    const languageSelected = language ? language : VOICE_FILE_DEFAULT_LANGUAGE;
    const voiceFileUploadMethodNew = voiceFileInfo[language]?.voiceFileType ? voiceFileInfo[language]?.voiceFileType : VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    const voiceFilePathNew = tmpVoiceFileInfo[language]?.filePath || voiceFileInfo[language]?.filePath;
    const { voiceFileInformation } = await AudioService.getVoiceFileInfo(element, ATTRIBUTES.VOICE_FILE_INFO, languageSelected);

    // set the invalidVoiceFileInfo of the latest language selected
    setVoiceFileInfo(voiceFileInformation);
    const voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(languageSelected, voiceFileInformation);
    voiceFileInfoTemplate[languageSelected].voiceFileType = voiceFileUploadMethod;
    voiceFileInfoTemplate[languageSelected].filePath = voiceFilePath;

    setSelectedLanguage(languageSelected);
    setVoiceFileUploadMethod(voiceFileUploadMethodNew);
    setVoiceFilePath(voiceFilePathNew || voiceFilePrefixObj[language]);
    setVoiceFileInfo(voiceFileInfoTemplate);
  }

  // show the save voice file options and setting in right panel
  const showVoiceFileInfo = () => {
    switch (voiceFileUploadMethod) {
      case VOICE_FILE_UPLOAD_TYPE.LIBRARY:
        return (
          <div className="row">
            <div className="tab-pane fade show active" id="tts-file" role="tabpanel" aria-labelledby="ttsFile-tab">
              <div className="d-flex">
                <div className="col-sm-9">
                  <label className="pmivr-title mt-3">Library Audio File</label>
                </div>
                <div className="col-sm-3">
                  <PmivrTooltip message={TOOLTIP.INFO.CONFIGURE_VOICE_FILE}>
                    <button onClick={(event) => { setShowVoiceFileSettingMenu(true); }}
                      className="mt-2 float-end pmivr-btn-rounded-circle pmivr-btn-app rounded-circle voice-file-setting-btn"
                      variant="primary" data-bs-toggle="tooltip" data-bs-placement="right">
                      <i className="bi bi-pencil"></i>
                    </button>
                  </PmivrTooltip>
                  <AudioPlayer filePath={voiceFilePath} cssClass={`${CSS_CLASSES.AUDIO_BUTTON_LARGE}`}
                    element={element} action={VOICE_FILE_UPLOAD_TYPE.LIBRARY}></AudioPlayer>
                </div>
              </div>
              <div className="form-group custom-input mb-3">
                <div className="pmivr-label">
                  <label>Base file path</label>
                </div>
                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                  <input readOnly={true} type="text" className="form-control pmivr-input pmivr-disabled"
                    size="50" value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`} />
                </PmivrOverlayTrigger>
              </div>
              <div className="form-group custom-input">
                <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} />
                <PmivrOverlayTrigger tooltip={voiceFilePath || TOOLTIP.FILE_PATH_INFO}>
                  <input readOnly={true} type="text" className="form-control pmivr-input pmivr-disabled"
                    value={voiceFilePath} size="50" placeholder={"file-path/file-name"} required
                    onChange={(event) => { updateVoiceFilePathState(event.target.value); }} />
                </PmivrOverlayTrigger>
              </div>
            </div>
          </div>
        );
      case VOICE_FILE_UPLOAD_TYPE.TTS:
        return (
          <>
            <div className="row">
              <div className="col-sm-9">
                <label className="pmivr-title mt-3">Text To Speech</label>
              </div>
              <div className="col-sm-3">
                <PmivrTooltip message={TOOLTIP.INFO.CONFIGURE_VOICE_FILE}>
                  <button onClick={(event) => { setShowVoiceFileSettingMenu(true); }}
                    className="mt-2 float-end pmivr-btn-rounded-circle pmivr-btn-app rounded-circle voice-file-setting-btn"
                    variant="primary" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-pencil"></i>
                  </button>
                </PmivrTooltip>
                <AudioPlayer filePath={voiceFilePath} cssClass={`${CSS_CLASSES.AUDIO_BUTTON_LARGE}`}
                  element={element} action={VOICE_FILE_UPLOAD_TYPE.TTS} selectedLanguage={selectedLanguage} />
              </div>
            </div>
            <div className="row">
              <div className="form-group custom-input">
                <PmivrOverlayTrigger tooltip={voiceFileInfo[selectedLanguage]?.ttsText || TOOLTIP.INPUT.TTS}>
                  <input type="text" className="form-control pmivr-input pmivr-disabled" readOnly={true} size="50"
                    placeholder={"Text"} required value={voiceFileInfo[selectedLanguage]?.ttsText || ""} />
                </PmivrOverlayTrigger>
              </div>
            </div>
          </>
        );
      case VOICE_FILE_UPLOAD_TYPE.UPLOAD:
        return (
          <>
            <div className="row">
              <div className="col-sm-9">
                <label className="pmivr-title mt-3">Uploaded File</label>
              </div>
              <div className="col-sm-3">
                <PmivrTooltip message={TOOLTIP.INFO.CONFIGURE_VOICE_FILE}>
                  <button onClick={(event) => { setShowVoiceFileSettingMenu(true); }}
                    className="mt-2 float-end pmivr-btn-rounded-circle pmivr-btn-app rounded-circle voice-file-setting-btn"
                    variant="primary" data-bs-toggle="tooltip" data-bs-placement="right">
                    <i className="bi bi-pencil"></i>
                  </button>
                </PmivrTooltip>
                <AudioPlayer filePath={voiceFilePath} cssClass={`${CSS_CLASSES.AUDIO_BUTTON_LARGE}`}
                  element={element} action={VOICE_FILE_UPLOAD_TYPE.UPLOAD} selectedLanguage={selectedLanguage} />
              </div>
            </div>
            <div className="row">
              <div className="form-group custom-input">
                <PmivrOverlayTrigger tooltip={voiceFileInfo[selectedLanguage]?.filePath || TOOLTIP.INPUT.UPLOAD_VOICE_FILE}>
                  <input type="text" className="form-control pmivr-input pmivr-disabled" readOnly={true}
                    placeholder="Voice File Url" size="50" required
                    value={voiceFileInfo[selectedLanguage]?.filePath || ""}
                  />
                </PmivrOverlayTrigger>
              </div>
            </div>
          </>
        );
      default:
        return (<></>);
    }
  }

  return (
    <>
      {showVoiceFileInfo()}
      <div className="row mt-3 mb-3">
        <SingleVoiceContext.Provider
          value={{
            supportedLanguages: supportedLanguages, selectedLanguage: selectedLanguage, updateVoiceFileStateInfo: updateVoiceFileStateInfo,
            voiceFileUploadMethod: voiceFileUploadMethod, setVoiceFileUploadMethod: setVoiceFileUploadMethod,
            voiceFilePath: voiceFilePath, updateVoiceFilePathState: updateVoiceFilePathState,
            ttsRef: ttsRef, element: element, voiceFileDetails: voiceFileInfo, rightPanelEventHandler: rightPanelEventHandler,
            resetStates: resetStates, uploadRef: uploadRef, optionAndVal: optionAndVal, updateOptionAndValues: updateOptionAndValues,
            isOptionAndValStartFromZero: isOptionAndValStartFromZero, removeOption: removeOption, addOption: addOption
          }}>

          <OptionValueMapping optionAndVal={optionAndVal} isOptionAndValStartFromZero={isOptionAndValStartFromZero}
            showOptionMenu={showOptionMenu} handleCloseOptionMenu={handleCloseOptionMenu} addOption={addOption}
            updateOptionAndValues={updateOptionAndValues} removeOption={removeOption}
            handleSaveOptionMenu={handleSaveOptionMenu} setShowOptionMenu={setShowOptionMenu} />

          <PmivrDialog showDialog={showVoiceFileSettingMenu} closeDialog={handleCloseVoiceFileSettingMenu}
            title={`Voice File`} message={<VoiceFileSettingModal supportedLanguages={supportedLanguages} selectedLanguage={selectedLanguage} updateVoiceFileStateInfo={updateVoiceFileStateInfo}
              voiceFileUploadMethod={voiceFileUploadMethod} setVoiceFileUploadMethod={setVoiceFileUploadMethod}
              voiceFilePath={voiceFilePath} updateVoiceFilePathState={updateVoiceFilePathState}
              ttsRef={ttsRef} element={element} voiceFileInfo={voiceFileInfo} rightPanelEventHandler={rightPanelEventHandler}
              resetStates={resetStates} uploadRef={uploadRef} />} saveDialogChanges={handleSaveVoiceFileSettingMenu} />

        </SingleVoiceContext.Provider>
      </div>
    </>
  );
}

export default SingleVoiceFileOption;