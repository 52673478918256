import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import { Formik, Field, Form as FForm, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";

import { ATTRIBUTES } from "../../../constants/attributes";
import { APP_CONFIG } from "../../../config/config";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { EL_NAME, TAB_LIST } from "../../../constants/element";
import { DATA_TYPE } from "../../../constants/data-types";

import AppUtil from "../../../util/app.util";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrSnackBar from "../../common/dialog/pmivr-snackbar";
import PmivrLoader from "../../common/loader/pmivr-loader";

import DiagramService from "../../../services/diagram.service";
import ElementService from "../../../services/element.service";
import VariableService from "../../../services/variable.service";
import ConditionService from "../../../services/condition.service";

/**
 * Condition props view on the diagram page
 */
const ConditionsView = (props) => {
  const [conditions, setConditions] = useState([]);
  // if isDateOfCodeExecution is true then the date value is "new Date()"
  const [logicalOperatorInfo, setLogicalOperatorInfo] = useState({});
  // list of available variables
  const [variables, setVariables] = useState([]);
  const [elements, setElements] = useState([]);
  // list of available simple variables (value is not array)
  const [variablesWithoutArrayVariable, setVariablesWithoutArrayVariable] = useState([]);
  // ui state for changing the state of save button
  // uiState.disabled = false : button is enabled
  // uiState.disabled = true : button is disabled
  // deletedConditions to store conditions that are deleted so on saving we delete the connection lines
  const [uiState, setUiState] = useState({ disabled: false, showLoader: false, deletedConditions: [] });
  /**
   * basic : supported operators -  normal operators like === , !== , >=
   * logical : special opertors like || , &&
   */
  const [operators, setOperators] = useState({ basic: [], logical: [] });
  // element that is used in creating connections with parent element
  const [element, setElement] = useState();
  // types of variables like boolean , string etc.
  const [variableTypes, setVariableTypes] = useState();
  // using the open method from the snackbar component
  const snackbarRef = useRef();

  // schema for the validation
  const conditionSchema = Yup.object().shape({
    conditions: Yup.array().of(
      Yup.object().shape({
        variable: Yup.string().nullable().required("Required"),
        operator: Yup.string().nullable().required("Required"),
        value: Yup.string().nullable(),
        elementId: Yup.string().nullable().required("Required"),
        jsExpression: Yup.string().nullable(),
      })
    ),
  });

  useEffect(() => {
    const init = () => {
      setElement(props.element);
      setConditions([{ variable: null, operator: "", value: "", logicalOperators: [], elementId: "", connectionId: "", isDateOfCodeExecution: false, selectedVariableType: "", jsExpression: "" }]);
      setVariableTypes(Object.keys(DATA_TYPE))
      setOperators({ basic: DiagramService.getOperators().basic, logical: DiagramService.getOperators().logical });
      setLogicalOperatorInfo({ variable: null, operator: "", value: "", logicalOperator: "", isDateOfCodeExecution: false, selectedVariableType: "" });
      setVariablesWithoutArrayVariable([]);
      populateVariables();
      setElementConditions(props.element);
      setElementList(props.element);
    }
    init();
  }, [props.element]);

  // validate logical operator's field value
  const validateLogicalOperatorField = (value) => {
    return value ? "" : "Required";
  }

  // setting the variables cache
  const populateVariables = async () => {
    const allVariablesNames = await VariableService.getAllVariablesNames(true);

    // populating the variables in state after getting the variable names.
    // Using timeout as getting all variable names is consuming time.
    setTimeout(() => { setVariables(allVariablesNames); }, 100);
  }

  // setting the elements
  const setElementList = (ele) => {
    const elements = DiagramService.getSiblingElements(ele);
    const validElements = ConditionService.getValidElementsForCondition(elements);
    // populating the elements in the state
    // Using timeout as getting the valid elements is consuming time.
    setTimeout(() => { setElements(AppUtil.sortElements(validElements, "name")); }, 100);
  }

  // setting the conditions in the state
  const setElementConditions = (ele) => {
    const conditions = ElementService.getAttribute(ele, ATTRIBUTES.GATEWAY_CONDITION);
    if (conditions) {
      // populating the elements in the state
      setTimeout(() => { setConditions(JSON.parse(conditions)); }, 100);
    }
  }

  /**
   * Delete the connection as per condition
   * @param {Object} condition 
   */
  const deleteConnection = (condition) => {
    if (condition.connectionId) {
      const connection = DiagramService.getConnectionById(condition.connectionId);
      DiagramService.deleteConnection(connection);
    }
  }

  /**
   * Save the condition
   * @param {Object} conditions 
   */
  const saveConditions = async (conditions) => {
    try {
      // disable the save button and start the loader until it saves everything
      setUiState({ ...uiState, disabled: true, showLoader: true });

      // delete the connection lines for deleted conditions
      uiState?.deletedConditions.forEach((condition) => {
        deleteConnection(condition);
      });

      // connect the element
      await conditions.forEach((conditionInfo) => {
        let elementInfo = elements.find((_elementInfo) => _elementInfo.id === conditionInfo.elementId);
        if (conditionInfo.elementId === EL_NAME.END_EVENT_NAME) {
          // create End element and add it into parent component
          let newEndElement = DiagramService.addEndEventInParent(element);
          // update the name of the new end element
          ElementService.updateElementAttr(newEndElement, ATTRIBUTES.ATTRIBUTE_NAME, EL_NAME.END_EVENT_NAME);
          // change the element id in condition
          conditionInfo.elementId = newEndElement.id;
          elementInfo = { id: newEndElement.id, name: EL_NAME.END_EVENT_NAME, element: newEndElement };
        }

        // for every condition disconnect the existing connection if exists and add new connection
        deleteConnection(conditionInfo);
        const connectionEl = DiagramService.connectElement(element, elementInfo.element);
        conditionInfo.connectionId = connectionEl.id;
        // save the condition to line element
        const condStr = ConditionService.getConditionInStr([conditionInfo]);
        ElementService.updateConnectionCondition(connectionEl, condStr);
      });

      // will be saved as attribute to gatway element --  save the condition to element
      const gatewayConditionsStr = JSON.stringify(conditions);
      ElementService.updateElementAttr(element, ATTRIBUTES.GATEWAY_CONDITION, gatewayConditionsStr);
      snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
      // close the right panel after a pause of 3 seconds
      setTimeout(() => {
        DiagramService.closeRightPanel();
        // enable the save button and close the loader.
        setUiState({ ...uiState, disabled: false, showLoader: false });
      }, APP_CONFIG.MESSAGE_TIMEOUT);
    } catch (err) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.UPDATE_CONDITION_ERROR);
      }
      // enable the save button
      setUiState({ ...uiState, disabled: false });
    }
  }

  // render fields according to selected data type
  const getFeildsByVariableType = (variableType, index, values, setFieldValue) => {
    switch (variableType) {
      case DATA_TYPE.BOOLEAN:
        return (<div className=" mb-3">
          <div className="props-custom-input">
            <Field as="select" required name={`conditions.${index}.value`}>
              <option></option>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </Field>
            <label htmlFor={`conditions.${index}.value`}>Select Value</label>
            <ErrorMessage name={`conditions.${index}.value`} component="div" className="field-error" />
          </div> </div>);

      case DATA_TYPE.STRING:
        return (<div className="col-sm-12 mb-3">
          <label className="pmivr-label" htmlFor={`conditions.${index}.value`}>Enter Value</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_STRING}>
            <Field name={`conditions.${index}.value`} type="text" className="pmivr-input" placeholder="Enter text here" />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.value`} component="div" className="field-error" />
        </div>);

      case DATA_TYPE.NUMBER:
        return (<div className="col-sm-12 mb-3">
          <label className="pmivr-label" htmlFor={`conditions.${index}.value`}>Enter Value</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_NUMBER}>
            <Field name={`conditions.${index}.value`} type="text" className="pmivr-input" placeholder="Enter number here" />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.value`} component="div" className="field-error" />
        </div>);

      case DATA_TYPE.VARIABLE:
        return (<div className="mb-3">
          <label className="pmivr-label" htmlFor={`conditions.${index}.value`}>Select Variable</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_VARIABLE}>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="selectedVariableInField"
              onChange={(selected) => {
                const selectedVariable = selected.length > 0 ? selected[0] : "";
                values.conditions[index].value = selectedVariable;
                // Note: if setFieldValue not called than formik will show old value in field.
                setFieldValue("variableText", selectedVariable);
              }}
              // only show the simple variables in option
              options={variablesWithoutArrayVariable}
              onInputChange={(text, _event) => {
                values.conditions[index].value = text;
                // Note: if setFieldValue not called than formik will show old value in field.
                setFieldValue("variableText", text);
              }}
              placeholder="Enter Variable"
              className="pmivr-variable-input"
              multiple={false}
              selected={values.conditions[index].value ? [values.conditions[index].value] : []}
            />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.value`} component="div" className="field-error" />
        </div>);
      case DATA_TYPE.DATE:
        return (<div className="col-sm-12 mb-3">
          <div className="form-check  pmivr-check-radio form-check-inline  mb-2 px-4">
            <Field type="checkbox" name={`conditions.${index}.isDateOfCodeExecution`} className="form-check-input" onChange={(_event) => {
              setFieldValue(`conditions.${index}.value`, values.conditions[index].isDateOfCodeExecution ? "" : "YYYY-MM-DD");
              setFieldValue(`conditions.${index}.isDateOfCodeExecution`, values.conditions[index].isDateOfCodeExecution ? false : true);
            }} />
            <label className="form-check-label">  Date of code execution </label>
          </div>
          <label htmlFor={`conditions.${index}.value`}>Enter Date (YYYY-MM-DD)</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_DATE}>
            <Field name={`conditions.${index}.value`} type="text" className="pmivr-input" placeholder="YYYY-MM-DD" disabled={values.conditions[index].isDateOfCodeExecution ? true : false} />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.value`} component="div" className="field-error" />
        </div>);
      default:
        return <></>;
    }
  }

  // render fields of logical condition according to selected data type
  const getFeildsByVariableTypeInLogicalCondtion = (variableType, index, values, setFieldValue, operatorIndex) => {
    switch (variableType) {
      case DATA_TYPE.BOOLEAN:
        return (<div className=" mb-3">
          <label className="pmivr-label" htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.value`}>Select Value</label>
          <Field as="select" className="pmivr-select" name={`conditions.${index}.logicalOperators.${operatorIndex}.value`}>
            <option></option>
            <option value={true}>True</option>
            <option value={false}>False</option>
          </Field>
          <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} component="div" className="field-error" />
        </div>);
      case DATA_TYPE.STRING:
        return (<div className="col-sm-12 mb-3">
          <label className="pmivr-label" htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.value`}>Enter Value</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_STRING}>
            <Field className="pmivr-input" name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} type="text" placeholder="Enter text here" />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} component="div" className="field-error" />
        </div>);
      case DATA_TYPE.NUMBER:
        return (<div className="col-sm-12 mb-3">
          <label className="pmivr-label" htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.value`}>Enter Value</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_NUMBER}>
            <Field className="pmivr-input" name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} type="text" placeholder="Enter number here" />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} component="div" className="field-error" />
        </div>);
      case DATA_TYPE.VARIABLE:
        return (<div className="mb-3">
          <label className="pmivr-label" htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.value`}>Select Variable</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_VARIABLE}>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="selectedVariableInField"
              onChange={(selected) => {
                const selectedVariable = selected.length > 0 ? selected[0] : "";
                values.conditions[index].logicalOperators[operatorIndex].value = selectedVariable;
                // Note: if setFieldValue not called than formik will show old value in field.
                setFieldValue("variableText", selectedVariable);
              }}
              // only show the simple variables in option
              options={variablesWithoutArrayVariable}
              onInputChange={(text, _event) => {
                values.conditions[index].logicalOperators[operatorIndex].value = text;
                // Note: if setFieldValue not called than formik will show old value in field.
                setFieldValue("variableText", text);
              }}
              placeholder="Enter Variable"
              className="pmivr-variable-input"
              multiple={false}
              selected={values.conditions[index].logicalOperators[operatorIndex].value ? [values.conditions[index].logicalOperators[operatorIndex].value] : []}
            />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} component="div" className="field-error" />
        </div>);
      case DATA_TYPE.DATE:
        return (<div className="col-sm-12 mb-3">
          <div className="form-check  pmivr-check-radio form-check-inline  mb-2 px-4">
            <Field type="checkbox" name={`conditions.${index}.logicalOperators.${operatorIndex}.isDateOfCodeExecution`} className="form-check-input"
              onChange={(_event) => {
                setFieldValue(`conditions.${index}.logicalOperators.${operatorIndex}.value`, values.conditions[index].logicalOperators[operatorIndex].isDateOfCodeExecution ? "" : "YYYY-MM-DD");
                setFieldValue(`conditions.${index}.logicalOperators.${operatorIndex}.isDateOfCodeExecution`, values.conditions[index].logicalOperators[operatorIndex].isDateOfCodeExecution ? false : true);
              }}
            />
            <label className="form-check-label">  Date of code execution </label>
          </div>
          <label className="pmivr-label" htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.value`}>Enter Date (YYYY-MM-DD)</label>
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_DATE}>
            <Field name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} type="text" className="pmivr-input" placeholder="YYYY-MM-DD" disabled={values.conditions[index].logicalOperators[operatorIndex].isDateOfCodeExecution ? true : false} />
          </PmivrOverlayTrigger>
          <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.value`} component="div" className="field-error" />
        </div>);
      default:
        return <></>;
    }
  }

  return (
    <>
      {
        (props.tabType === TAB_LIST) ?
          <button className="nav-link active" id="flow-details" data-bs-toggle="tab" data-bs-target="#edit-flow-details" type="button" role="tab" aria-controls="edit-flow-details" aria-selected="false">
            Condition
          </button>
          :
          <div className="tab-pane fade show active" id="edit-flow-details" role="tabpanel" aria-labelledby="nav-home-tab">
            <PmivrSnackBar ref={snackbarRef} />
            <PmivrLoader showLoader={uiState.showLoader} />
            <div className="m-2">
              <Formik initialValues={{ conditions: conditions, logicalOperatorInfo: logicalOperatorInfo }} enableReinitialize validationSchema={conditionSchema} onSubmit={(values) => saveConditions(values.conditions)}>
                {({ values, setFieldValue }) => (
                  <FForm>
                    <FieldArray name="conditions">
                      {({ remove, push }) => (
                        <>
                          {values.conditions && values.conditions.length > 0 && values.conditions.map((condition, index) => (
                            <div className="accordion mt-3 pmivr-variable" key={"condition-" + index}>
                              <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne">
                                  <button className="accordion-button pmivr-accordian-tab" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls="collapseOne">
                                    Condition
                                  </button>
                                </h2>
                                <div id={"collapse" + index} className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                  <div className="pmivr-card card-secondary p-3 pt-0" key={index}>
                                    <div className="row">
                                      <div className="col-sm-12 mb-3 text-end">
                                        <button type="button" className="secondary pmivr-btn-transparent close-btn"
                                          onClick={() => {
                                            remove(index);
                                            setUiState({ ...uiState, deletedConditions: [...uiState?.deletedConditions, condition] });
                                          }}
                                        >
                                          <i className="bi bi-x close-arrow"></i>
                                        </button>
                                      </div>
                                      {/* condition variable */}
                                      <div className="mb-3">
                                        <label className="pmivr-label" htmlFor={`conditions.${index}.variable`}>
                                          Select Variable
                                        </label>

                                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.CONDITION_VARIABLE}>
                                          <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="selectedVariableInField"
                                            onChange={(selected) => {
                                              const selectedVariable = (selected.length > 0) ? selected[0] : "";
                                              values.conditions[index].variable = selectedVariable;
                                              // Note: if setFieldValue not called than formik will show old value in field.
                                              setFieldValue("variableText", selectedVariable);
                                            }}
                                            options={variables}
                                            onInputChange={(text, _event) => {
                                              values.conditions[index].variable = text;
                                              // Note: if setFieldValue not called than formik will show old value in field.
                                              setFieldValue("variableText", text);
                                            }}
                                            placeholder="Enter Variable"
                                            className="pmivr-variable-input"
                                            multiple={false}
                                            selected={values.conditions[index].variable ? [values.conditions[index].variable] : []}
                                          />
                                        </PmivrOverlayTrigger>
                                        <ErrorMessage name={`conditions.${index}.variable`} component="div" className="field-error" />
                                      </div>

                                      {/* operator */}
                                      <div className=" mb-3 mt-3 ">
                                        <div className="props-custom-input">
                                          <Field as="select" name={`conditions.${index}.operator`} required>
                                            <option></option>
                                            {operators.basic?.map((operator, operatorIndex) => {
                                              return (
                                                <option key={operatorIndex} value={operator.operator}>
                                                  {operator.name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                          <label className="pmivr-label" htmlFor={`conditions.${index}.operator`}>Select Operator</label>
                                          <ErrorMessage name={`conditions.${index}.operator`} component="div" className="field-error" />
                                        </div>
                                      </div>
                                      {
                                        // check if is defined or js expression
                                        ["IS_DEFINED", "JS_EXPRESSION", "NOT_DEFINED"].includes(values.conditions[index].operator) ?
                                          // if js expression show input box to take expression otherwise in case of defined_check show nothing  
                                          values.conditions[index].operator === "JS_EXPRESSION" ?
                                            <div className=" mb-3 mt-3 ">
                                              <div className="props-custom-input">
                                                <Field name={`conditions.${index}.jsExpression`} >
                                                </Field>
                                                <label className="pmivr-label" htmlFor={`conditions.${index}.jsExpression`}>JS Expression (.split(...).includes(...))</label>
                                                <ErrorMessage name={`conditions.${index}.jsExpression`} component="div" className="field-error" />
                                              </div>
                                            </div>
                                            : ""
                                          :
                                          <>
                                            {/* value */}
                                            <div className="col-sm-12 mb-3">
                                              <div className="props-custom-input">
                                                <Field as="select" required name={`conditions.${index}.selectedVariableType`}
                                                  onChange={(data) => {
                                                    // reset values if variable type changed
                                                    setFieldValue(`conditions.${index}.value`, "");
                                                    setFieldValue(`conditions.${index}.isDateOfCodeExecution`, false);
                                                    setFieldValue(`conditions.${index}.selectedVariableType`, data.target.value);
                                                  }}
                                                >
                                                  <option></option>
                                                  {variableTypes.map((type, typeIndex) => {
                                                    return (<option key={typeIndex} value={type}>{type}</option>);
                                                  })}
                                                </Field>
                                                <label className="pmivr-label" htmlFor={`conditions.${index}.selectedVariableType`}>Select Variable Type</label>
                                                <ErrorMessage name={`conditions.${index}.selectedVariableType`} component="div" className="field-error" />
                                              </div>
                                            </div>
                                            {getFeildsByVariableType(values.conditions[index].selectedVariableType, index, values, setFieldValue)}
                                          </>
                                      }

                                      {/* For Special logical operators */}
                                      <FieldArray name={`conditions.${index}.logicalOperators`}>
                                        {({ remove, push }) => {
                                          return (
                                            <>
                                              {values.conditions[index]?.logicalOperators?.length > 0 ? (
                                                <hr />
                                              ) : (
                                                <div className="text-center mt-2 mb-2">
                                                  <button className="pmivr-add-option" onClick={() => { push(values.logicalOperatorInfo); }}>
                                                    Add Special Condition <i className="ms-1">+</i>
                                                  </button>
                                                </div>
                                              )}

                                              {values.conditions[index]?.logicalOperators &&
                                                values.conditions[index]?.logicalOperators.length > 0 &&
                                                values.conditions[index]?.logicalOperators.map((operatorCondition, operatorIndex) => {
                                                  return (
                                                    <>
                                                      <div className="col-sm-12 mb-3">
                                                        <div className="props-custom-input">
                                                          <Field
                                                            as="select" required
                                                            name={`conditions.${index}.logicalOperators.${operatorIndex}.logicalOperator`}
                                                            validate={validateLogicalOperatorField}
                                                          >
                                                            <option></option>
                                                            {operators.logical?.map((operator, index) => {
                                                              return (
                                                                <option key={index} value={operator.operator}>
                                                                  {operator.name}
                                                                </option>
                                                              );
                                                            })}
                                                          </Field>
                                                          <label className="pmivr-label" htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.logicalOperator`}>Select Special Operator</label>
                                                        </div>
                                                      </div>

                                                      {/* select variable */}
                                                      <div className="mb-3 pb-2">
                                                        <label className="pmivr-label" htmlFor={`conditions.${index}.variable`}>
                                                          Select Variable
                                                        </label>
                                                        <Typeahead
                                                          id="basic-typeahead-single"
                                                          labelKey="selectedVariableInField"
                                                          onChange={(selected) => {
                                                            const selectedVariable = selected.length > 0 ? selected[0] : "";
                                                            values.conditions[index].logicalOperators[operatorIndex].variable = selectedVariable;
                                                            // Note: if setFieldValue not called than formik will show old value in field.
                                                            setFieldValue("variableText", selectedVariable);
                                                          }}
                                                          options={variables}
                                                          onInputChange={(text, event) => {
                                                            values.conditions[index].logicalOperators[operatorIndex].variable = text;
                                                            // Note: if setFieldValue not called than formik will show old value in field.
                                                            setFieldValue("variableText", text);
                                                          }}
                                                          placeholder="Enter Variable"
                                                          className="pmivr-variable-input"
                                                          multiple={false}
                                                          selected={
                                                            values.conditions[index].logicalOperators[operatorIndex].variable
                                                              ? [values.conditions[index].logicalOperators[operatorIndex].variable]
                                                              : []
                                                          }
                                                        />
                                                        <ErrorMessage name={`conditions.${index}.variable`} component="div" className="field-error" />
                                                      </div>

                                                      {/* operator */}
                                                      <div className=" mb-3 mt-4">
                                                        <div className="props-custom-input">
                                                          <Field
                                                            as="select" required
                                                            name={`conditions.${index}.logicalOperators.${operatorIndex}.operator`}
                                                            validate={validateLogicalOperatorField}
                                                          >
                                                            <option></option>
                                                            {operators.basic?.map((operator, operatorIndex) => {
                                                              return (
                                                                <option key={operatorIndex} value={operator.operator}>
                                                                  {operator.name}
                                                                </option>
                                                              );
                                                            })}
                                                          </Field>
                                                          <label className="pmivr-label" htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.operator`}>Select Operator</label>
                                                          <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.operator`} component="div" className="field-error" />
                                                        </div>
                                                      </div>

                                                      {/* check if is defined or js expression */}
                                                      {
                                                        ["IS_DEFINED", "JS_EXPRESSION", "NOT_DEFINED"].includes(values.conditions[index].logicalOperators[operatorIndex].operator) ?
                                                          // if js expression show input box to take expression otherwise in case of defined_check show nothing  
                                                          values.conditions[index].logicalOperators[operatorIndex].operator === "JS_EXPRESSION" ?
                                                            <div className=" mb-3 mt-3 ">
                                                              <div className="props-custom-input">
                                                                <Field name={`conditions.${index}.logicalOperators.${operatorIndex}.jsExpression`} ></Field>
                                                                <label htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.jsExpression`}>
                                                                  JS Expression (.split(...).includes(...))
                                                                </label>
                                                                <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.jsExpression`}
                                                                  component="div" className="field-error" />
                                                              </div>
                                                            </div>
                                                            : ""
                                                          : <>
                                                            {/* value */}
                                                            <div className="col-sm-12 mb-3">
                                                              <div className="props-custom-input">
                                                                <Field as="select" required name={`conditions.${index}.logicalOperators.${operatorIndex}.selectedVariableType`}
                                                                  onChange={(data) => {
                                                                    // reset values if variable type changed
                                                                    setFieldValue(`conditions.${index}.logicalOperators.${operatorIndex}.value`, "");
                                                                    setFieldValue(`conditions.${index}.logicalOperators.${operatorIndex}.isDateOfCodeExecution`, false);
                                                                    setFieldValue(`conditions.${index}.logicalOperators.${operatorIndex}.selectedVariableType`, data.target.value);
                                                                  }}
                                                                >
                                                                  <option></option>
                                                                  {variableTypes.map((type, typeIndex) => {
                                                                    return (
                                                                      <option key={typeIndex} value={type}>
                                                                        {type}
                                                                      </option>
                                                                    );
                                                                  })}
                                                                </Field>
                                                                <label htmlFor={`conditions.${index}.logicalOperators.${operatorIndex}.selectedVariableType`}>Select Variable Type</label>
                                                                <ErrorMessage name={`conditions.${index}.logicalOperators.${operatorIndex}.selectedVariableType`} component="div" className="field-error" />
                                                              </div>
                                                            </div>
                                                            {getFeildsByVariableTypeInLogicalCondtion(values.conditions[index].logicalOperators[operatorIndex].selectedVariableType, index, values, setFieldValue, operatorIndex)}
                                                          </>
                                                      }

                                                      {/* remove */}
                                                      <div className="text-center mt-3">
                                                        <button className="pmivr-add-option" onClick={() => { remove(operatorIndex); }} >
                                                          Remove <i className="bi bi-trash3-fill mx-1"></i>
                                                        </button>
                                                      </div>

                                                      {/* add button */}
                                                      {operatorIndex < values.conditions[index]?.logicalOperators?.length - 1 ? (
                                                        <hr />
                                                      ) : (
                                                        <>
                                                          <hr />
                                                          <div className="text-center mt-3">
                                                            <button className="pmivr-add-option" onClick={() => { push(values.logicalOperatorInfo); }}>
                                                              Add Special Condition <i className="ms-1">+</i>
                                                            </button>
                                                          </div>
                                                        </>
                                                      )}
                                                    </>
                                                  );
                                                })}
                                            </>
                                          );
                                        }}
                                      </FieldArray>
                                      {/* element mapping */}
                                      <div className="col-sm-12 mt-3">
                                        <div className="props-custom-input">
                                          <Field as="select" required name={`conditions.${index}.elementId`}>
                                            <option></option>
                                            {elements.map((element, elIndex) => {
                                              return (
                                                <option key={elIndex} value={element.id}>
                                                  {element.name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                          <label htmlFor={`conditions.${index}.elementId`}>Select Element</label>
                                          <ErrorMessage name={`conditions.${index}.elementId`} component="div" className="field-error" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {/* add new condition */}
                          <div className="text-center mt-3">
                            <button className="pmivr-add-option" onClick={() => { push({ variable: "", value: "", operator: null }); }}>
                              Add Condition <i className="ms-1">+</i>
                            </button>
                          </div>
                        </>
                      )}
                    </FieldArray>
                    <button type="submit" className="pmivr-btn-app float-end" disabled={uiState.disabled}>
                      Save
                    </button>
                  </FForm>
                )}
              </Formik>
            </div>
          </div>
      }
    </>
  )
}

ConditionsView.propTypes = {
  element: PropTypes.object,
  tabType: PropTypes.string
}

export default ConditionsView;