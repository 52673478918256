import PropTypes from 'prop-types';
import { TOOLTIP } from "../../../constants/messages";
import { PmivrDialog } from "../dialog/pmivr-dialog";
import PmivrLabel, { LABEL_SIZE } from "../label/pmivr-label";
import EditOptionModal from "../../properties-panel/single-voice-file-option/EditOptionModal";
import PmivrOverlayTrigger from "../overlay-trigger/pmivr-overlay-trigger";

/**
 * Add/Edit the values mapped to options
 * @param {{ optionAndVal, isOptionAndValStartFromZero, showOptionMenu, handleCloseOptionMenu,
 * updateOptionAndValues, removeOption, addOption, handleSaveOptionMenu, 
 * setShowOptionMenu }} props props data from parent component
 * @returns  {React.Component} Html element to render
 */
const OptionValueMapping = ({ optionAndVal, isOptionAndValStartFromZero, showOptionMenu, handleCloseOptionMenu,
    updateOptionAndValues, removeOption, addOption, handleSaveOptionMenu, setShowOptionMenu }) => {

    return (
        <>
            <div className="col-sm-10 mt-2">
                <PmivrLabel label="Mapping Option Values" size={LABEL_SIZE.MEDIUM} />
            </div>
            <div className="col-sm-2 pmivr-title pmivr-label my-1">
                <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.EDIT_OPTION_MAPPINGS}>
                    <button className=" mt-1 p-2 pmivr-btn-app pmivr-btn-rounded-circle rounded-circle"
                        onClick={(event) => { setShowOptionMenu(true); }}>
                        <i className="bi bi-pencil"> </i>
                    </button>
                </PmivrOverlayTrigger>
            </div>
            {Object.keys(optionAndVal).map((key, index) => {
                return (
                    <div key={index}>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-3">
                                <select id={`file_option_${index}`} className="pmivr-select" aria-label="Default select example" disabled={true}>
                                    <option selected>
                                        {(isOptionAndValStartFromZero) ? (Number(optionAndVal[index][0]) + 1) : (optionAndVal[index][0])}
                                    </option>
                                </select>
                            </div>
                            <div className="col-sm-9">
                                <PmivrOverlayTrigger tooltip={optionAndVal[index][1] || "Please enter a valid value"}>
                                    <input className="form-control pmivr-input pmivr-disabled" id={`file_value_${index}`} value={optionAndVal[index][1]} readOnly={true} type="text" required />
                                </PmivrOverlayTrigger>
                            </div>
                        </div>
                    </div>
                );
            })}
            <PmivrDialog showDialog={showOptionMenu} closeDialog={handleCloseOptionMenu} title={`Add Options`}
                isOptionAndValStartFromZero={isOptionAndValStartFromZero} removeOption={removeOption}
                saveDialogChanges={handleSaveOptionMenu}
                message={<EditOptionModal optionAndVal={optionAndVal}
                    updateOptionAndValues={updateOptionAndValues} addOption={addOption} />} />
        </>
    )

}

OptionValueMapping.prototype = {
    optionAndVal: PropTypes.object,
    isOptionAndValStartFromZero: PropTypes.bool,
    showOptionMenu: PropTypes.bool,
    handleCloseOptionMenu: PropTypes.func,
    updateOptionAndValues: PropTypes.func,
    removeOption: PropTypes.func,
    addOption: PropTypes.func,
    handleSaveOptionMenu: PropTypes.func,
    setShowOptionMenu: PropTypes.bool
}

export default OptionValueMapping;