class UrlUtil {

  /**
   * Extract the path from URL
   * For example, URL : /client-flows/abc , then return /client-flows
   * @param {string} route path to extract first parameter
   * @returns {string} first parameter
   */
  static extractPathFromURL(route) {
    const pathSegments = route?.split('/');
    return '/' + pathSegments[1];
  }

  /**
   * Extract the path params from the route path
   * if route starts with / then ignore the first element, as first element will be empty string
   * Example: 
   *    /diagram/abc/published/123   ['', 'diagram', 'abc', 'publised', '123']
   *    diagram/abc/published/123    ['diagram', 'abc', 'publised', '123']
   * @param {String} route route path 
   * @returns {Array} array of path params
   */
  static getPathParamsFromRoute(route) {
    return (route?.startsWith("/") ? route?.split('/').slice(1) : route?.split('/')) || [];
  }
}

export default UrlUtil;