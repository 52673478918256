import { ATTRIBUTES } from "../constants/attributes";
import { TASK_TYPE_NAME } from "../constants/task-types";
import {
	TTS_VOICE_TYPES_INFO,
	VOICE_FILE_LANGUAGE_NAME,
	VOICE_FILE_UPLOAD_TYPE, VOICE_FILE_UPLOAD_TYPE_NAME
} from "../constants/voice-file";

import AppUtil from "./app.util";

import ElementService from "../services/element.service";
class AudioUtil {

	/**
	 * Format language information
	 * @param {string} language for which language we need to format voice info
	 * @param {Object} voiceFileInfo information of voice file
	 * @returns {Object} object of formated voiceFile information
	 */
	static getFormattedVoiceFileInfo(language, voiceFileInfo) {
		const languageInfo = voiceFileInfo ? voiceFileInfo[language] : {};
		// it ensure that we have all the possible voice file field for that specified langauge and if any language information not present in voice file then insert a schema for that language so that later we can add information.
		const tempLanguageInfo = {};
		tempLanguageInfo.filePath = languageInfo?.filePath || "";
		tempLanguageInfo.ttsText = languageInfo?.ttsText || "";
		tempLanguageInfo.gender = languageInfo?.gender || "";
		tempLanguageInfo.playSpeed = languageInfo?.playSpeed || "";
		tempLanguageInfo.voiceFileType = languageInfo?.voiceFileType || VOICE_FILE_UPLOAD_TYPE.LIBRARY;
		tempLanguageInfo.fileUrl = languageInfo?.fileUrl || "";
		tempLanguageInfo.fileSize = languageInfo?.fileSize || "";
		if (voiceFileInfo) {
			voiceFileInfo[language] = tempLanguageInfo;
			return voiceFileInfo;
		} else {
			return tempLanguageInfo;
		}
	}

	/**
   * It populates the voiceFileDetailObj and assisn the values to it from various services andmanipulations and then
   * set the value of mapOfVoiceFileDetails
   * @param {Object} element 
   * @param {Object} voiceFileDetailObj 
   * @param {Map} mapOfVoiceFileDetails 
   */
	static updateMapOfVoiceFileDetails(element, voiceFileDetailObj, mapOfVoiceFileDetails, lang, voiceFileInfo) {
		voiceFileDetailObj.uploadType = voiceFileInfo["voiceFileType"];
		if (voiceFileDetailObj.uploadType) {
			voiceFileDetailObj.langauge = VOICE_FILE_LANGUAGE_NAME[lang?.toUpperCase()];
			voiceFileDetailObj.step = ElementService.getElementName(element);
			voiceFileDetailObj.process = ElementService.getParentName(element);
			// The id will be used for specifying detail block for a step and process on voice file detail
			voiceFileDetailObj.detailId = AppUtil.getUniqueKey([voiceFileDetailObj.step, voiceFileDetailObj.process]);
			voiceFileDetailObj.taskType = ElementService.getAttribute(element, ATTRIBUTES.TASK_TYPE);

			switch (voiceFileDetailObj.uploadType) {
				case VOICE_FILE_UPLOAD_TYPE.UPLOAD:
					const uploadFileUrl = voiceFileInfo["fileUrl"];
					voiceFileDetailObj.filePath = uploadFileUrl.substring(0, uploadFileUrl.lastIndexOf("/") + 1);
					break;
				case VOICE_FILE_UPLOAD_TYPE.LIBRARY:
					voiceFileDetailObj.filePath = voiceFileInfo["filePath"];
					break;
				case VOICE_FILE_UPLOAD_TYPE.TTS:
					const ttsFileUrl = voiceFileInfo["fileUrl"];
					voiceFileDetailObj.filePath = ttsFileUrl.substring(0, ttsFileUrl.lastIndexOf("/"));
					break;
				default:
					//nothing to do
					break;
			}

			voiceFileDetailObj.isFileExists = this.isVoiceFileExists(voiceFileDetailObj.filePath);

			const fileDetails = {
				filePath: voiceFileDetailObj.filePath,
				language: voiceFileDetailObj.langauge,
				isFileExists: voiceFileDetailObj.isFileExists,
				uploadType: this.getVoiceFileTypeName(voiceFileDetailObj.uploadType),
			};

			let key = AppUtil.getUniqueKey([voiceFileDetailObj.step, voiceFileDetailObj.process]);

			if (!mapOfVoiceFileDetails.has(key)) {
				// when there is new key in the map
				// make new entry in the map
				let voiceFileDetail = [];
				voiceFileDetail.push(fileDetails);
				const info = {
					step: voiceFileDetailObj.step,
					process: voiceFileDetailObj.process,
					detailId: voiceFileDetailObj.detailId,
					taskType: TASK_TYPE_NAME[voiceFileDetailObj.taskType?.toUpperCase()],
					details: voiceFileDetail,
				};

				info[voiceFileDetailObj.langauge] = voiceFileDetailObj.isFileExists;
				mapOfVoiceFileDetails.set(key, info);
			} else {
				// when key already exists in the map
				// update the array only
				let info = mapOfVoiceFileDetails.get(key);
				info[voiceFileDetailObj.langauge] = voiceFileDetailObj.isFileExists;
				info.details.push(fileDetails);
			}

		}
	}

	/**
   * Parses the voice task element, reads the voice file details and saves them in map. 
   * @param {{ element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages }} elementInfo Object
   */
	static parseVoiceFileTask(elementInfo) {
		const { element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages = [] } = elementInfo;
		// Take out the voiceFileInfo and check [voiceFileType].
		// As per file upload type, populate the required info
		const voiceFileInfoDetails = JSON.parse(ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO));
		for (let lang in voiceFileInfoDetails) {
			if (supportedLanguages.includes(lang)) {
				const voiceFileInfo = voiceFileInfoDetails[lang];
				if (voiceFileInfo) {
					AudioUtil.updateMapOfVoiceFileDetails(element, voiceFileDetailObj, mapOfVoiceFileDetails, lang, voiceFileInfo);
				}
			}
		}
	}

	/**
 * Parses the prompt user input task element, reads the voice file details and saves them in map. 
 * @param {{ element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages }} elementInfo Object
 */
	static parsePromptUserInputTask(elementInfo) {
		const { element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages = [] } = elementInfo;
		let voiceFileInfoDetails = JSON.parse(ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO));
		for (let lang in voiceFileInfoDetails) {
			if (supportedLanguages.includes(lang)) {
				const voiceFileInfo = voiceFileInfoDetails[lang];
				if (voiceFileInfo) {
					AudioUtil.updateMapOfVoiceFileDetails(element, voiceFileDetailObj, mapOfVoiceFileDetails, lang, voiceFileInfo);
				}
			}
		}

		// user-input-invalid-file scenario
		const invalidFileUserInput = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE);
		if (invalidFileUserInput) {
			// reset the values for the object
			AppUtil.resetObject(voiceFileDetailObj);
			voiceFileInfoDetails = JSON.parse(invalidFileUserInput);
			if (voiceFileInfoDetails) {
				for (let lang in voiceFileInfoDetails) {
					if (supportedLanguages.includes(lang)) {
						const voiceFileInfo = voiceFileInfoDetails[lang];
						if (voiceFileInfo) {
							AudioUtil.updateMapOfVoiceFileDetails(element, voiceFileDetailObj, mapOfVoiceFileDetails, lang, voiceFileInfo);
						}
					}
				}
			}
		}
	}

	/**
	 * Parses the key value user option task element, reads the voice file details and saves them in map. 
	 * @param {{ element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages }} elementInfo Object
	 */
	static parseKeyValueUserOptionTask(elementInfo) {
		const { element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages = [] } = elementInfo;
		const voiceFileInfoDetails = JSON.parse(ElementService.getAttribute(element, ATTRIBUTES.KEY_VALUE_MAP));
		for (let lang in voiceFileInfoDetails) {
			if (supportedLanguages.includes(lang)) {
				const voiceFileInfo = voiceFileInfoDetails[lang];
				for (let index in voiceFileInfo) {
					const voiceInfo = voiceFileInfo[index];
					if (voiceInfo) {
						AudioUtil.updateMapOfVoiceFileDetails(element, voiceFileDetailObj, mapOfVoiceFileDetails, lang, voiceInfo);
					}
				}
			}
		}
	}

	/**
	 * Parses the prompt user option task element, reads the voice file details and saves them in map. 
	 * @param {{ element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages }} elementInfo Object
	 */
	static parsePromptUserOptionTask(elementInfo) {
		const { element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages = [] } = elementInfo;
		// Take out the voiceFileInfo and check [voiceFileType].
		// As per file upload type, populate the required info
		const voiceFileInfoDetails = JSON.parse(ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO));
		for (let lang in voiceFileInfoDetails) {
			if (supportedLanguages.includes(lang)) {
				const voiceFileInfo = voiceFileInfoDetails[lang];
				if (voiceFileInfo) {
					AudioUtil.updateMapOfVoiceFileDetails(element, voiceFileDetailObj, mapOfVoiceFileDetails, lang, voiceFileInfo);
				}
			}
		}
	}

	/**
	 * Checks whether the voice file with path is configured 
	 * @param {string} filePath
	 * @returns {boolean} voice file exists or not
	 */
	static isVoiceFileExists(filePath) {
		return filePath ? true : false;
	}

	/**
	 * Getting the voice file type names per input type
	 * @param {string} type
	 * @returns {string}
	 */
	static getVoiceFileTypeName(type) {
		return VOICE_FILE_UPLOAD_TYPE_NAME[type?.toUpperCase()];
	}

	/**
	 * Get the voice id for tts to pass in tts-ms polly for generating the speech with voice type
	 * @param {string} language contains value of selected language
	 * @param {string} gender value like male female of selected gender for tts
	 * @returns {{voiceId,langaugeCode}} voice id to be used in tts polly
	 */
	static getTtsVoiceId(language, gender) {
		for (const voiceInfo of TTS_VOICE_TYPES_INFO) {
			if (voiceInfo.gender === gender && voiceInfo.language === language) {
				return { voiceId: voiceInfo.name, languageCode: voiceInfo.languageCode };
			}
		}
		return "";
	}
}

export default AudioUtil;