import { useEffect, useState } from 'react';
import MonacoEditor from '@monaco-editor/react';
import PropTypes from 'prop-types';

import PmivrTooltip from ".././tooltip/pmivr-tooltip";

import { TASK_ICONS } from '../../../constants/css-classes';

import JsCodeSafetyCheckUtil from '../../../util/jscode-safety-checks.util';

import VariableService from '../../../services/variable.service';

/**
 * Code Editor to edit code
 * @param {expression, handleChange, uiAttributes, language, editorOptions, suggestions} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
const PmivrCodeEditor = ({ expression, handleChange, uiAttributes, language, editorOptions, suggestions }) => {

    const [uiState, setUiState] = useState({ code: expression });

    // decoration to be applied in editors, to specify the restricted code base
    let decorationIds = [];

    // completion provider registration (suggestions)
    let completionProvider;

    // default code to be displayed on creation of new node
    const DEFAULT_CODE = '/**\n Start coding here. Add "return" to return the value. You cannot type $, as it is the reserved keyword for identifying flow '
        + 'and system variables. \n\tExample: \n\t\t return $paymentAmt + 30 \n **/';

    useEffect(() => {
        return () => {
            // on unmount, it will unregister the completion provider
            unRegisterCompletionItemProvider();
        };
    }, []);

    // update the state on expression change
    useEffect(() => {
        setUiState({ code: expression });
    }, [expression]);

    /**
     * handling and configuring the events for the monaco editor
     * @param {object} editor 
     * @param {object} monaco 
     */
    const handleEditorDidMount = async (editor, monaco) => {

        // listener for change in the content of the editor
        editor.onDidChangeModelContent(() => {
            const model = editor.getModel();
            const cursorPosition = editor.getPosition();
            const lineContent = model.getLineContent(cursorPosition.lineNumber);
            const contentTyped = lineContent.substring(cursorPosition.column - 2, cursorPosition.column);
            // disable the manual insert of $ (as it is reserved for usage of variables)
            if (contentTyped === '$') {
                model.applyEdits([{
                    range: {
                        startLineNumber: cursorPosition.lineNumber,
                        startColumn: cursorPosition.column - 1,
                        endLineNumber: cursorPosition.lineNumber,
                        endColumn: cursorPosition.column
                    }, text: ''
                }]);
            } else {
                highlightRestrictedCode(model);
            }
        });

        /**
         * Apply the bootstrap class to highlight the restricted code.
         * Iterating through the lines of code and checking whether it is allowed code base or not.
         * @param {object} model 
         */
        const highlightRestrictedCode = (model) => {
            // getting the lines of code
            const codeLines = model.getLinesContent();
            const newDecorations = [];
            /**
             * Iterating through the lines of code and checking whether it is allowed code base or not. 
             * If not allowed, then apply decoration (add boostrap class to line of code to highlight in editor)
             */
            codeLines.forEach((line, index) => {
                // checking if the line of code is restricted
                if (!JsCodeSafetyCheckUtil.isJSCodeSafe(line)) {
                    // adding decorations on restricted code base (bootstrap class will be applied on line of code)
                    newDecorations.push({
                        range: new monaco.Range(index + 1, 1, index + 1, 1),
                        options: { isWholeLine: true, className: 'bg-danger text-white' }
                    });
                }
            });
            // removing the old decorations and apply the new decorations
            decorationIds = editor.deltaDecorations(decorationIds, newDecorations);
        }

        const handleClick = () => {
            // whenever there is no code available, then it will clear the default text so that we can write code
            if (editor.getValue() === DEFAULT_CODE) {
                editor.setValue('');
            }
        }
        // on click of mouse
        editor.onMouseDown(handleClick);

        // disable default extra suggestions for javascript
        monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
            noLib: true, allowNonTsExtensions: true
        });

        // suggestion options to be displayed
        const suggestionOptions = suggestions || await VariableService.getAllVariablesNames();

        // registering custom suggestions
        completionProvider = monaco.languages.registerCompletionItemProvider('javascript', {
            provideCompletionItems: (model, position) => {
                return {
                    suggestions: suggestionOptions.map(variable => ({
                        label: variable,
                        kind: monaco.languages.CompletionItemKind.Keyword,
                        insertText: `$${variable}`,
                        documentation: variable
                    }))
                }
            }
        });
    }

    // dispose off the completion provider (suggestions) 
    // Without dispose, it registers again and again and generates the duplicate suggestions
    const unRegisterCompletionItemProvider = () => {
        if (completionProvider) {
            completionProvider.dispose();
        }
    }

    /**
     * updates the code changes added onto the state as well as to the xml
     * @param {string} value code changed
     */
    const handleCodeChange = (value) => {
        setUiState({ ...uiState, code: value });
        handleChange(value);
    }

    return (
        <>
            {
                (uiAttributes.label) ?
                    <div className="pmivr-label">
                        <span>{uiAttributes.label.text}</span>
                        {(uiAttributes.label.toolTipMsg) ?
                            <PmivrTooltip message={uiAttributes.label.toolTipMsg}>
                                <i className={TASK_ICONS.DISPLAY_INFO}></i>
                            </PmivrTooltip> : <></>
                        }

                    </div>
                    : <></>
            }
            <div className="form-control pmivr-code-editor mt-2">
                <MonacoEditor width={uiAttributes?.width} height={uiAttributes?.height} language={language || "javascript"}
                    theme="vs-light" value={uiState.code} defaultValue={DEFAULT_CODE} options={editorOptions}
                    onChange={handleCodeChange} onMount={handleEditorDidMount} />
            </div>
        </>
    );

}

PmivrCodeEditor.propTypes = {
    // expression to be implemented
    expression: PropTypes.string,
    // function called on changing in editor
    handleChange: PropTypes.func,
    // ui attributes of the editor
    uiAttributes: PropTypes.shape({
        width: PropTypes.string,
        height: PropTypes.string,
        label: PropTypes.shape({
            text: PropTypes.string,
            toolTipMsg: PropTypes.string
        })
    }),
    // language of the editor
    language: PropTypes.string,
    // options to customize the editor
    editorOptions: PropTypes.shape({
        automaticLayout: PropTypes.bool,
        minimap: PropTypes.object,
        lineNumbersMinChars: PropTypes.number,
        wordWrap: PropTypes.string,
        scrollbar: PropTypes.object,
        cursorStyle: PropTypes.string,
        matchBrackets: PropTypes.string
    }),
    // array of suggestions to be displayed [paymentAmount, payLater, paymentDate,..]
    suggestions: PropTypes.array
}

export { PmivrCodeEditor };