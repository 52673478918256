import { useEffect, useState, useRef, useImperativeHandle, forwardRef } from "react";

import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import { MESSAGES } from "../../../constants/messages";

import UserService from '../../../services/user.service';

import { PmivrDialog } from '../../../components/common/dialog/pmivr-dialog';

/**
 * Dialog box to upload xml file done in flow (draft or publish doc)
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
const UploadFileDialog = forwardRef((props, ref) => {
    const { selectedFlowType, uploadBaseFlow } = props;

    // using the open method from the snackbar component
    const snackbarRef = useRef();

    // variable to show and hide the dialog box
    const [dialogProps, setDialogProps] = useState({ showDialog: false });

    useEffect(() => {
        const init = async () => {
            try {
            } catch (err) {
                // opening the snackbar
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
        init();
    }, []);

    // exposing functions to parent component
    useImperativeHandle(ref, () => ({
        open() {
            setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: true }));
        },
        close: closeDialog
    }));

    // validating the formik fields
    const validate = Yup.object({
        comments: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        file: Yup.mixed().required(MESSAGES.ERR.XML_FILE_REQUIRED)
            .test('is-valid-type', MESSAGES.ERR.INVALID_XML_FILE, (value) => {
                if (value) {
                    if (['text/xml'].includes(value?.type) && value?.name?.endsWith(".xml")) {
                        return true;
                    } else {
                        return false;
                    }
                }
            })
    });

    // function to close the upload file dialog box
    const closeDialog = () => {
        setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: false }));
    }

    return (
        <PmivrDialog showDialog={dialogProps.showDialog}
            closeDialog={closeDialog}
            title={`Upload (Flow Type: ${selectedFlowType?.name})`}
            message={
                <Formik initialValues={{ file: null, flowTypeId: null, name: null, comments: '' }}
                    validationSchema={validate} onSubmit={uploadBaseFlow}>
                    {({ setFieldValue, values, errors, setErrors }) => (
                        <Form>
                            <div className="pmivr-title pt-3 text-start row" >
                                {UserService.hasPermission() && (
                                    <div className="form-group mt-3 mb-2">
                                        <textarea id={`comments-${selectedFlowType.flowTypeId}`} name="comments"
                                            className="form-control pmivr-input doc-comments" rows="3"
                                            value={values.comments}
                                            placeholder="Mention the changes that has been made in this version"
                                            onChange={(event) => {
                                                setFieldValue("comments", event.target.value);
                                                // resetting the event target value as some browsers 
                                                // did not clear the event target for input file
                                                event.target.value = '';
                                            }}
                                        />
                                        <ErrorMessage name="comments" component="div"
                                            className="field-error error pt-2" />
                                    </div>
                                )}
                            </div>

                            {UserService.hasPermission() && (
                                <div className="text-start pt-3 row">
                                    <div className="col-md-6 pmivr-select-file-input float-start">
                                        <label htmlFor={`file-${selectedFlowType.flowTypeId}`}
                                            title="Select XML file only"
                                            className="custom-file-upload pmivr-btn-secondary">
                                            Select <i className="bi bi-filetype-xml"></i> File
                                        </label>
                                        <input type="file" accept=".xml"
                                            id={`file-${selectedFlowType.flowTypeId}`}
                                            name="file"
                                            onChange={(event) => {
                                                setFieldValue("file", event.currentTarget.files[0]);
                                                setFieldValue("flowTypeId", selectedFlowType.flowTypeId);
                                                setFieldValue("name", selectedFlowType.name);
                                                // resetting the event target value as some browsers 
                                                // didnot clear the event target for input file
                                                event.target.value = null;
                                            }} />
                                        <span className="file-info-text">
                                            {values?.file?.name}
                                        </span>
                                        <ErrorMessage name="file" component="div"
                                            className="field-error error pt-2" />
                                    </div>
                                    <div className="col-md-6 float-end">
                                        <button title="Cancel" type="button"
                                            className="pmivr-btn-cancel float-end"
                                            onClick={closeDialog} >
                                            Cancel
                                        </button>
                                        <button type="submit" title="Upload the selected flow file"
                                            className="pmivr-btn-secondary float-end mx-2" >
                                            Upload
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Form>
                    )}
                </Formik>
            }
            footer={<></>}
        />
    )
});

UploadFileDialog.propTypes = {
    // function to upload the xml file
    uploadBaseFlow: PropTypes.func,
    // flow type (standard ivr, aaivr etc.)
    selectedFlowType: PropTypes.string
}

export default UploadFileDialog;