import { TAB_LIST } from "../../../constants/element";
import { ATTRIBUTES } from "../../../constants/attributes";
import { TOOLTIP } from "../../../constants/messages";

import ElementService from "../../../services/element.service";
import { useContext } from "react";
import { VoiceContext } from "../../../contexts/app-context";

import PmivrOverlayTrigger from '../../common/overlay-trigger/pmivr-overlay-trigger';
import PmivrLabel from "../../common/label/pmivr-label";

/**
 * Transfer call properties view in the diagram
 * @param {Object} props props data from parent component
 */
const TransferPropertiesView = () => {
  const { element, tabType } = useContext(VoiceContext);

  /**
   * Update the property in the flow
   * @param {string} property
   * @param {Object} value
   */
  const updateProperty = (property, value) => {
    ElementService.updateElement(element, property, value);
  }

  /**
   * Update the common business hours in the flow
   * @param {Object} e event of type object
   */
  const updateCommonBusinessHours = (e) => {
    const isChecked = e.target.checked;
    updateProperty(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS, isChecked);

    //if common business hours is not needed, clean start time and end time as well
    if (!isChecked) {
      updateProperty(ATTRIBUTES.TRANSFER_START_TIME, "");
      updateProperty(ATTRIBUTES.TRANSFER_END_TIME, "");
    }
  }

  return (
    <>
      {
        (tabType === TAB_LIST)
          ? <button
            className="nav-link active"
            id="transfer"
            data-bs-toggle="tab"
            data-bs-target="#edit-transfer"
            type="button"
            role="tab"
            aria-controls="edit-transfer"
            aria-selected="false"
          >
            Transfer
          </button>
          : <>
            <div className="tab-pane fade show active" id="edit-transfer" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className=" m-2 mt-3">
                <div className="form-group mb-2">
                  <PmivrLabel label="Transfer Number" tooltip={TOOLTIP.INFO.TRANSFER_NUMBER} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TRANSFER_NUMBER}>
                    <input
                      id="transferNumber"
                      name="transferNumber"
                      type="number"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_NUMBER) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_NUMBER, event.target.value); }}
                      placeholder="Please enter the transfer number"
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="row">
                  <div className="col">
                    <div className="form-check pmivr-check-radio form-check-inline mt-4 mb-4">
                      <input
                        id="commonBusinessHours"
                        name="commonBusinessHours"
                        className="form-check-input radio"
                        type="checkBox"
                        checked={
                          element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS) === "true" || element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS) === true
                            ? true
                            : false
                        }
                        onChange={(e) => updateCommonBusinessHours(e)}
                      />
                      <label className="form-check-label ">
                        Common Business Hours<br></br> Standard Business hours ?
                      </label>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-4">
                  <PmivrLabel label="Business Start Time" tooltip={TOOLTIP.INFO.BUSINESS_START_TIME} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BUSINESS_START_TIME}>
                    <input
                      id="startTime"
                      name="startTime"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_START_TIME) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_START_TIME, event.target.value); }}
                      placeholder="Enter Business Start Time (hhmm)"
                      readOnly={!element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Business End Time" tooltip={TOOLTIP.INFO.BUSINESS_END_TIME} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BUSINESS_END_TIME}>
                    <input
                      id="endTime"
                      name="endTime"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_END_TIME) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_END_TIME, event.target.value); }}
                      placeholder="Enter Business End Time (hhmm)"
                      readOnly={!element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                    />
                  </PmivrOverlayTrigger>
                </div>
                {[...Array(7)].map((x, i) => (
                  <div key={i}>
                    <div className="form-group mt-4 mb-4">
                      <label className="pmivr-label">Start Time</label>
                      <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TRANSFER_CALL_START_TIME}>
                        <input
                          key={"start" + i}
                          id={"startTimeDay" + i}
                          name={"startTimeDay" + i}
                          className="form-control pmivr-input"
                          value={element.businessObject.get(ATTRIBUTES.TRANSFER_START_TIME + `${i}`) || ""}
                          onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_START_TIME + `${i}`, event.target.value); }}
                          placeholder={"Enter start time Day " + i}
                          disabled={element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                        />
                      </PmivrOverlayTrigger>
                    </div>
                    <div className="form-group mt-4 mb-4">
                      <div className="pmivr-label">
                        <label>End Time</label>
                      </div>
                      <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TRANSFER_CALL_END_TIME}>
                        <input
                          key={"end" + i}
                          id={"endTimeDay" + i}
                          name={"endTimeDay" + (i + 1)}
                          className="form-control pmivr-input"
                          value={element.businessObject.get(ATTRIBUTES.TRANSFER_END_TIME + `${i}`) || ""}
                          onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_END_TIME + `${i}`, event.target.value); }}
                          placeholder={"Enter end  time Day " + i}
                          disabled={element.businessObject.get(ATTRIBUTES.TRANSFER_COMMON_BUSINESS_HOURS)}
                        />
                      </PmivrOverlayTrigger>
                    </div>
                  </div>
                ))}
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Holidays List" tooltip={TOOLTIP.INFO.HOLIDAYS_LIST} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.HOLIDAYS_LIST}>
                    <input
                      id="transferHoliday"
                      name="transferHoliday"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_HOLIDAY) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_HOLIDAY, event.target.value); }}
                      placeholder={"Enter Holidays list(Comma seperated) Format : MMddyyyy"}
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Zero Out Voice File" tooltip={TOOLTIP.INFO.ZERO_OUT_VOICE_FILE} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.ZERO_OUT_VOICE_FILE}>
                    <input
                      id="zeroOutVoiceFile"
                      name="zeroOutVoiceFile"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_ZERO_OUT_VOICE_FILE) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_ZERO_OUT_VOICE_FILE, event.target.value); }}
                      placeholder="Enter Zero Out Voice File"
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mt-4 mb-4">
                  <PmivrLabel label="Outside of Business Hours Voice File" tooltip={TOOLTIP.INFO.OUTSIDE_OF_BUSINESS_HOURS} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.OUTSIDE_OF_BUSINESS_HOURS}>
                    <input
                      id="outsideBusinessHoursVoiceFile"
                      name="outsideBusinessHoursVoiceFile"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.TRANSFER_OUTSIDE_BUSINESS_HOUR_VOICE_FILE) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.TRANSFER_OUTSIDE_BUSINESS_HOUR_VOICE_FILE, event.target.value); }}
                      placeholder="Enter OutSide of Business Hours Voice File"
                    />
                  </PmivrOverlayTrigger>
                </div>
              </div>
            </div>
          </>
      }
    </>
  )
}

export default TransferPropertiesView;